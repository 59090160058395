@font-face {
  font-family: Volte;
  src: url(../fonts/Volte.otf);
}

@font-face {
  font-family: Volte-semibold;
  src: url(../fonts/Volte-Semibold.otf);
}

body {
  width: 100%;
  height: 100%;
  font-family: "Volte" !important;
  font-size: 14px;
  color: #3a3a3a;
  background-color: #fff;
  outline: none !important;
}

.course-footer.active {
  background: #ff5a0b !important;
}

.progress-tab-headings a button.btn-10 {
  /* border-radius: 10px 10px 0px 0px !important; */
  margin-top: -24px;
  position: initial;
  color: #000;
  border: 1px solid #085dab;
}

.dual-list .list-group {
  margin-top: 8px;
}

.list-left li,
.list-right li {
  cursor: pointer;
}

.list-arrows {
  padding-top: 100px;
}

.list-arrows button {
  margin-bottom: 20px;
}

.selectedprodid {
  display: none;
}
.search-wrapper {
  box-shadow: 0px 2px 9px #f4e1c9;
    border: 1px solid #fbc583 !important;;
}
#select {
  font-family: "Volte-semibold" !important;
}

.mt-20 {
  margin-top: 20px;
}

button:focus {
  outline: none !important;
}

h1,
h2,
h3 {
  margin-top: 0px !important;
  margin-bottom: 10px;
}

h3,
h4 {
  margin: 0px 0 0px 0;
  font-size: 20px;
  color: #111;
}

a {
  font-size: 14px;
  cursor: pointer !important;
}

a:hover {
  font-size: 14px;
  cursor: pointer;
  /* color: #69a63a !important; */
}

a:visited {
  text-decoration: none;
}
a:focus {
  outline: unset !important;
  text-decoration: none !important;
}

p {
  font-size: 14px;
  color: #3a3a3a;
}

ul li {
  font-size: 14px;
  color: #000;
}

.form-control ::placeholder {
  color: #dcdcdc !important;
}

input[type="date"] ::-webkit-input-placeholder {
  color: #dcdcdc !important;
}

input:focus {
  box-shadow: 0px 2px 9px #ffbb65 !important;
  border: 1px solid #ff8c00 !important;
}

select:focus {
  box-shadow: 0px 2px 9px #ffbb65 !important;
  border: 1px solid #ff8c00 !important;
}

/*************** comman style ****************/
.no-margin {
  margin: 0px !important;
}

.padding-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.padding-01 {
 padding:0px !important
}
.pl {
  padding-left: 0px;
}

.pr {
  padding-right: 0px;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mtCenter {
  margin-top: 25% !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5{
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}
.m-5{
  margin: 5px !important;
}

.margin-auto{
margin: 0 auto !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-mb-5 {
  padding-bottom: 5px !important;
  margin-bottom: 5px !important
}

.pbpt {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}
.pt-50 {
  padding-top: 50px !important;
}

.pt-55 {
  padding-top: 55px !important;
}
.pt-80 {
  padding-top: 80px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pr-5{
  padding-right: 5px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}
.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.margin {
  margin: 25px 20px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}
.mb-50p{
  margin-bottom: 50%
}

#autoScroll{
  min-height: 50vh;
  max-height: 90vh;
  overflow-y: auto;
}

b {
  color: #000;
}

.color_gray {
  color: #a4a6a7;
}

.color_black {
  color: #000;
}

.color_magento {
  color: #085dab !important;
}

.btn-1 {
  margin: auto;
  font-size: 16px;
  display: block;
  padding: 6px 30px;
  border: none;
  border-radius: 50px;
  color: #fff;
  background: #085dab;
  transition: 0.5s;
  box-shadow: 3px 5px 8px #bfbfbf;
}

.btn-1 a {
  color: #fff !important;
}

.btn-1:hover {
  background-color: #085dab;
  border: 1px solid #085dab;
  color: #fff;
}

.btn-2 {
  position: relative;
  margin: auto;
  font-size: 16px;
  display: block;
  padding: 6px 30px;
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
  background: linear-gradient(to right, #0093ff 0%, #085dab 100%);
}

.btn-2 a {
  color: #fff !important;
}

.btn-2:hover {
  background: #000;
  color: #fff;
}

.btn-3 {
  margin: auto;
  font-size: 16px;
  display: block;
  padding: 6px 30px;
  border: none;
  border-radius: 50px;
  color: #fff;
  background: linear-gradient(to right, #ab0165de 0%, #d86d00 100%);
  transition: 0.5s;
  box-shadow: 3px 5px 8px #bfbfbf;
}

.btn-3 a {
  color: #fff !important;
}

.btn-3:hover {
  background-color: #085dab;
}

.inlinepayment {
  margin-top: 30px !important;
  margin-left: 10px !important;
  display: inline-block !important;
}

.btn-4 {
  margin: auto;
  font-size: 18px;
  display: block;
  padding: 6px 30px;
  margin: 5px;
  border: 1px solid #085dab;
  border-radius: 50px;
  color: #085dab;
  background: #fff;
  transition: 0.5s;
  box-shadow: 3px 5px 8px #bfbfbf;
}

.btn-4 a {
  color: #085dab !important;
}

.btn-4:hover {
  background-color: #085dab;
  color: #fff !important;
}

.btn-4:focus {
  background-color: #085dab !important;
  color: #fff !important;
}

.btn-5 {
  margin: auto;
  font-size: 18px;
  padding: 6px 30px;
  border: 1px solid #085dab;
  border-radius: 50px;
  color: #085dab;
  background: #fff;
  transition: 0.5s;
  box-shadow: 3px 5px 8px #bfbfbf;
}

.btn-5:hover {
  background-color: #085dab;
  color: #fff !important;
}

/* .btn-5:focus {
  background-color: #085dab;
  color: #fff !important;
} */

.btn-5 a {
  color: #085dab !important;
}

.btn-5 a:hover {
  color: #fff !important;
}

.btn-6 {
  /* margin: auto; */
  /* font-size: 18px; */
  display: inline-block;
  padding: 0px 10px;
  border: unset;
  border-radius: 2px;
  color: #ffffff;
  background: transparent;
  transition: 0.5s;
  float: right;
  /* margin-top: 10px; */
  /* box-shadow: 3px 5px 8px #bfbfbf; */
}

.btn-6 a {
  color: #fff !important;
}

.btn-6:hover {
  background-color: #fff;
  color: #085dab !important;
}

.btn-6 a:hover {
  color: #085dab !important;
}

button[disabled], html input[disabled] {
  cursor: not-allowed;
  background: #ccc;
}

.btn-7 {
  margin: auto;
  font-size: 16px;
  display: block;
  padding: 3px 10px;
  border: none;
  border-radius: 50px;
  color: #fff;
  background: #085dab;
  transition: 0.5s;
  /* box-shadow: 3px 5px 8px #bfbfbf; */
}

.btn-7 a {
  color: #fff !important;
  padding: 0px !important;
  font-size: 16px;
}

.btn-7:hover {
  background-color: #085dab;
  color: #fff;
}

.btn-8 {
  background: none;
  border: none;
  color: #085dab;
}


.btn-9 {
  margin: auto;
  font-size: 16px;
  display: block;
  padding: 6px 30px;
  border: none;
  border-radius: 50px;
  color: #fff;
  background: #085dab;
  transition: 0.5s;
}

.btn-9 a {
  color: #fff !important;
}

.btn-9:hover {
  background-color: #085dab;
  border: 1px solid #085dab;
  color: #fff;
}

.btn-10 {
  /* margin: 0;
  font-size: 14px;
  display: block;
  padding: 3px 30px;
  border: 1px solid #085dab;
  border-radius: 0px 0px 10px 10px;
  color: #085dab;
  background: #fff;
  transition: 0.5s;
  box-shadow: 3px 5px 8px #bfbfbf; */
    font-size: 14px;
    display: inline-block;
    padding: 5px;
    width: 50%;
    word-break: break-all;
    border: 1px solid #085dab;
    border-radius: 0px 0px 10px 10px;
    color: #085dab;
    background: #fff;
    transition: 0.5s;
    box-shadow: 3px 5px 8px #bfbfbf;
}

.background {
  color: #fff !important;
  background-color: #085dab;
}

.btn-10 a {
  background-color: #085dab;
  color: #085dab !important;
}

.btn-10:hover {
  background-color: #085dab;
  color: #fff !important;
}

.btn-10:focus {
  background-color: #085dab;
  color: #fff !important;
}

.btn-11 {
  /* margin: auto; */
  /* font-size: 18px; */
  display: inline-block;
  padding: 10px;
  border: unset;
  border-radius: 2px;
  color: #ffffff;
  background: transparent;
  transition: 0.5s;
  /* float: right; */
  
  /* margin-top: 10px; */
  /* box-shadow: 3px 5px 8px #bfbfbf; */
}

.btn-11 a {
  color: #fff !important;
  padding: 0px;
}

.btn-11:hover {
  /* background-color: #fff; */
  color: #fff !important;
  /*#085dab*/
  font-size: 16px;
  font-weight: bold;
}

.btn-11 a:hover {
  color: #085dab !important;
}

.btn-12 {
  margin: auto;
  font-size: 14px;
  display: block;
  padding: 6px 16px;
  margin: 0 auto;
  border: unset;
  border-radius: 50px;
  color: #085dab;
  background: #fff;
  transition: 0.5s;
  box-shadow: unset;
}

.btn-12 a {
  color: #085dab !important;
}

.btn-12:hover {
  background-color: #085dab;
  color: #fff !important;
}

.btn-12:focus {
  background-color: #085dab !important;
  color: #fff !important;
}

.StatusContent {
  display: flex;
  align-items: center;
}
.StatusCircle .fa {
  font-size: 35px;
}
.StatusName {
  padding-left: 10px;
}
.StatusVerticleLine {
  border-left: 1px solid #dcdcdc;
  height: 25px;
  margin-left: 15px;
  margin-top: -5px;
}

.alignRight{
  text-align: right !important;
}
.animated.notification-item.notification-warning.n-child {
  /* top: 170%; */
  z-index: 9999999 !important;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #f99f3133;
}

h2.sub-header {
  font-size: 18px;
  font-weight: bold;
  color: #085dab;
  padding-bottom: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

label {
  padding: 5px 0px 3px;
  font-weight: 800;
  color: #9e9a9a;
  font-size: 13px;
  letter-spacing: 1px;
  margin-top: 10px;
  text-transform: uppercase;
}

input.form-control1 {
  display: block;
  width: 20px;
  height: 20px;
  margin-top: 10px;
}

.css-yk16xz-control {
  background-color: #fff !important;
  border-color: unset !important;
  border-style: unset !important;
  min-height: 34px !important;
}
.css-yk16xz-control:focus {
  background-color: #fff !important;
  border-color: unset !important;
  border-style: unset !important;
}
.css-2b097c-container{
box-shadow: 0px 2px 9px #f4e1c9;
    border: 1px solid #fbc583 !important;
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
}
.css-2b097c-container:focus{
  box-shadow: 0px 2px 9px #f4e1c9;
      border: 1px solid #fbc583 !important;
      display: block;
      width: 100%;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border-radius: 4px;
  }
h5.email-body {
  color: #3a3a3a;
  font-size: 16px;
}

h5.progress-title {
  padding-left: 6px;
  color: #085dab;
  font-weight: 700;
}

h3.email-head {
  color: green;
  font-size: 25px;
  font-weight: 600;
}


.text-info {
  color: #085eab;
  font-size: 16px;
}

.text-warning {
  color: #fa9f34;
  font-size: 14px;
}

.bg-green {
  color: green;
}

.bg-orange {
  color: orange;
}

.red {
  color: red;
}

.green {
  color: lightgreen;
}

.green1 {
  color: green;
}

.gray {
  color: #3a3a3a;
  opacity: 0.6;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3x grey;
  /* border-radius: 3px; */
  background: #78bcfa
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #085dab;
  /* border-radius: 3px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3a3a3a;
}

::-moz-scrollbar {
  width: 6px;
}

::-moz-scrollbar-track {
  box-shadow: inset 0 0 3x grey;
  border-radius: 3px;
}

::-moz-scrollbar-thumb {
  background: #085dab;
  border-radius: 3px;
} 
::-moz-scrollbar-thumb:hover {
  background: #3a3a3a;
}
html {
  scrollbar-color: #0064a7 #fff;
  scrollbar-width: auto !important;
}
.file-size-limit {
  font-size: 12px;
  color: #999;
  display: block;
  text-align: left;
  padding: 5px;
}

.file-size-limit-bulkupload {
  font-size: 12px;
  color: #999;
  display: block;
  text-align: center;
  padding: 5px;
}
.react-datepicker-wrapper{
  width: 100% !important;
}
.notification-container-bottom-right {
  bottom: 12% !important;
}



/************* Under Maintenance ***************/
#maintenance .wrapper{
  /* background-color:rgba(0,0,0,.5);
  width:100vw;
  height:100vh;
  position:absolute;
  top:0; left:0; */
  position: fixed; 
  z-index: 99999; 
  padding-top: 100px; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.5);
  }

#maintenance .construction{
  /* font-family: Ubuntu; */
  background:orange;
  border-radius:12px;
  border:5px solid darken(gray, 30%);
  text-align:center;
  margin:3em auto;
  max-width:30em;
  padding:2em;
  position:relative;  
  margin-top: 2%
}
#maintenance .construction p{font-size:17px;}
#maintenance .construction span{
      position:absolute;
      top:-0.1em; right:0.25em;
      font-size:2em;
      cursor:pointer;
      color:gray;
      border:10px solid darken(gray,30%);
      border-radius:0.25em;
      border-top-left-radius:0;
      border-top-right-radius:0;
    }
#maintenance .construction span:hover{
          color: darken(gray, 99%) 
        }
  

/************* New Loader *********************/
#loader{
    content: "";
    display: block;
    position: fixed;
    padding: 100px 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255);
  }    
  .loading {
    /* color: #7ef9ff, #b4c8d1, #4682b4, #0f52ba, #000080; */
    display: flex;
    justify-content: center;
    margin: auto;
}
    .dot {
      position: relative;
      width: 3em;
      height: 3em;
      margin: 0.8em;
      border-radius: 50%;
      background: #126fc3
    }
      .dot::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: #126fc3;
        border-radius: inherit;
        animation: wave 2s ease-out infinite;
      }

      .dot1 {
        position: relative;
        width: 3em;
        height: 3em;
        margin: 0.8em;
        border-radius: 50%;
        background: #085eac
      }
        .dot1::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background: #085eac;
          border-radius: inherit;
          animation: wave 2s ease-out infinite;
        }
        .dot2 {
          position: relative;
          width: 3em;
          height: 3em;
          margin: 0.8em;
          border-radius: 50%;
          background: #01396d
        }
          .dot2::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: #01396d;
            border-radius: inherit;
            animation: wave 2s ease-out infinite;
          }
          .dot3 {
            position: relative;
            width: 3em;
            height: 3em;
            margin: 0.8em;
            border-radius: 50%;
            background: #001e39
          }
            .dot3::before {
              position: absolute;
              content: "";
              width: 100%;
              height: 100%;
              background: #001e39;
              border-radius: inherit;
              animation: wave 2s ease-out infinite;
            }
            .dot4 {
              position: relative;
              width: 3em;
              height: 3em;
              margin: 0.8em;
              border-radius: 50%;
              background: #000305
            }
              .dot4::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                background: #000305;
                border-radius: inherit;
                animation: wave 2s ease-out infinite;
              }
  
      @for $i from 1 through 5 {
       .dot {
          background: (#89cff0);
       }
          .dot::before {
            animation-delay: $i * 0.2s;
          }
        }
      
  @keyframes wave {
    50%,
    75% {
      transform: scale(2.5);
    }
  
    80%,
    100% {
      opacity: 0;
    }
  }
  
  
/************* Date Request ***************/
.dropdown-menu.show {
  position: relative  !important;
  top: -35px  !important;
  right: 0px !important;
  left: auto  !important;
  margin: 0px  !important;
  padding: 5px 0px  !important;
  text-align: center  !important;
  border-radius: 5px  !important;
  /* right: auto  !important; */
  bottom: auto  !important;
  transform: translate(0px, 38px)  !important;
  width: auto !important;
  min-width: 100px !important;
}
.show.dropdown .dropdown-menu:before{
  content: unset;
    position: unset;
    top: unset;
    right: unset;
    border: unset;
    border-color: unset;
}

/* select#action {
  border: unset;
  background: transparent;
  content: unset;
  -webkit-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
} */
/* select#action:focus {
border: unset !important;
background: unset !important;
box-shadow: unset !important;
  } */
  /* select#action:after {
    content:"\f13a ";
    font-family: FontAwesome;
    color: #000;
    position: absolute; 
    right: 0; 
    top: 18px;
    z-index: 1;
    width: 10%;
    height: 100%;  
    pointer-events: none;    
} */
/* i.fa.fa-ellipsis-h.actionIcon {
  position: absolute;
  margin-left: -4%;
  background: transparent;
  color: #085dab
} */

/* select#download {
  border: unset;
  background: transparent;
  content: unset;
  -webkit-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
} */
/* select#download:focus {
border: unset !important;
background: unset !important;
box-shadow: unset !important;
  }
  select#download:after {
    content:"\f13a ";
    font-family: FontAwesome;
    color: #000;
    position: absolute; 
    right: 0; 
    top: 18px;
    z-index: 1;
    width: 10%;
    height: 100%;  
    pointer-events: none;    
} */

/************* calendar Event ***************/
.calendar-event label.label-date {
  padding-left: 22% !important;
}

.calendar-event .btn-event {
  background: #ffffff !important;
  color: #3a3a3a !important;
  border: 1px solid #f99f318c !important;
  margin: 2px !important;
}

.calendar-event .rbc-event {
  padding: unset !important;
  background: #eb8c2e !important;
}

.calendar-event .rbc-month-header {
  font-size: 16px;
}

.calendar-event .rbc-month-view {
  font-size: 13px;
}

.calendar-event .rbc-date-cell {
  text-align: center !important;
  margin: 0 auto !important;
  padding-right: unset !important;
}

.calendar-event .rbc-date-cell.rbc-now {
  background: #a2d2ff !important;
  color: black !important;
}

.elipsisApply {
  display: inline-block;
/* width: 145px; */
width: 100%;
white-space: nowrap;
overflow: hidden !important;
text-overflow: ellipsis;
}

/*************** React Table *******************/
.ReactTable .rt-table{
  overflow: auto !important;
}

.ReactTable .rt-thead.-headerGroups {
  background: none !important;
  border-bottom: none !important;
}

.ReactTable .rt-thead.-header {
  /* box-shadow: 0 2px 15px 0 rgba(0,0,0,0.15);  */
  box-shadow: none !important;
  border-bottom: 1px solid #ff866552 !important;
}

/* .ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0,0,0,0.05);
  display: none;
} */
.ReactTable.-striped .rt-tr.-odd {
  background: none !important;
}
.rt-tr-group.unread {
  background: #d4e9fd !important;
}
.ReactTable .rt-tbody .rt-td.unread {
  font-weight: bold;
  background: #d4e9fd;
}
strong.unread {
  font-weight: bold;
  /* color: blue; */
  color:#085dab;
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: none !important;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  color: #000 !important;
  text-align: left !important;
  border-right: none !important;
  transition: none !important;
  box-shadow: none !important;
}

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: #085dab !important;
  color: #fff !important;
  transition: all 0.1s ease;
  cursor: pointer;
  outline-width: 0;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: #00508d !important;
  color: #fff !important;
}

.ReactTable .-pagination {
  box-shadow: none !important;
}

.ReactTable .rt-noData {
  background: unset !important;
}
.ReactTable .rt-tbody{
  overflow-x: hidden !important;
  overflow-y: auto !important;
}
/*************** React Carousel ****************/

.react-multiple-carousel__arrow {
  min-width: 30px !important;
  min-height: 30px !important;
  z-index: 0 !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
  top: 50% !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
  top: 50% !important;
}

.react-multi-carousel-dot--active button {
  background: #085dab !important;
}

.react-multi-carousel-dot button {
  border-color: #528dc4 !important;
}


/* loder */
#loading-bar-spinner.spinner {
  left: 50%;
  margin-left: -20px;
  top: 50%;
  margin-top: -20px;
  position: absolute;
  z-index: 19 !important;
  animation: loading-bar-spinner 400ms linear infinite;
  display: none;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 120px;
  height: 120px;
  border:  solid 5px transparent;
  border-top-color:  green !important;
  border-left-color: green !important;
  border-radius: 50%;
}

@keyframes loading-bar-spinner {
0%   { transform: rotate(0deg);   transform: rotate(0deg); }
100% { transform: rotate(360deg); transform: rotate(360deg); }
}


/****** Progress Overview ******/
.carousel.carousel-slider .control-arrow:hover {
  /* background: unset !important; */
}

.carousel .control-prev.control-arrow:before {
  /* border-right: unset !important; */
}

.carousel .control-next.control-arrow:before {
  /* border-left: unset !important; */
}

/*************** header section ****************/

.header {
  /* padding-bottom: 86px;
    position: absolute; */
  margin-bottom: 86px;
}

.container-fluid.padding-pl-pr {
  padding-left: 5%;
  padding-right: 5%;
}

#md-toggle{
  display: none;
}
.navbar {
  transition: 500ms ease;
  background: #fff;
  border-bottom: 1px solid #dcdcdc;
  box-shadow: 2px 2px 5px #bfbfbf;
  position: fixed;
  height: 80px;
}

/* .navbar.scrolled {
    background: #ffffff;
    box-shadow: 0px 0px 21px #cecece;
    padding: 4px 10px !important;
    height: 70px;
} */

.login-detail {
  display: inline-flex;
  list-style: none;
  padding: 15px 0px 0px;
}

.login-detail span>a {
  float: right;
  font-size: 13px;
  margin-top: 6px;
  color: #000;
}

.login-detail li {
  padding: 5px;
}

.login-detail .form-control {
  display: block;
  width: 100%;
  height: 30px;
  padding: 5px 20px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  /* border: 1px solid #ccc; */
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
  border-radius: 34px;
}

.login-detail li.login-button {
  font-size: 15px;
  padding: 10px;
}

.login-detail li.login-button a {
  font-size: 16px;
  color: #000;
}

.login-detail li.login-button img {
  margin-left: 5px;
}

.login-detail li.login-button .fa {
  /* background: linear-gradient(to right, #ff8963 0%, #de7e60 60%); */
  -webkit-background-clip: text;
  color: #000;
  -webkit-background-clip: text;
  background-clip: text;
}

.navbar-brand {
  float: left;
  height: 0px;
  padding: 0px;
  font-size: 0px;
  line-height: 0px;
}

.navbar-brand img {
  height: 75px;
  margin-left: 0px;
}

.navbar-header .navbar-toggle {
  border: 1px solid #3a3a3a;
  padding: 10px !important;
}

.navbar-header .navbar-toggle .icon-bar {
  background: #3a3a3a;
}

/******************* Preassessment details *************************/
.progress{
  margin-bottom: 0px;
  overflow-x: auto;
}
  .progress-box {
    border: 1px solid #f99f31;
    padding: 20px 15px;
    box-shadow: 3px 5px 8px #bfbfbf;
    float: left;
    margin: 5px;
    width: 100%;
    min-height: 50px;
    max-height: max-content;
    /* max-height: auto; */
    overflow-y: auto;
    border-radius: 10px;
    margin-bottom: 30px;
}
.preassessmentDetailBox1 {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  min-height: 50px;
  max-height: max-content;
  overflow-y: auto;
  border-radius: 0px;
  margin-bottom: 30px;
}
.preassessmentDetailBox {
  border: 1px solid #f99f31;
  /* padding: 20px 15px; */
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  min-height: 50px;
  max-height: max-content;
  overflow-y: auto;
  border-radius: 10px;
  margin-bottom: 30px;
}
.preassessmentDetailBox .pre-detail-tab {
  border-bottom: 1px solid #dcdcdc;
  /* margin-left: -15px; */
  box-shadow: 3px 5px 8px #bfbfbf;
  margin-bottom: 15px;
}
.preassessmentDetailBox .tab-group {
  list-style: none;
  padding: 10px;
  /* margin: 0 10px 40px 10px; */
  border-radius: 10px;
  margin: 0;
}
.preassessmentDetailBox .question-style {
  color: #999999;
  padding-bottom: 10px;
}
.preassessmentDetailBox .question {
  color: #000;
  font-weight: 600;
}
.preassessmentDetailBox #assessmentOption label{
  padding: 4px 0px 3px 10px;
  font-weight: 800;
  color: #000000;
  font-size: 14px;
  letter-spacing: 1px;
  margin-top: 10px;
  text-transform: capitalize;
}
.progress-box .progress-bar-success {
  background-color: #085dab !important;
}
.progress-box .progress-bar-info {
  background-color: #7bc248 !important;
}
.progress-box .progress-bar-danger {
  background-color: #a83172 !important;
}
.progress-box .progress-bar-warning {
  background-color: #f99f31 !important;
}
/******************* After Login Header *************************/

#notification-profile .notification-content{
border:unset
}
ul#notification-profile {
  width: 320px;
}
#notification-profile li a{
  display:block;
  text-decoration:none;
  padding:5px;
  color:#085dab;
  font-size:16px;
  float:left;
  width:50%;
  text-align:left;
  cursor:pointer;
  transition:.5s ease;
  }
  #notification-profile li a:hover{
    color: #cf5822;
    text-decoration: none;
    }
/******************* Right Section *************************/

.right-section {
  padding: 20px 0px;
}

.right-search .form-control {
  height: 30px;
}

.right-search button {
  background: none;
  border: none;
  top: 0;
  margin: 0 auto;
}

.right-search {
  margin: 0 auto;
  float: left;
  padding: 25px;
  margin-left: 0px;
  align-items: center;
  margin-right: 0px;
}

/* .right-search .fa {
    padding: 8px 10px 7px 138px;
    top: 0;
    position: absolute;
    display: block;
    float: right;
} */

.right-search .fa {
  padding: 33px 10px 7px 154px;
  top: 0;
  position: absolute;
  display: block;
  float: right;
}

.notification .fa {
  color: #000;
  font-size: 20px;
  padding-top: 12px;
}

.notification .badge {
  position: absolute;
  /* top: -8px; */
  top: 15px;
  right: 0px;
  padding: 6px 8px;
  border-radius: 50%;
  background-color: #f99f31;
  color: black;
  font-size: 10px;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.email .fa {
  color: #fff;
  font-size: 20px;
  padding-top: 12px;
}

.email .badge {
  position: absolute;
  top: -8px;
  right: 20px;
  padding: 6px 8px;
  border-radius: 50%;
  background-color: #7bc248;
  color: black;
  font-size: 10px;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.setting .fa {
  color: #fff;
  font-size: 20px;
  padding-top: 12px;
}

.nav>li>a:focus {
  text-decoration: none;
  background-color: unset !important;
}

/************* Left Side Navbar *********************/

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 120px;
}

.opensidenav {
  width: 265px;
}

.sidenav a {
  padding: 8px 8px 8px 15px;
  text-decoration: none;
  font-size: 16px;
  color: #b1b1b1;
  display: block;
  transition: 0.3s;
}

.sidenav a .fa {
  width: 20px;
}

.sidenav .dropdown-btn .fa {
  width: 20px;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 75px;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

span.sidenva-icon {
  font-size: 30px;
  cursor: pointer;
  color: #000;
  float: left;
  margin: 0 auto;
  /* padding: 20px 10px 20px 50px; */
  padding: 20px;
}

/* Style the sidenav links and the dropdown button */

.sidenav a,
.dropdown-btn {
  padding: 8px 8px 8px 15px;
  /* text-decoration: none; */
  font-size: 16px;
  color: #b1b1b1;
  /* display: block; */
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.sidenav a:hover,
.dropdown-btn:hover {
  color: #f1f1f1;
}

/* .active {
  background-color: #085dab;
  color: white;
} */

.fa-caret-down {
  float: right;
  padding-right: 8px;
}

.dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;
}

/*************** Compose Email ************/
.composeBtn {
  position: fixed;
  top: 110px;
  right: 155px;
}
.composeBtn1{
  padding: 17px;
  float: right;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

/* Bubble Theme */
.ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}

.ql-editor {
  min-height: 18em;
}

.themeSwitcher {
  margin-top: 0.5em;
  font-size: small;
}
.ql-snow .ql-tooltip{
  left: 15px !important
}
.ql-editor ol > li, .ql-editor ul > li{
  font-size: 14px;
}
.ql-snow .ql-editor a {
  text-decoration: underline;
  font-size: 14px;
  text-align: left;
  display: inline-block;
}
.ql-snow .ql-editor a:hover{
  font-size: 14px;
}
.ql-snow .ql-editor a:hover{
  font-size: 14px;
}
/*************** Nav Heading Title ************/

.heading-title {
  top: 80px;
  background: #231f20;
  padding: 7px 30px 7px 30px;
  width: 100%;
  color: #fff;
  margin-bottom: 30px;
  z-index: 1;
}

.heading-title h3.head-title {
  color: #fff;
  font-weight: normal;
}

 li a{
  display:block;
  text-decoration:none;
  padding:15px;
  color:#085dab;
  font-size:20px;
  /* float:left; */
  /* width:50%; */
  text-align:center;
  cursor:pointer;
  transition:.5s ease;
}
 
 .active a {
  background:#085dab;
  color:#fff !important;
}
.tab-group {
  list-style: none;
    padding: 0;
    margin: 0 10px 0px 10px;
    border-radius: 10px;
  }
  .tab-group:after {
    content: "";
    display: table;
    clear: both;
  }

  #login-reg-tab li a{
    display:block;
    text-decoration:none;
    padding:15px;
    background:#e1e1e1;
    color:#085dab;
    font-size:20px;
    float:left;
    border: 1px solid #dcdcdc;
    width: 50%;
    text-align:center;
    cursor:pointer;
    transition:.5s ease;
  }
  #login-reg-tab li a:hover {
      background:#206db3;
      color:#fff;
    }
  #login-reg-tab li.active a {
    background:#085dab !important;
    color:#fff !important;
  }
  #inboxTab li a{
    display:block;
    text-decoration:none;
    padding:10px 15px;
    background:#e1e1e1;
    color:#085dab;
    font-size:20px;
    float:left;
    border: 1px solid #dcdcdc;
    min-width: 17%;
    max-width: max-content;
    text-align:center;
    cursor:pointer;
    transition:.5s ease;
  }
  #inboxTab li a:hover {
    background:#206db3;
    color:#fff;
  }
  #inboxTab li a.active {
  background:#085dab;
  color:#fff;
}
/********************** Shortcut Links ***********************/

.header-shortcut-links {
  top: 80px;
  background: #ffffff;
  padding: 6px 30px 2px 30px;
  width: 100%;
  color: #fff;
  margin-bottom: 30px;
  z-index: 99;
}

.shortcut-links .fa {
  color: #085dab;
  font-size: 22px;
  padding: 7px 10px;
}

/*************** Shortcut Link Hover  *****************/

/* .shortcut-links .tooltip {
    background-color: #085dab;
    padding: 10px 15px;
    border-radius: 5px;
} */

.tooltip {
  top: 32px;
  left: 1110.15px;
  display: block;
}

.tooltip.in {
  filter: alpha(opacity=90);
  opacity: 1;
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 2px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

/* .tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #085dab !important;
}

.tooltip-inner {
  max-width: 200px;
  padding: 10px 20px !important;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  background-color: #085dab !important;
  border-radius: 3px !important;
} */

.Tab3 {
  float: left;
  width: 100%;
  max-height: max-content;
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  margin-left: 15px;
}

.CardHeader {
  font-weight: bolder;
  color: #085dab;
  padding-bottom: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
}


/*********************** React Modal ******************************/
.styles_modal__gNwvD {
  max-width: none;
  position: unset;
  padding: unset;
  background: unset;
  background-clip: unset;
  box-shadow: unset;
  margin: auto;
}

h3.message-text {
  text-align: center;
  line-height: 1.5;
}
 .download-kyd {
  display: flex;
}
.assess-subtext-kyd {
  padding-left: 15px;
}
h5.download-text {
  font-size: 15px;
  color: #3a3a3a;
}

div#empanelment2 {
  display: block;
  padding: 10px;
}
div#empanelment3 {
  display: none;
}
#empanelmentTC{
  padding-right: 15px;
  display: flex;
}

/*************** Enroll ****************/

.enroll {
  /* background-image: url(../images/banner-bg.jpg); */
  background-size: cover;
  padding: 30px 0px;
  position: relative;
}

.enroll .feat,
.cfx {
  text-align: center;
  padding-top: 50px;
  margin-bottom: 35px;
}

.enroll h3 {
  font-weight: bold;
  font-size: 36px;
  color: #972c6b;
  padding-bottom: 10px;
}

.enroll h2 {
  font-size: 36px;
  font-weight: 500;
  color: #000;
}

.enroll p {
  font-size: 16px;
}

.enroll p:nth-child(3) {
  margin-top: 15px;
}

.read-more {
  color: #00528e;
  cursor: pointer;
}

.sample-report {
  text-align: center;
  padding-top: 40px;
  text-decoration: underline;
  color: #2ca7e2;
  cursor: pointer;
}

.sample-report a {
  color: #085dab;
}

.sample-report a:hover {
  color: #000;
}

.title-text {
  color: #666;
}

.letter1 {
  color: #972c6b;
}

.letter2 {
  color: #e0862b;
}

.letter3 {
  color: #00528e;
}

.letter4 {
  color: #6ea842;
}

/*************** course structure ****************/

.course-structure {
  background: #363636;
  padding: 32px 0px 13px;
}

.course-structure h3 {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.course-structure p {
  font-size: 18px;
  color: #fff;
}

/**************** DAP Header **************/
.dap-header .navbar{
  border-bottom: 1px solid #767676;
  box-shadow: none;
}
.dap-headr #navbarSupportedContent1{
  margin-top: 5px;
}
.dap-headr button{
  display: none;
}
.dap-header .navbar-nav{
float: right !important;
z-index: 0;
}
.dap-header .navbar-brand img {
  height: 65px;
  margin-top: 5px;
  margin-left: 15px;
}
.dap-header .navbar-nav>li>a {
  font-size: 18px;
  font-weight: 600;
  padding-top: 30px;
  padding-bottom: 6px;
  color: #000;
}
.dap-header .active a {
  background: none;
  color: #085dab !important;
  text-decoration: none;
  font-weight: bold;
}
.dap-header button {
  display: none;
}

/**************** Journey Status popup **************/
#statusPopup .modal-head{
  text-align: left;
  padding: 0px;
}
#statusPopup .registration{
  background-image: unset
}
div#dateLocationPopup {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  margin-right: 20px;
  /* width: 100%; */
  /* min-height: 345px; */
  /* max-height: max-content; */
  /* max-height: 345px; */
  /* overflow-y: auto; */
  border-radius: 10px;
}
#statusPopup .modal-content {
  max-height: 90vh;
  overflow-y: auto;
}
div#certificateDownloadpopup {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  margin-right: 20px;
  /* width: 100%; */
  /* min-height: 345px; */
  /* max-height: max-content; */
  /* max-height: 345px; */
  /* overflow-y: auto; */
  border-radius: 10px;
}
span.J-done {
  color: #4fbc56;

}
span.J-inprogress {
color: #eb8c2e;

}
span.J-pending {
  color: #cccccc;
  
  }
  .arrow_box {
    position: relative;
    background: #f18851;
    border: 4px solid #f79e70;
    border-radius: 5px;
    margin-bottom: 20px;  
    text-align: center;
}
.arrow_box h2{
font-size: 16px;
font-weight: bold;
padding: 3px;
color: #fff;
}
.arrow_box p{
  color: #fff
  }
  .arrow_box p b{
    color: #fff
    }
.arrow_box:after, .arrow_box:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #f18851;
    border-width: 20px;
    margin-left: -20px;
}
.arrow_box:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: #f79e70;
    border-width: 21px;
    margin-left: -21px;
}

.arrow_box1 {
  position: relative;
  background: #156fad;
  border: 4px solid #2f87c4;
  border-radius: 5px;
  margin-bottom: 20px;  
  text-align: center;
  color: #fff
}
.arrow_box1 h2{
font-size: 16px;
font-weight: bold;
padding: 3px;
color: #fff
}
.arrow_box1 p{
color: #fff
}
.arrow_box1 p b{
  color: #fff
  }
.arrow_box1:after, .arrow_box1:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box1:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #156fad;
  border-width: 20px;
  margin-left: -20px;
}
.arrow_box1:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #2f87c4;
  border-width: 21px;
  margin-left: -21px;
}
/**************** Audit Trail **************/
div#auditBox #preassessment {
  padding-top: 0px;
  margin-bottom: 20px;
}
/**************** section Banner **************/
section#home {
  margin-top: 1%;
}
#home .carousel .carousel-status {
  display: none;
}
.banner .carousel .control-dots .dot {
  /* box-shadow: none !important; */
  background: #ffffff !important;
}
.banner .carousel .thumbs-wrapper {
  margin-bottom: -20px;
}
.banner .caption {
  position: absolute;
  left: 4%;
  top: 40%;
}
.banner h2.banner-title {
  color: #fff;
  font-weight: 900;
    text-align: center;
    padding: 5px 0px;
    font-size: 38px;
}
.banner p.banner-text {
  color: #fff;
  text-align: center;
  padding: 5px 0px;
  font-weight: bold;
  font-size: 16px;
  width: 80%;
 margin: auto
}
.banner .banner-button {
    padding-top: 20px;
    margin: auto;
    width: 32%;
}
.banner .banner-btn {
  margin: auto;
  font-size: 16px;
  display: block;
  padding: 8px 30px;
  border: none;
  border-radius: 50px;
  color: #010101;
  background: #fff;
  transition: 0.5s;

}
.banner .banner-btn li a:hover {
  background: none;

}
.banner .carousel .thumbs-wrapper {
  display: none;
}
/**************** section about **************/

.about {
  padding-top: 40px;
  padding-bottom: 35px;
}

/* .about-paragraph {
  overflow: scroll;
  height: 322px;
  overflow-x: hidden;
} */

.about ::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

.about ::-webkit-scrollbar-track {
  background: #f1f1f1;
  height: 5px;
}

.about ::-webkit-scrollbar-thumb {
  background: #085dab;
  height: 0px !important;
  width: 5px;
}

.about ::-webkit-scrollbar-thumb:hover {
  background: #555;
  height: 5px;
}

.about ::-webkit-scrollbar-track-piece {
  height: 5px;
}


/**************** Benefits **************/
.benefits{
  padding-top: 40px;
  background: #f7f7f7
}
.benefits-box {
  background-color: white;
  width: 100%;
  height: auto;
  border: 1px solid #dcdcdc;
  box-shadow: 2px 2px 5px #bfbfbf;
  padding: 20px 15px;
  margin: 20px 0px;
  border-radius: 5px;
  text-align: center;
  z-index: 1;
}
.benefits-icon {
  margin: auto;
  width: 50%;
}
.benefits-icon img {
  width: 75px;
  height: auto;
  margin: auto;
  padding-bottom: 15px;
  text-align: center
}
h2.benefits-title {
  color: #000;
  font-size: 23px;
  font-weight: 600
}
/**************** Process Flow **************/
.processFlow{
  padding-top: 40px;
  padding-bottom: 35px;
}

/**************** Process Flow **************/
.testimonials{
  padding-top: 40px;
  /* background-image: url(../images/testimonialBg.png); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-height: 500px;
}

/**************** Videos **************/
section#videos {
  padding-top: 38px;
}
section#videos h4 {
  color: #085dab;
  font-size: 27px;
  text-align: center;
  font-weight: 600;
}
.video {
  padding-top: 0px;
  padding-bottom: 35px;
}

video {
  width: 100% !important;
  height: 110px !important;
  border-radius: 0px;
}

/**************** Referral Program **************/
.referral{
  padding-top: 40px;
  background-image: url(../images/referralProgramBg.png);
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  min-height: 500px;
}

.referral p{
  color: #fff;
  font-size: 14px;
}
.referral ul{
 list-style: none
}
.referral ul li{
  color: #fff;
  font-size: 14px;
  line-height: 2.5;
  font-weight: bold;
 }
 .referral ul li span{
  padding-left: 5px;
 }

 .referral .referral-button {
  margin: auto;
  width: 30%;
  padding: 50px 0px;
}
.referral .share-btn {
    margin: auto;
    font-size: 16px;
    display: block;
    padding: 8px 30px;
    border: none;
    border-radius: 50px;
    color: #010101;
    background: #fff;
    transition: 0.5s;
}

/**************** FAQ *****************/
.faq{
  padding-top: 40px;
  padding-bottom: 35px;
}
.faq .accordion {
    /* border: 1px solid #767676; */
    border-radius: 2px;
    background: #fff;
}
.faq .accordion__button {
  background-color: #e2e2e2;
  color: #3a3a3a;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: 1px solid #767676;
  /* margin-bottom: 2px; */
}
.faq .accordion__button:hover {
  background-color: #085dab;
  color: #fff;
}
.faq .accordion__button:focus {
  outline: none;
}
.faq .accordion__button.active {
  background-color: #085dab;
  color: #fff;
}
[role=button] {
  cursor: pointer !important;
}

.faq  .accordion__button[aria-expanded='false']::before, .accordion__button[aria-selected='false']::before {
  transform: rotate(45deg);
}
.faq  .accordion__button:before {
  display: inline-block;
  content: '' !important;
  /* content: '+'; */
  height: 10px;
  width: 10px;
  margin-right: 12px;
  text-align: right;
  float: right;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.faq  .accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
  border: 1px solid #767676;
  margin-top: 0px;
}
.faq .accordion__item {
  margin-bottom: 3px;
}
.faq .faq-btn {
  padding-top: 15px;
}
li.faq_li a {
  display: inline-block;
  padding: 0px;
  font-size: 14px;
}
li.faq_li a:hover {
  font-size: 14px;
}
/********************* Partners *******************/

.partners {
  background-image: url(../images/partner-bg.jpg);
  background-size: cover;
  padding-bottom: 35px;
  padding-top: 40px;
}

#ourpartners .slick-slide {
  margin: 0px 20px;
}

#ourpartners .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

#ourpartners .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

#ourpartners .slick-list:focus {
  outline: none;
}

#ourpartners .slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

#ourpartners .slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

#ourpartners .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

#ourpartners .slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

#ourpartners .slick-track:after {
  clear: both;
}

#ourpartners .slick-loading .slick-track {
  visibility: hidden;
}

#ourpartners  .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

#ourpartners .slick-slide img {
  display: block;
  width: 100%;
}

#ourpartners .slick-slide img:focus {
  outline: none !important;
}

#ourpartners .slick-slide.slick-loading img {
  display: none;
}

#ourpartners .slick-slide.dragging img {
  pointer-events: none;
}

#ourpartners .slick-initialized .slick-slide {
  display: block;
}

#ourpartners .slick-loading .slick-slide {
  visibility: hidden;
}

#ourpartners .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

#ourpartners .slick-arrow.slick-hidden {
  display: none;
}
/**************** Sub Footer *****************/
.sub-footer{
  padding-top: 40px;
  padding-bottom: 65px;
}
.sub-footer .section-title-black {
  padding-bottom: 40px;
  padding-top: 40px;
}
.sub-footer .section-title-black h2 {
  text-align: left;
  font-size: 36px;
  font-weight: bold;
  color: #000;
}
.sub-footer .section-title-black::after {
  content: "";
  height: 3px;
  background: linear-gradient(to right, #0093ff 0%, #085dab 100%);
  width: 90%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0px 0px 0px 15px;
  text-align: left;
}
.blue-btn{
    margin: auto;
    font-size: 16px;
    display: block;
    padding: 8px 30px;
    border: none;
    border-radius: 50px;
    color: #fff;
    background: #085dab;
    transition: 0.5s;
}
.sub-footer p{
  font-size: 16px;
  color: #3a3a3a;
  font-weight: bold;
  text-align: left;
}
.sub-footer .footer-btn {
  margin: auto;
  width: 50%;
}
.sub-footer .quickMenu ul {
  list-style: none;
  margin: 0px;
  padding-left: 0px;
}
.sub-footer .quickMenu ul li {
  display: block;
  width: 100%;
  padding: 0px;
}
.sub-footer .quickMenu ul li a {
  color: #3a3a3a !important;
  font-size: 20px;
  width: 100%;
  padding: 0px 0px 12px 0px;
  text-align: left;
  font-weight: bold;
}
.sub-footer .quickMenu ul li a:hover {
color: #085dab !important;
background: transparent !important;
}
.sub-footer .socialLinks .fa {
  padding: 14px 10px;
  font-size: 20px;
  width: 40px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  background: #085dab;
  color: #fff;
}

/**************** Get in Touch icon & form *****************/
.getInTouch-box {
  bottom: 18%;
  position: fixed;
  right: 12px;
  height: 135px;
  z-index: 999;
  background-image: linear-gradient(#085dab, #1d99cd);
  /* padding: 27px 30px 9px 15; */
  width: 45px;
  padding-top: 27px;
  text-align: center;
  border-radius: 5px;
  vertical-align: middle;
}
.getInTouch-box1 {
  bottom: 42%;
    position: fixed;
    right: 12px;
    height: 135px;
    z-index: 999;
    background-image: linear-gradient(#085dab, #1d99cd);
    /* padding: 11px 10px 4px; */
    width: 45px;
    padding-top: 11px;
    text-align: center;
    border-radius: 5px;
    vertical-align: middle;
}
.getInTouch-box:hover {
  background-image: unset;
  background: #000;
}
.getInTouch-box1:hover {
  background-image: unset;
  background: #000;
}
span.getInTouchText {
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform: rotate(180deg);
  color: #fff;
  font-size: 15px;

}

div#get-in-touch1 {
  bottom: 22%;
  position: fixed;
  /* margin: 1em; */
  right: 5px;
  z-index: 999;
}
#get-in-touch1 img{
  width: 45px;
  height: auto;
}
div#get-in-touch1:hover {
  opacity: 0.8;
}
#get-in-touch {
  bottom: 15%;
  position: fixed;
  /* margin: 1em; */
  right: 5px;
  z-index: 999;
}

div#get-in-touch:hover {
  opacity: 0.8;
}
#get-in-touch img{
  width: 45px;
  height: auto;
}
#getintouch{
display: none;
position: fixed;
bottom: 0;
right: 6%;
/* border: 3px solid #f1f1f1; */
z-index: 999; 
}
#getintouch .modal-dialog {
  width: 480px;
  height: auto;
  max-height: 520px;
  overflow-y: auto;
}
#getintouch .modal-dialog {
  scrollbar-color: #0064a7 #fff;
  scrollbar-width: thin !important;
}
#getintouch .registration{
  background-image: unset;
}
#getintouch .container{
  width: auto;
}
#getintouch .modal-head{
  padding: 0px;
}
#getintouch .section-title-black {
  padding-bottom: 0px;
  padding-top: 0px;
}
#getintouch .section-title-black h2{
  margin: 0px;
}
#getintouch .getintouchbtn{
  width: 28%;
  margin: auto;
}
/**************** head title *****************/

.section-title-black {
  padding-bottom: 40px;
  padding-top: 40px;
}

.section-title-black h2 {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: #000;
}

.section-title-black p {
  text-align: center;
  color: #000;
}

.section-title-black::after {
  content: "";
  height: 3px;
  background: linear-gradient(to right, #0093ff 0%, #085dab 100%);
  width: 100px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.first-text::after {
  content: "";
  height: 3px;
  background: linear-gradient(to right, #bf5331 0%, #ea501e 100%);
  width: 6px;
  position: absolute;
  right: 0;
  margin: auto;
  height: 24px;
  top: 0;
  border-radius: 7px;
}

.section-title-white {
  padding-bottom: 40px;
  padding-top: 40px;
}

.section-title-white h2 {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: #fff;
}

.section-title-white p {
  text-align: center;
  color: #fff;
}

.section-title-white::after {
  content: "";
  height: 3px;
  background: linear-gradient(to right, #0093ff 0%, #085dab 100%);
  width: 100px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.AMCDistributor{
  border-bottom: 1px solid #dcdcdc;
  box-shadow: 0px 5px 5px #a7a7a7;
  padding-left: 15px;
}

/************* get in touch *************/

.getin-touch .form-control {
  margin-bottom: 11px;
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

/* .getin-touch label {
    color: #9a9c9e;
} */

.getin-touch {
  /* background-image: url(../images/get-touch-bg.jpg); */
  background-size: cover;
  padding-bottom: 45px;
}

/******************* Modal form ***********************/

.registration {
  background-image: url(../images/Dap-moda-bg.png);
  background-repeat: no-repeat;
  border-radius: 5px;
  background-size: contain;
}

.registration img {
  height: 189px;
}

.registration .form-control {
  /* border: none;
    padding: 0px 0px;
    border-bottom: 1px solid #d6d3d3;
    border-top: none !important;
    box-shadow: none;
    border-radius: 0;
    font-weight: bold;
    font-size: 15px;
    color: #000;
    margin-top: -14px !important;
    background-color: transparent; */
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

.registration .form-control.enter-otp {
  width: 50%;
}

/* .registration label {
    padding: 5px 0px 3px;
    font-weight: 800;
    color: #9e9a9a;
    font-size: 13px;
    letter-spacing: 1px;
    margin-top: 10px;
} */

/* .registration ::-webkit-input-placeholder {
    color: #000;
} */

.registration input[type="file"] {
  display: block;
  border-bottom: 1px solid #d6d3d3;
  padding: 0px;
  width: 100%;
  margin-top: -6px;
}

.registration .form-control option {
  display: contents;
}
.attempt-checkbox label {
  padding: 0px 0px;
}

.registration .checkbox {
  display: inline;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  color: #000;
}

.registration .checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.registration .checkmark {
  position: absolute;
  top: 0;
  left: 5px;
  height: 20px;
  width: 20px;
  background-color: none;
  border: 1px solid #d9dbdc;
  border-radius: 50px;
  background: #dcddde;
}

.registration .checkbox:hover input~.checkmark {
  background-color: transparent;
}

.registration .checkbox input:checked~.checkmark {
  background-color: transparent;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.registration .checkbox input:checked~.checkmark:after {
  display: block;
}

.registration .checkbox .checkmark:after {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 4px solid #085dab;
  text-align: center;
}

button.close {
  padding: 1px 5px;
  cursor: pointer;
  background: 0 0;
  border: 0;
  font-size: 30px;
  z-index: 9999999999999;
  opacity: 0.4;
  position: inherit;
}

.modal-head {
  padding: 28px 8px;
  text-align: right;
}

.modal-head h3 {
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 14px;
}

.modal-head p {
  line-height: 1.4em;
  font-size: 16px;
}

.resend-otp {
  margin-top: -25px;
  float: right;
  border-bottom: 1px solid #3592e2;
}

.otp {
  padding: 22px 0px;
  margin-top: 18px;
}

.otp a {
  color: #337ab7;
  text-decoration: underline;
}

.login-modal {
  /* background-image: url(../images/get-touch-bg.jpg); */
  background-repeat: no-repeat;
  border-radius: 5px;
}

.login-modal .form-control {
  padding: 10px 0px 7px;
  margin-bottom: 22px;
  border: none;
  border-bottom: 1px solid #ada6a6;
  box-shadow: none;
  border-radius: none !important;
  border-radius: 0;
  padding-bottom: 17px;
  background: transparent;
}

.login-modal .modal-header {
  padding: 9px;
  border-bottom: none !important;
  background: #6b9ecd;
  border-radius: 5px;
}

.login-modal .modal-title {
  margin: 0;
  line-height: 1.42857143;
  text-align: center;
  font-weight: 700;
  color: aquamarine;
  font-weight: 800;
  /* background: linear-gradient(-90deg, #ea6e06 5%, #c310aa 95%); */
  color: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 26px;
  padding: 0px 26px;
}

.login-modal .modal-body {
  position: relative;
  padding: 26px 21px 25px;
}

.validation {
  color: red;
  float: unset;
  font-size: 13px;
  padding-top: 3px;
}

.validation1 {
  color: red;
  font-size: 13px;
  padding-bottom: 15px;
}

.registration .form-control.validation-error {
  border: 1px solid red !important;
}

h5.timer {
  /* float: right; */
  padding: 5px 10px;
  color: #085dab;
  font-size: 14px;
  font-weight: 600;
  margin: 0 auto;
  /* border: 1px solid #fbc583; */
  /* box-shadow: 0px 2px 9px #f4e1c9; */
  /* border: 1px solid #fbc583 !important; */
  /* background: #dcdcdc; */
}

h5.timer1 {
  /* float: right; */
  padding: 5px 10px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin: 0 auto;
}

h5.timer2 {
  /* float: right; */
  padding: 5px 10px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin: 0 auto;
}

/********************** admin dashboard ******************/

.top-nav {
  padding: 0 5px;
}

.top-nav>li {
  display: inline-block;
}

.top-nav>li>a {
  color: #000;
  font-size: 20px;
  line-height: 20px;
  padding-bottom: 12px;
  padding-top: 16px;
}

.top-nav>li span.badge {
  background: #085dab;
  /* font-family: "Open Sans", sans-serif; */
  font-size: 11px;
  font-weight: 300;
  position: absolute;
  right: -5px;
  top: 3px;
}

.top-nav .badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 3px;
  width: 30px;
  margin-top: -2px;
}

nav.navbar.dashboard {
  padding: 10px 10px;
}

.nav>li>a {
  position: relative;
  display: block;
  padding: 6px 0px;
  margin-right: 28px;
  padding-bottom: 8px;
}

.nav>li>a>img {
  max-width: none;
  margin-top: -10px;
  width: 45px;
}

.navbar-nav {
  margin-right: 20px;
}

.navbar-nav>li>a {
  font-size: 14px;
  padding-top: 25px;
  padding-bottom: 6px;
  color: #000;
}

.navbar-nav>li>a:hover {
  color: #085dab;
  background: transparent;
}

.navbar-nav>li>a.active {
  color: #085dab;
  font-weight: 600;
  background: transparent;
  /* border-bottom: 2px solid transparent;
    border-image: linear-gradient(135deg, #a83172 37%, #f99f31 98%);
    border-image-slice: 1;*/
}

.navbar-default .navbar-nav>li>a {
  color: #000;
}

.dropdown-notification .dropdown-menu {
  width: 275px;
  max-width: 275px;
  min-width: 160px;
}

.dropdown-notification>a {
  font-size: 18px !important;
  line-height: 29px !important;
}

.top-nav .dropdown-menu .dropdown-menu-list {
  list-style: outside none none;
  padding-left: 0;
  padding-right: 0 !important;
}

.top-nav>li.dropdown-notification .dropdown-menu>li.external {
  border-radius: 4px 4px 0 0;
  display: block;
  letter-spacing: 0.5px;
  overflow: hidden;
  padding: 15px;
}

.top-nav>li.dropdown-notification .dropdown-menu>li.external>h3 {
  display: inline-block;
  float: left;
  font-size: 13px;
  margin: 0;
  padding: 0;
}

.top-nav>li.dropdown-notification .dropdown-menu>li.external>a {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 0 none;
  color: #888;
  clear: inherit;
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
  margin-top: -1px;
  padding: 0;
  position: absolute;
  right: 10px;
}

.top-nav>li.dropdown-notification .dropdown-menu>li.external {
  background: #eaedf2 none repeat scroll 0 0;
}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list {
  list-style: outside none none;
}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li>a {
  clear: both;
  display: block;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  padding: 16px 15px 18px;
  text-shadow: none;
  white-space: normal;
  border-bottom: 1px solid #eff2f6 !important;
}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .time {
  float: right;
  font-size: 11px;
  font-weight: 400;
  max-width: 75px;
  opacity: 0.7;
  padding: 1px 5px;
  text-align: right;
  background: #f1f1f1 none repeat scroll 0 0;
}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details {
  font-size: 13px;
}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li a .details .label-icon {
  border-radius: 50%;
  margin-right: 10px;
}

.top-nav>li>a:hover,
.top-nav>li>a:focus,
.top-nav>.open>a,
.top-nav>.open>a:hover,
.top-nav>.open>a:focus {
  background-color: transparent;
  color: #999999;
}

.top-nav>li.dropdown-notification .dropdown-menu .dropdown-menu-list>li>a:hover {
  background: transparent;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
  background-color: transparent;
}

.navbar-default .navbar-toggle {
  border: none;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background: none;
}



.dashboard .open>.dropdown-menu {
  display: block;
}

.dashboard .dropdown-menu {
  position: absolute;
  top: 100%;
  left: -150px !important;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/********************* board-section *********************/

.dashboard-tab {
  /* background-image: url(../images/banner-bg.jpg); */
  /* padding: 120px 0px 20px;
    background-repeat: no-repeat;
    background-size: 100% 100%; */
  padding: 30px 0px;
}

.dashboard-tab .board-img {
  background-image: url(../images/icon-bg.png);
  background-size: 100%;
  padding: 39px 35px;
  background-repeat: no-repeat;
  position: absolute;
}

.dashboard-tab .board-img .fa {
  font-size: 35px;
  color: #fff;
  width: 25px;
  text-align: center;
  line-height: 35px;
}

.dashboard-tab span.right-icon .fa {
  float: right;
  font-size: 16px;
  border: 2px solid #fff;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 28px;
  border-radius: 50px;
  color: #fff;
  margin-top: -5px;
}

.dashboard-tab span.left-icon .fa {
  float: left;
  font-size: 25px;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 22px;
  border-radius: 50px;
}

.padding-rl-50 {
  padding: 0px 50px;
}

.board {
  background: #fff;
  box-shadow: 8px 6px 15px #e6e6e6;
  padding: 24px 21px;
}

.board-process h3 {
  font-size: 42px;
  text-align: right;
  font-weight: 800;
  color: #a83172;
}

.board-process h4 {
  font-size: 19px;
  text-align: right;
  font-weight: 500;
  color: #5d5959;
  margin-top: 4px;
}

.board-process h4::after {
  content: "";
  height: 1px;
  background: #f7ae65;
  width: 62px;
  position: absolute;
  right: 16px;
  top: 75px;
}

.process-badges {
  padding-top: 25px;
  text-align: right;
}

.board-process .badge {
  display: inline-block;
  min-width: 10px;
  padding: 8px 19px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 5px;
  margin-left: 7px;
}

.badge.pending {
  background-color: #f99f31;
}

.badge.completed {
  background-color: #7bc248;
}

.badge.inprogress {
  background-color: #085dab;
}

#newsLetter .section-title-black{
  padding-top: 5px;
  padding-bottom: 5px;
}
/********************* Message Send ***********************/
section#send-message {
  margin-bottom: 110px !important;
}
/********************* user table ***********************/

section.dashboard-tab.user-profile {
  padding: 5px 0px 70px;
}
.checkout {
  /* border: 1px solid #fbc583; */
  /* border-radius: 5px; */
  /* margin: 5%; */
  /* width: 100%; */
}

.dahboard-table {
  margin-top: 40px;
}

#admin-profile .bg-box {
  padding-top: 45px
}
.bg-box {
  /* box-shadow: 2px 9px 27px #e6e6e6; */
  /* margin-bottom: 50px; */
  padding: 0px 7px 20px;
  background: #fff;
  /* border: 1px solid; */
  /* padding-top: 20px; */
  /* padding-bottom: 20px; */
}

.user-table {
  border-collapse: collapse;
  width: 100%;
  overflow-x: scroll;
  margin-top: -15px;
}

.user-table td {
  padding: 17px 10px;
  background: #fff;
  border-top: 1px solid #ff866552;
}

.user-table th {
  padding: 17px 10px;
  background: #fff;
}

.user-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.user-table tr:hover {
  background-color: #ddd;
}

.user-table th {
  padding-top: 55px;
  padding-bottom: 23px;
  text-align: left;
  font-weight: bold;
  color: #000;
  font-size: 15px;
}

/* main {
    float: right;
    width: calc(90% - 300px);
    overflow: auto;
    position: fixed;
    right: 0;
    top: 136px;
    bottom: 100px;
} */

main {
  float: right;
  width: auto;
  /* min-width: 70%;
  max-width: max-content; */
  overflow: auto;
  position: fixed;
  /* right: 0; 
    /* height: 100%; */
  top: 120px;
  bottom: 80px;
  margin-right: 8%
}

.profile-left-sidebar {
  /* position: fixed; */
  margin: 0 auto;
  z-index: 1;
}

.profile-sidebar {
  padding: 27px 0px;
  /* position: fixed; */
  min-width: 15%;
  margin-bottom: 20%;
}

/* .tab>li>a:focus, .tab>li>a:hover, .tab>li>a:active{
  text-decoration: underline; */
/* background-color: #085dab; */
/* background: none; 
  font-weight: bold; 
} */



/* .tab-link.active  {
  color:white;
  background-color: red;
} */

.profile-sidebar .fa {
  color: #7bc248;
}
.profile-sidebar .fa:hover {
  color: #fff;
}


.profile-sidebar .fa.red {
  color: red;
}

.profile-graph {
  width: 100%;
  height: auto;
  text-align: center;
  margin-top: 8px;
}

.profile-graph-my-learning-path {
  /* width: 90%;
  height: auto;
  text-align: left;
  margin-left: 20px;
  display: inline-flex; */
  width: 100%;
  height: auto;
  text-align: left;
  padding: 20px;
  display: inline-flex;
  overflow-x: auto;
}

.rs-steps.rs-steps-horizontal {
  margin-right: 10px;
  padding-right: 10px;
}

.rs-steps-item-description {
  padding-right: 12px;
}

span.profile-completion-title {
  text-align: center;
  font-size: 16px;
  color: #3a3a3a;
  width: 100%;
  /* margin-left: 31px; */
  margin-right: 0px;

}

.profile-sidebar .table-header .fa {
  margin-right: -12px;
}

.profile-sidebar .table-header {
  background: #fff;
  border: 1px solid #dcdcdc;
  width: 100%;
  max-height: 450px;
  overflow-y: auto;
  margin: 0 auto;
  padding-top: 0px;
  /* padding-bottom: 8px; */
  text-align: left;
  /* box-shadow: 10px 10px 9px #d6d4d4; */
    box-shadow: 0 0 12px rgba(0,0,0,0.4);
    -moz-box-shadow: 0 0 12px rgba(0,0,0,0.4);
    -webkit-box-shadow: 0 0 12px rgba(0,0,0,0.4);
    -o-box-shadow: 0 0 12px rgba(0,0,0,0.4);
  border-radius: 3px;
}
.table-header .container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}
.table-header {
  background: #085dab;
  width: 100%;
  margin: 0 auto;
  padding: 6px 0px 10px 0px;
  text-align: left;
  box-shadow: 10px 10px 9px #d6d4d4;
}
#topheader .tab li a {
  color: #000;
  font-weight: 200;
  text-align: left;
  width: 100%;
  font-size: 16px;
  display: block;
  text-decoration: none;
  padding: 8px;
  float: left;
  cursor: pointer;
  transition: .5s ease;
}

#topheader .tab li>a:hover,
#topheader .tab li>a:focus {
  background-color: #085dab;
  text-decoration: none;
  font-weight: bold;
  color: #fff !important;
  font-size: 16px;
}

#topheader .table-header ul li.active a {
  background-color: #085dab;
  text-decoration: none;
  font-weight: bold;
  color: #fff !important;
  font-size: 16px;

}
#topheader .pb{
  padding-bottom: 10px
}
.table-header ul {
  /* padding: 5px; */
}

/* .table-header ul li {
  list-style: none;
  color: #fff;
} */

/* .table-header ul li a:active {
  color: #fff !important;
  font-weight: bold;
} */

.table-header h3 {
  font-size: 16px;
  color: #fff;
  /* padding: 6px 24px; */
  padding: 14px 24px 0px;
}

.table-header .fa {
  margin-left: 0px;
  margin-right: 10px;
}

.user-table label {
  display: inline-block;
  max-width: 100%;
  font-weight: 700;
  margin-right: 8px;
  margin-bottom: 0px;
}

.user-table label input {
  display: none;
}

.user-table label span {
  height: 13px;
  width: 13px;
  border: 1px solid #c3c3c3;
  display: inline-block;
  position: relative;
}

.user-table [type="checkbox"]:checked+span:before {
  content: "\2714";
  position: absolute;
  top: -5px;
  left: 0;
}

.password-strength-meter {
  text-align: left;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.password-strength-meter-label {
  font-size: 14px;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.strength-Weak::-webkit-progress-value {
  background-color: #f25f5c;
}

.strength-Fair::-webkit-progress-value {
  background-color: #ffe066;
}

.strength-Good::-webkit-progress-value {
  background-color: #247ba0;
}

.strength-Strong::-webkit-progress-value {
  background-color: lightgreen;
}

.closerow .fa {
  font-size: 14px;
  border: 1px solid #a83172;
  color: #a83172;
  border-radius: 50px;
  height: 28px;
  width: 28px;
  text-align: center;
  line-height: 27px;
}

.closerow i.active {
  background-color: black;
}

.search {
  margin-right: 24px;
}

.search input[type="text"] {
  padding: 10px;
  font-size: 15px;
  margin: 2px;
  /* border: 1px solid #ecebeb; */
  float: left;
  width: 100%;
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
  background: #fff;
  /* box-shadow: 5px 5px 6px #ddd; */
}

.search button {
  float: left;
  padding: 7px;
  color: #797575;
  font-size: 15px;
  border: none;
  cursor: pointer;
  background: none;
  margin-left: -31px;
  margin-top: 7px;
}

.user-footer {
  background: white;
  padding: 9px 11px;
  box-shadow: 5;
}

.user-footer select {
  padding: 2px 19px;
  box-shadow: 2px 2px 2px #ddd;
  border: 1px solid #efe9e9;
}

.user-footer .pagination {
  display: inline-block;
}

.user-footer .pagination a {
  color: black;
  float: left;
  padding: 6px 14px;
  text-decoration: none;
}

.user-footer .pagination a.active {
  background-color: #085dab;
  color: white;
  border-radius: 53px;
}

.pagination a:hover:not(.active) {
  background-color: #085dab;
  color: #fff;
  border-radius: 50px;
}

.user-footer select {
  padding: 4px 26px;
  box-shadow: 2px 2px 5px #ddd;
  border: 1px solid #efe9e9;
  margin-top: 22px;
}

.document .fa {
  float: right;
  padding: 11px 0px;
  margin-right: 29px;
  font-size: 30px;
  color: #7bc248;
  margin-bottom: -12px;
}

a.mar-left .fa {
  font-size: 16px;
  margin-right: 15px;
}

a.mar-left.red {
  color: red;
}
.displayFlex{
  display: flex
}

/* .tabs a{
display: block;
    text-decoration: none;
    padding: 15px;
    background: #e1e1e1;
    color: #085dab;
    font-size: 20px;
    float: left;
    border: 1px solid #dcdcdc;
    min-width: 25%;
    max-width: max-content;
    text-align: center;
    cursor: pointer;
    transition: .5s ease;
}
.tabs a.active {
  background: #085dab;
    color: #fff;
} */
/**************** Change Password *********************/

.change-pass {
  box-shadow: 1px 2px 21px #ddd;
  height: auto;
  border: 1px solid #fbc583;
  padding: 15px;
}

/* .change-pass {
    background: #fff; 
     box-shadow: 1px 2px 21px #ddd; 
     height: auto; 
     padding-bottom: 40px;
    padding-top: 40px;
}  */

.alert-info b {
  color: #31708f;
}

.alert-info p {
  color: #31708f;
}

/* .scr-board label
{
    color: #a4a6a7;
    letter-spacing: 1px;
} */

/* .change-pass ::-webkit-input-placeholder {
    color: #000 !important;
} */

.change-pass .lock-img {
  background-image: url(../images/icon-bg.png);
  padding: 35px 0;
  background-repeat: no-repeat;
  background-position: center;
}

.change-pass .lock-img .fa {
  text-align: center;
  display: block;
  margin: 0 auto;
  font-size: 42px;
  color: #fff;
}

.change-pass h2 {
  font-size: 34px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #085dab;
  padding-top: 18px;
  font-family: Volte-semibold;
}

.otp-resend {
  text-decoration: underline;
  cursor: pointer;
  float: right;
  margin-top: 5px;
  font-size: 14px;
  margin-right: 10px;
}

.bottom-line {
  content: "";
  height: 1px;
  background: #f99f31;
  width: 75px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  margin-top: 6px;
}

.change-pass .bottom-line {
  content: "";
  height: 1px;
  background: linear-gradient(to right, #0093ff 0%, #085dab 100%);
  width: 75px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  margin-top: 6px;
}

.pass-form {
  width: 80%;
  display: block;
  margin: auto;
  margin-top: 20px;
}

/* .scr-board .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 9px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: transparent;
    background-image: none;
    border: none;
    border-bottom: 1px solid #e0dede;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    margin-bottom: 25px;
    border-radius: 0;
} */

.pass-form .form-control {
  margin-bottom: 11px;
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

.pass-form span.icon {
  float: right;
  margin-top: -40px;
  font-size: 18px;
  margin-right: 3px;
}

/********************** Forgot Password *********************/

.modal-header {
  padding: 15px 15px 0px 15px;
  border-bottom: none;
}

.modal-footer {
  padding: 15px;
  text-align: center;
  border-top: none;
}

.scr-board {
  /* background: #fff; */
  /* box-shadow: 1px 2px 21px #ddd; */
  /* height: auto; */
  /* padding-bottom: 40px;
    padding-top: 40px; */
}

/* .scr-board label
{
    color: #a4a6a7;
    letter-spacing: 1px;
} */

/* .scr-board ::-webkit-input-placeholder {
    color: #000 !important;
} */

.scr-board .lock-img {
  background-image: url(../images/icon-bg.png);
  padding: 35px 0;
  background-repeat: no-repeat;
  background-position: center;
}

.scr-board .lock-img .fa {
  text-align: center;
  display: block;
  margin: 0 auto;
  font-size: 42px;
  color: #fff;
}

.scr-board h2 {
  font-size: 34px;
  text-transform: capitalize;
  text-align: center;
  font-weight: bold;
  color: #085dab;
  padding-top: 18px;
  font-family: Volte-semibold;
}
.modal-header .scr-board h2 {
  font-size: 24px;
  text-transform: capitalize;
  text-align: center;
  font-weight: 400;
  color: #085dab;
  padding-top: 18px;
  font-family: Volte-semibold;
}
.bottom-line {
  content: "";
  height: 1px;
  background: #f99f31;
  width: 75px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  margin-top: 6px;
}

.scr-board .bottom-line {
  content: "";
  height: 1px;
  background: linear-gradient(to right, #0093ff 0%, #085dab 100%);
  width: 75px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  margin-top: 6px;
}

.scr-form {
  width: 80%;
  display: block;
  margin: auto;
  margin-top: 20px;
}

/* .scr-board .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 9px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: transparent;
    background-image: none;
    border: none;
    border-bottom: 1px solid #e0dede;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    margin-bottom: 25px;
    border-radius: 0;
} */

.scr-board .form-control {
  margin-bottom: 11px;
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

.scr-board span.icon {
  float: right;
  margin-top: -40px;
  font-size: 18px;
  margin-right: 3px;
}

/*************************** Sco0re Range Popup ******************************/
div#scoreRange {
  margin: auto;
  width: 100%;
  text-align: center;
}
#scoreRange th {
  color: #fff;
  background: #085dab;
  text-align: center;
  }
  #scoreRange tr:nth-child(even) {background: #fbbc6e}
  #scoreRange tr:nth-child(odd) {background: #f99f31}

/*************************** Create user/user profile ******************************/

section.create-login {
  padding-top: 20px;
  display: block;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #fbce97;
  margin-bottom: 20px;
}

section.create-login .form-control {
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

section#create-userType {
  padding-top: 20px;
  display: block;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #fbce97;
  margin-bottom: 20px;
}

section#create-userType .form-control {
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

.create-user {
  /* padding-top: 20px; */
  /* width: 100%; */
  display: block;
  margin: 0 auto;
  /* padding-bottom: 10px; */
  padding: 20px;
  border: 1px solid #fbce97;

  margin-bottom: 20px;
}
.profile-box {
  /* box-shadow: 10px 10px 9px #d6d4d4; */
  box-shadow: 0 0 12px rgba(0,0,0,0.4);
  -moz-box-shadow: 0 0 12px rgba(0,0,0,0.4);
  -webkit-box-shadow: 0 0 12px rgba(0,0,0,0.4);
  -o-box-shadow: 0 0 12px rgba(0,0,0,0.4);
}

.profile-footer{
    /* padding: 12px 8px 15px;
    background: #fff;
    border-top: 1px solid #dcdcdc;
    position: fixed;
    left: 0;
    padding-right: 9%;
    bottom: 4%;
    width: 100%;
    color: white;
    text-align: center;
    z-index: 111; */

    background: #fff;
    border-top: 1px solid #dcdcdc;
    position: fixed;
    left: 0;
    padding: 12px 10% 35px 8px;
    bottom: 4%;
    width: 100%;
    color: #fff;
    text-align: center;
    z-index: 111;
}
/* .create-user h4.download {
    font-size: 14px;
    text-align: center;
    color: #085dab;
    margin-bottom: 10px;
} */
.create-user a.download {
  text-align: center;
  display: block;
  font-size: 14px;
  color: #085db0;
}

.create-user h2 {
  font-size: 19px;
  font-weight: bold;
  padding-bottom: 15px;
  color: #085dab;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.create-user .form-control {
  /* display: block; */
  /* width: 100%; */
  /* height: 34px; */
  /* padding: 0px 0px; */
  /* font-size: 16px; */
  /* line-height: 1.42857143; */
  /* color: #555; */
  /* background-color: transparent; */
  /* background-image: none; */
  /* border: none; */
  /* border-radius: 0px; */
  /* margin-bottom: 30px; */
  /* border-bottom: 1px solid #e0dede; */
  /* -webkit-box-shadow: none; */
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

/* .create-user ::-webkit-input-placeholder {
    color: #000;
} */

/* .create-user label {
    color: #a4a6a7;
    letter-spacing: 1px;
    font-size: 14px;
} */

input[type="date"] {
  position: relative;
}

input[type="date"]:after {
  font-family: FontAwesome;
  font-weight: 500;
  content: "\f073";
  color: #555;
  padding: 0 0px;
  margin-top: 0px;
  font-size: 17px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

section.create-address {
  /* padding-top: 20px; */
  /* width: 100%; */
  display: block;
  margin: 0 auto;
  /* padding-bottom: 10px; */
  padding: 20px;
  border: 1px solid #fbce97;
  margin-bottom: 20px;
}

.create-address .form-control {
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

h5.address-heading {
  font-weight: bold;
  color: #3a3a3a;
}

.address-border {
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius: 3px;
  /* background: #f8f8f8; */
  margin-bottom: 5px;
}

section.create-parent {
  display: block;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #fbce97;
  margin-bottom: 20px;
}

.create-parent .form-control {
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

section.create-qualification {
  display: block;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #fbce97;
  margin-bottom: 20px;
}

table#tab_logic {
  overflow-x: auto;
  width: 100%;
  display: block;
}

.card {
  overflow-x: auto;
}

.table-row .form-control {
  width: 100%;
  min-width: 50px;
  /* max-width: 115px; */
  /* border: 1px solid #fbc58369; */
  padding: 5px;
  margin: 5px;
  border-radius: 3px;
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

.table-row thead, tbody {
  width: 100%;
  display: contents;
  /* word-wrap: break-word !important; */
  /* word-break: break-all; */
}
.table>thead>tr>th {
  vertical-align: middle !important;
}
.qualification-table .form-control {
  width: 100%;
  /* border: 1px solid #fbc58369; */
  padding: 5px;
  margin: 5px;
  border-radius: 3px;
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

.table-row input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  white-space: pre-line;
  position: relative;
  top: -7px;
  color: #dcdcdc;
}

.text-success .fa {
  float: right;
  padding: 10px 0px;
  font-size: 32px;
  color: #7bc248;
}

section.create-profile {
  display: block;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #fbce97;
  margin-bottom: 35px;
}

/* section.create-profile h4.download {
    font-size: 14px;
    text-align: center;
    color: #085dab;
    margin-bottom: 10px;
} */
section.create-profile a.download {
  text-align: left;
  display: block;
  font-size: 14px;
  color: #085db0;
}

.profile-detail .circle {
  border-radius: 10px;
  overflow: hidden;
  width: 90%;
  height: 150px;
  border: 1px solid #e29bc2;
  background: #e4e5e7;
  position: relative;
  margin-top: 10px;
}

.profile-detail .profile-pic {
  display: block;
  width: 100%;
  height: 100%;
}

.profile-detail .profile-pic img {
  max-width: 100%;
  height: 100%;
}

.profile-detail .circle .fa {
  font-size: 120px;
  text-align: center;
  color: #aeb4b7;
  display: block;
  margin: 10px auto;
}

.profile-add {
  padding: 11px 6px;
}

.profile-add .fa {
  font-size: 21px;
  color: #aeb4b7;
  cursor: pointer;
}

.profile-add h4 {
  display: initial;
  padding: 5px 8px;
  font-size: 16px;
  color: #086cba;
}

.profile-detail iframe {
  border: 1px solid #e29bc2;
}

.text-resume {
  text-align: center;
  padding-top: 22%;
}

.text-resume:before {
  content: "";
  height: 80px;
  background: #ecc28f;
  width: 1px;
  position: absolute;
  margin: auto;
  display: block;
  margin-top: -24px;
}

.text-resume:after {
  content: "";
  height: 80px;
  background: #ecc28f;
  width: 1px;
  position: absolute;
  right: 31px;
  margin: auto;
  display: block;
  margin-top: -41px;
}

.text-resume h3 {
  font-size: 16px;
  color: #a4a6a9;
}

/************************ reattemp preasessment *****************************/
.reattempt-footer{
  padding: 12px 8px;
  background: #fff;
  border-top: 1px solid #dcdcdc;
  position: fixed;
  left: 0;
  padding-right: 8%;
  bottom: 2%;
  width: 100%;
  color: white;
  text-align: center;
  z-index: 111;
}

/************************ ciel score report *****************************/
.cielLogo{
width: 150px;
height: auto !important;
margin: 0 auto;
}
.cielSign{
  width: 150px;
height: auto !important;
/* margin: 0 auto; */
margin-left: 76%;
}
/************************ Learner Dashboard *****************************/

#learner-dashboard .profile-details {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  min-height: 345px;
  /* max-height: max-content; */
  max-height: 345px;
  overflow-y: auto;
  border-radius: 10px;
}

#learner-dashboard h3.learner-title {
  color: #3a3a3a;
  padding-left: 5px;
  font-size: 16px;
  font-weight: 600;
}

#learner-dashboard h3.user-name {
  font-size: 20px;
  color: #3a3a3a;
  font-weight: 700;
  text-transform: uppercase;
}

#learner-dashboard h5.user-contact-details {
  color: #3a3a3a;
  font-weight: normal;
  /* word-wrap: break-word; */
  white-space: nowrap;
  /* width: 200px; */
  overflow: hidden;
  text-overflow: ellipsis;
}

#learner-dashboard .profile-details .border-bottom {
  border-bottom: 1px solid #dcdcdc;
}

#learner-dashboard .badge-certificate .fa {
  color: #f99f31;
  font-size: 20px;
}

#learner-dashboard .badge-certificate {
  border-bottom: 1px solid;
  display: flow-root;
  padding: 10px 0px;
  color: #dcdcdc;
}

#learner-dashboard h3.badge-details {
  color: #3a3a3a;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
 /* word-break: break-all; */
  white-space: nowrap;
  /* width: 200px; */
  overflow: hidden;
  text-overflow: ellipsis;
}

#learner-dashboard .badge-certificate .fa {
  color: #f99f31;
  font-size: 20px;
}

#learner-dashboard span.badge-number {
  background: #085dab;
  padding: 7px 12px;
  border-radius: 50%;
  text-align: center;
  margin: 30px;
  /* margin-top: 16px; */
}

#learner-dashboard div.DOBstyle {
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  /* margin-top: 16px; */
}

#learner-dashboard .profile-summary {
  margin-left: 15px;
}

#learner-dashboard .profile-details .profile-link {
  padding: 10px 0px;
}

#learner-dashboard .profile-details .profile-link a {
  color: #085dab;
  font-size: 14px;
  font-weight: 600;
}

#learner-dashboard .profile-details .profile-link a:hover {
  color: #f99f31;
}

#learner-dashboard .progress-overview {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  min-height: 345px;
  /* max-height: max-content; */
  max-height: 350px;
  overflow-y: auto;
  border-radius: 3px;
  border-radius: 10px;
}

#learner-dashboard .calendar-event {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  min-height: 390px;
  /* max-height: max-content; */
  max-height: 390px;
  overflow-y: auto;
  border-radius: 10px;
}
#learner-dashboard .my-status{
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  /* min-width: 430px; */
    /* max-width: 100%; */
  min-height: 345px;
  /* max-height: max-content; */
  max-height: 345px;
  overflow-y: auto;
  border-radius: 10px;
  scrollbar-width: thin !important;
}
#learner-dashboard .mystatuspadding{
  padding-left: 0px;
}
#learner-dashboard .mystatus{
  /* margin-top: 50px; */
  margin-bottom: 10px;
  /* padding-left: 0px; */
}
.my-status .MuiStepIcon-root.MuiStepIcon-completed {
  color: #4fbc56;
}
.my-status .MuiStepIcon-root.MuiStepIcon-active {
  color: #eb8c2e;
}
.my-status .MuiStepIcon-root {
  color: #cccccc;
}
.my-status .MuiSvgIcon-root {
  width: 2em;
  height: 2em;
}
.my-status .MuiStepConnector-vertical {
  padding: 0px 0 0px;
  margin-left: 15px;
  /* height: 100%;
  position: absolute; */
}
/* .my-status .AuditSubStatus{
  padding-left:25px 
} */
/* .my-status .MuiStepConnector-lineVertical {
  min-height: 100% !important;
} */
.my-status .MuiStepIcon-text {
  fill: #fff;
  font-size: 10px;
  font-family: 'Volte';
}
.my-status .MuiTypography-body2 {
  font-size: 14px;
  font-family: 'Volte';
  width: 100%;
  color: #3a3a3a !important;
  font-weight: 600 !important
}
#learner-dashboard .ml-notofication{
  /* margin-top: 10px; */
  /* padding-left: 0px */
}
#learner-dashboard .notificatio-details{
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  /* min-width: 430px; */
    /* max-width: 100%; */
  min-height: 390px;
  /* max-height: max-content; */
  max-height: 390px;
  overflow-y: auto;
  border-radius: 10px;
} 
#learner-dashboard .my-learning {
  border: 1px solid #f99f31;
  padding-top: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  overflow: hidden;
  min-height: 395px;
  max-height: max-content;
  /* max-height: 350px;
  overflow-y: auto; */
  border-radius: 10px;
  margin-bottom: 20px
}

#learner-dashboard .my-noreacord {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  overflow: hidden;
  min-height: max-content;
  max-height: max-content;
  border-radius: 10px;
}

#learner-dashboard .margin5 {
  margin-bottom: 5px;
}

#learner-dashboard .my-learning .course {
  /* border: 1px solid #f99f31;
  padding: 0px;
  width: 100%;
  min-height: 230px;
  max-height: max-content;
  min-height: auto; */

  border: 1px solid #f99f31;
  padding: 0px;
  width: 100%;
  min-height: 300px;
  max-height: 301px;
  /* max-height: max-content; */
  overflow-y: auto;
  /* min-height: auto;*/
  scrollbar-width: thin !important;
}
#learner-dashboard .my-learning .course {
}
#learner-dashboard .my-learning .video {
  margin-bottom: 0px;
  padding-bottom: 0px;
  width: 100%;
  height: 110px;
  /* min-height: 150px */
  
}

#learner-dashboard .my-learning .course-type {
  background: #f99f31;
  padding: 0px;
  margin: 0 auto;
}

#learner-dashboard .my-learning h5.course-name {
  margin: 0px;
  padding: 3px 7px;
  color: #fff;
}

#learner-dashboard h5.course-no {
  color: #000;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 10px;
    margin-bottom: 0px
}
.course-number .MuiCardHeader-root {
  padding-bottom: 0px;
}
#learner-dashboard .course-description p {
  font-size: 12px;
  color: #3a3a3a;
  padding: 0px 5px;
  margin-bottom: 0px;
  /* word-break: break-all; */
  word-break: break-word;
}

#learner-dashboard .course-rating {
  padding: 5px 5px;
}

#learner-dashboard .course-rating .fa {
  color: #f99f31;
  font-size: 12px;
}

#learner-dashboard .course-footer {
  /* background: #528dc4; */
  background: #7da0c2;
  color: #fff;
  text-align: center;
  padding: 0px;
  /* margin-top: 32%; */
}

#learner-dashboard .qc p {
  font-size: 12px;
  color: #3a3a3a;
  padding: 10px 0px;
}
div#nav-tabContent{
  width: 100%;
  overflow-x: auto
}
#learner-dashboard .progress-tab {
  /* padding: 10%; */
  /* margin-top: -8%; */
  margin-top: 4%;
  /* border: 1px solid #f99f31; */
  border: 1px solid #085dab;
  padding: 0px 15px;
  /* margin: 0px 15px; */
  display: visible;
  border-radius: 0px 0px 10px 10px;
}

#learner-dashboard .progress-tab-headings {
  background: #085dab;
  color: #fff;
  /* padding-left: 0px !important; */
  margin: 3px 0px;

}

#learner-dashboard svg.rc-progress-circle {
  width: 100%;
  padding: 20px;
  text-align: center;
  margin: 0 auto;
}

#learner-dashboard .social-stream {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  min-height: 335px;
  /* max-height: max-content; */
  max-height: 350px;
  overflow-y: auto;
  border-radius: 10px;
}

#learner-dashboard .scr-board h2 {
  font-size: 26px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #a83172;
  padding-bottom: 0px;
  padding-top: 18px;
  font-family: Volte-semibold;
}

#learner-dashboard.scr-board.sec-disable {
  pointer-events: none;
  opacity: 0.8;
  background: #fff;
  box-shadow: 0px 5px 17px #ddd;
}

#learner-dashboard td.pending {
  color: #f99f31;
}

#learner-dashboard td.solved {
  color: #7bc248;
}

#learner-dashboard .sec-activity {
  margin-top: 20px;
}

#learner-dashboard .sec-activity .bg-box {
  padding: 0px 50px;
  padding-bottom: 15px;
}

#nav-tab a {
  color: #fff;
  /* padding-right: 165px;
  padding-left: 13px; */
}

#nav-tab a:hover {
  text-decoration: none;
}

#nav-tab a:focus {
  text-decoration: none;
}

a#nav-home-tab {
  /* border-right: 1px solid #dcdcdc; */
}

#nav-tab .tab-button {
  display: flex;
}

/* #nav-tab .inner {
  display: inline-block;
} */

a#nav-profile-tab {
  border-right: 1px solid #dcdcdc;
}

#learner-dashboard .active {
  background: none;
}

#preassessment .auditStage {
  color: #000;
  font-weight: 600;
  font-size: 22px;
  padding: 0px 0px 10px;
}
/************************ Admin Dashboard *****************************/

#admin-dashboard .profile-details {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  min-height: 345px;
  /* max-height: max-content; */
  max-height: 350px;
  overflow-y: auto;
  border-radius: 10px;
}

#admin-dashboard h3.learner-title {
  color: #3a3a3a;
  padding-left: 5px;
  font-size: 16px;
  font-weight: 600;
}

#admin-dashboard h3.user-name {
  font-size: 20px;
  color: #3a3a3a;
  font-weight: 700;
}

#admin-dashboard h5.user-contact-details {
  color: #3a3a3a;
  font-weight: normal;
  word-wrap: break-word;
}

#admin-dashboard .profile-details .border-bottom {
  border-bottom: 1px solid #dcdcdc;
}

#admin-dashboard .badge-certificate .fa {
  color: #f99f31;
  font-size: 20px;
}

#admin-dashboard .badge-certificate {
  border-bottom: 1px solid;
  display: flow-root;
  padding: 10px 0px;
  color: #dcdcdc;
}

#admin-dashboard h3.badge-details {
  color: #3a3a3a;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  word-break: break-all;
}

#admin-dashboard .badge-certificate .fa {
  color: #f99f31;
  font-size: 20px;
}

#admin-dashboard span.badge-number {
  background: #085dab;
  padding: 7px 12px;
  border-radius: 50%;
  text-align: center;
  margin: 30px;
  /* margin-top: 16px; */
}

#admin-dashboard .profile-summary {
  margin-left: 15px;
}

#admin-dashboard .profile-details .profile-link {
  padding: 10px 0px;
}

#admin-dashboard .profile-details .profile-link a {
  color: #085dab;
  font-size: 14px;
  font-weight: 600;
}

#admin-dashboard .profile-details .profile-link a:hover {
  color: #f99f31;
}

#admin-dashboard .progress-overview {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  min-height: 345px;
  /* max-height: max-content; */
  max-height: 350px;
  overflow-y: auto;
  border-radius: 10px;
}

#admin-dashboard .calendar-event {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  min-height: 345px;
  /* max-height: max-content; */
  max-height: 350px;
  overflow-y: auto;
  border-radius: 10px;
}

#admin-dashboard .recent-activity {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  min-height: 500px;
  /* max-height: max-content; */
  max-height: 501px;
  overflow-y: auto;
  border-radius: 10px;
}

#admin-dashboard .recent-activity .form-control {
  border: 1px solid #f99f31;
  box-shadow: 3px 5px 8px #bfbfbf;
}

#admin-dashboard .recent-activity .course {
  border: 1px solid #f99f31;
  padding: 0px;
  width: 100%;
  min-height: 335px;
}

#admin-dashboard .recent-activity .video {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

#admin-dashboard .recent-activity .course-type {
  background: #f99f31;
  padding: 0px;
  margin: 0 auto;
}

#admin-dashboard .recent-activity h5.course-name {
  margin: 0px;
  padding: 3px 7px;
  color: #fff;
}

#admin-dashboard h5.course-no {
  color: #3a3a3a;
  font-weight: 600;
  padding: 5px 10px;
  margin-bottom: 0px
}

#admin-dashboard .course-description p {
  font-size: 12px;
  color: #3a3a3a;
  padding: 0px 5px;
  margin-bottom: 0px;
}

#admin-dashboard .course-rating {
  padding: 5px 5px;
}

#admin-dashboard .course-rating .fa {
  color: #f99f31;
  font-size: 12px;
}

#admin-dashboard .course-footer {
  background: #528dc4;
  color: #fff;
  padding: 10px;
}

#admin-dashboard .qc p {
  font-size: 12px;
  color: #3a3a3a;
  padding: 10px 0px;
}

#admin-dashboard .progress-tab {
  /* padding: 10%; */
  /* margin-top: -8%; */
  border: 1px solid #f99f31;
  padding: 0px 15px;
  /* margin: 0px 15px; */
  display: none;
}

.carousel.carousel-slider {
  min-height: 310px;
}

.carousel .slide {
  background: transparent !important;
  text-align: left !important;
}

.carousel .carousel-status {
  text-shadow: none !important;
  color: #3a3a3a !important;
  display: none
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #085dab !important;
}

#admin-dashboard .progress-tab-headings {
  background: #f99f31;
  color: #fff;
  /* padding-left: 0px !important; */
}

#admin-dashboard .social-stream {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  min-height: 500px;
  /* max-height: max-content; */
  max-height: 501px;
  border-radius: 10px;
}

#admin-dashboard .scr-board h2 {
  font-size: 26px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  color: #a83172;
  padding-bottom: 0px;
  padding-top: 18px;
  font-family: Volte-semibold;
}

#admin-dashboard.scr-board.sec-disable {
  pointer-events: none;
  opacity: 0.8;
  background: #fff;
  box-shadow: 0px 5px 17px #ddd;
}

#admin-dashboard td.pending {
  color: #f99f31;
}

#admin-dashboard td.solved {
  color: #7bc248;
}

#admin-dashboard .sec-activity {
  margin-top: 20px;
}

#admin-dashboard .sec-activity .bg-box {
  padding: 0px 50px;
  padding-bottom: 15px;
}

#admin-dashboard .enquiry-form {
  border-bottom: 1px solid #dcdcdc;
  /* height: 450px; */
  overflow-x: auto;
  overflow-y: auto;
}

#admin-dashboard table {
  /* font-family: arial, sans-serif; */
  border-collapse: collapse;
  width: 100%;
}

#admin-dashboard td,
th {
  /* border: 1px solid #dcdcdc; */
  text-align: left;
  padding: 8px;
}

/* #admin-dashboard tr:nth-child(even) {
    background-color: #dcdcdc;
  } */
.expriry-radio label.never {
  margin-left: 15%;
  margin-top: 6px;
}

.expriry-radio .checkbox,
.radio {
  position: relative;
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10%;
}

/************************* Distributor List ************************/
#distributor-list-box{
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  /* height: auto; */
  /* /* min-height: 345px; */
  max-height: max-content;
  /* max-height: 350px;  */
  overflow-y: auto;
  border-radius: 10px;
}
#distributor-list-box .table-header h3 {
  /* padding: 8px 24px 11px; */
}
#distributor-list-box span.right-icon {
  float: right;
}
#distributor-list-box span.right-icon a{
  color: #fff
}

/************************* Pre styele ************************/
pre#pre-style{
  font-family: 'Volte' !important;
  background: no-repeat !important;
  border: none !important;
  margin: 0 auto !important;
  padding: 2px !important;
  font-size: 13px !important;
  color: #3a3a3a !important;
  font-weight: bold !important;
}
/************************* Funnel ************************/
#funnelChart {
  border: 1px solid #f99f31;
  padding: 15px;
  box-shadow: 3px 5px 8px #bfbfbf;
  float: left;
  margin: 5px;
  width: 100%;
  min-height: 345px;
  /* max-height: max-content; */
  max-height: 350px;
  overflow-y: auto;
  border-radius: 10px;
  margin-bottom: 30px;
}
#funnelChart ul{
list-style: none;
text-align: left;
/* padding-left: 0px; */
margin-left: 35px

}
#funnelChart ul li .lable{
  font-size: 12px;
}
#funnelChart ul li .fa{
  font-size: 14px;
  }
#funnelChart .F-blue .fa{
    color: #0f65b3;
    padding-right: 5px;
    }
#funnelChart .F-orange .fa{
      color: #de8123;
      padding-right: 5px;
      }
  
#funnelChart .F-green .fa{
        color: #519b1c;
        padding-right: 5px;
        }
#funnelChart .F-purple .fa{
          color: #a02769;
          padding-right: 5px;
          }
#funnelChart .F-skyblue .fa{
          color: #328ea7;
          padding-right: 5px;
          }
#funnelChart .F-red .fa{
            color: #d84640;
            padding-right: 5px;
            }
 #funnelChart .F-mt{
   /* margin-top: 70% */
   margin-top: 30%;
 }
 #funnelChart .F-mt1{
  /* margin-top: 40% */
  margin-top: 20%;
}
#funnelChart .F-mt2{
  /* margin-top: 25% */
  margin-top: 15%;
}
#funnelChart .F-mt3{
  margin-top: 7%
}
#funnelChart .F-mt4{
  margin-top: 5%
}
#funnelChart .F-mt5{
  /* margin-top: 3% */
}
.overlay li{
  padding: 8px 0px;
  color: #fff;
  font-size: 12px;
}
.overlay strong{
  float: right;
  font-size: 16px;
  font-weight: 600
}
.overlay {
  display:none;
}
#funnel-hover:hover .overlay {
  /* width:100%;
  height:100%;
  background:rgba(0,0,0,.5);
  position:absolute;
  top:0;
  left:0;
  display:block;
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box; */

    width: 82%;
    height: auto;
    text-align: center;
    background: rgba(0,0,0,.5);
    position: absolute;
    top: 10%;
    left: 8%;
    display: block;
    box-sizing: border-box;
    border-radius: 2px;
    color: #fff;
    padding: 15px;
    margin: 0 auto;
}
/************************* Pie Chart ************************/
g text {
  font-size: 12px !important;
  font-family: 'Volte' !important;
  font-weight: unset !important;
  color: #3a3a3a !important;
}
g.raphael-group-lnEmTxwD {
  display: none !important;
}

#action-dropdown .fa{
  color: #fff;
    font-size: 16px;
    float: right;
    font-size: 16px;
    border: 2px solid #fff;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 28px;
    border-radius: 50px;
    color: #fff;
    margin-top: -5px;
}
#action-dropdown .notification .fa{
  color: #fff;
    font-size: 16px;
    float: right;
    font-size: 16px;
    border: 2px solid #fff;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 9px;
    border-radius: 50px;
    color: #fff;
    margin-top: -5px;
}
#action-dropdown li a{
  text-align: left;
  padding: 0px;
  color: #085dab !important;
}
#action-dropdown li{
  list-style: none
}
#action-dropdown .dropdown-menu {
  top: 34px;
  right: 2px !important;
  width: 300px;
}
#action-dropdown #notification-profile li a {
  width: 100%;
  padding-bottom: 15px;
}
/************************* My Payment ************************/
.filter {
  border: 1px solid #dcdcdc;
  padding: 10px;
  background: #f0f8ff;
}

.filter .form-control {
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

h5.filter-heading {
  color: #085dab;
  font-size: 16px;
  font-weight: bold;
}


/*********** learner apply cfx *************/

ul.feat-report {
  background: #f2f3f5;
  width: 80%;
  display: block;
  margin: 0 auto;
  padding: 10px 18px;
  padding-bottom: 10px;
}

.feat-report li {
  display: inline-block;
  padding: 0px 9px;
  font-size: 14px;
}

.feat-report li:nth-child(3) {
  color: #a83172;
  font-weight: 700;
}

.feat-report .badge {
  display: inline-block;
  min-width: 10px;
  padding: 4px 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  border-radius: 3px;
}

.feat-report .right-line {
  content: "";
  width: 1px;
  height: 16px;
  position: relative;
  right: 0px;
  margin: auto;
  display: block;
  top: 4px;
  background: #fda05e78;
  display: inline-flex;
}

.report-bg {
  margin-top: 32px;
  padding-bottom: 0;
  margin-bottom: -40px;
}

/************************ Assign Roll dashboard *****************************/

.assign-role .sec-assign {
  padding-top: 70px;
  width: 90%;
  display: block;
  margin: 0 auto;
  padding-bottom: 35px;
}

.assign-role .sec-assign h1 {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

.assign-role .sec-assign h3 {
  text-align: center;
  padding: 24px;
  text-transform: uppercase;
  color: #626465;
}

.assign-role .sec-assign h2 {
  font-size: 19px;
  text-transform: uppercase;
  border-bottom: 1px solid #fde2bf;
  width: fit-content;
  padding-top: 20px;
  color: #a83172;
  padding-bottom: 5px;
}

.assign-role .scr-board {
  box-shadow: none;
  background: none;
  padding-bottom: 0px;
}

.assign-role .scr-board .lock-img {
  margin-bottom: 8px;
}

.assign-role .right-line {
  content: "";
  width: 1px;
  height: 55%;
  position: absolute;
  right: 0;
  margin: auto;
  display: block;
  top: 40%;
  background: #fde2bf;
}

.circle-toggle .toggle-label {
  position: relative;
  display: block;
  width: 120px;
  height: 35px;
  border: 1px solid #7bc248;
  box-shadow: 2px 4px 10px #a9a6a6;
  cursor: pointer;
  border-radius: 50px;
  margin: 0 auto;
  margin-bottom: 25px;
}

.circle-toggle .toggle-label input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.circle-toggle .toggle-label input[type="checkbox"]:checked+.back {
  background: #fff;
  /*white*/
  border-radius: 50px;
}

.circle-toggle .toggle-label input[type="checkbox"]+.back .toggle {
  display: block;
  position: absolute;
  content: " ";
  background: #7bc248;
  width: 50%;
  height: 100%;
  transition: margin 150ms linear;
  border: 1px solid #7bc248;
  border-radius: 50px;
}

.bg_yellow.circle-toggle .toggle-label input[type="checkbox"]+.back .toggle {
  background: #f99f31;
  transition: margin 150ms linear;
  border: 1px solid #f99f31;
}

.circle-toggle .toggle-label input[type="checkbox"]:checked+.back .toggle {
  margin-left: 60px;
}

.circle-toggle .toggle-label .label {
  display: block;
  position: absolute;
  width: 47%;
  color: #fff;
  line-height: 27px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
}

.circle-toggle .toggle-label .label.on {
  left: 0px;
}

.circle-toggle .toggle-label .label.off {
  right: 0px;
}

.circle-toggle .toggle-label input[type="checkbox"]:checked+.back .label.on {
  color: #c9c5cc;
  /***on text***/
}

.circle-toggle .toggle-label input[type="checkbox"]+.back .label.off {
  color: #c9c5cc;
  /***off text***/
}

.circle-toggle .toggle-label input[type="checkbox"]:checked+.back .label.off {
  color: #fff;
}

.assign-role .sec-management {
  padding-top: 60px;
}

.assign-role .sec-checkbox {
  padding: 5px 20px;
}

.assign-role .cus-checkbox {
  display: inline;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 100;
  padding: 0px 24px;
  margin-right: 15px;
}

.assign-role .cus-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.assign-role .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #bfbebe;
}

.assign-role .cus-checkbox input:checked~.checkmark {
  background-color: #fff;
  border: 1px solid #bfbebe;
}

.assign-role .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.assign-role .cus-checkbox input:checked~.checkmark:after {
  display: block;
}

.assign-role .cus-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #827d7d;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.role-name {
  margin-left: 24px;
}

.role-name input[type="text"] {
  padding: 11px;
  font-size: 16px;
  border: 1px solid #ecebeb;
  float: left;
  width: 100%;
  background: #fff;
  box-shadow: 5px 5px 6px #ddd;
  letter-spacing: 1px;
}

.ReactTable .rt-thead {
  font-weight: 700;
}

.column-center {
  text-align: center;
}

/************************** Add Role ****************************/

.add-role .form-control {
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

/************************ Update GDPI ****************************/

.updateGDPI .form-control {
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

/************************ Bulk upload summary *****************************/

.bulk-summary .bulkupload-tab p {
  font-size: 19px;
}

.bulk-summary .bulkupload-tab span {
  float: right;
  font-size: 45px;
  font-weight: bold;
}

.bulk-summary .bulkupload-tab.user-table td {
  padding: 7px 0px;
  background: #fff;
  border-top: none;
  padding-bottom: 0;
  border-bottom: 1px solid #f9d6ac;
}

.bulkupload-tab .records {
  color: #a83172;
}

.bulkupload-tab .success {
  color: #7bc248;
}

.bulkupload-tab .duplicate {
  color: #f99f31;
}

.bulkupload-tab .fail {
  color: #f13e3a;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
#bulkUploadNISM .btn-file1 {
  position: relative;
  overflow: hidden;
  left: unset;
  bottom: unset;
  text-align: center;
}
#bulkUploadNISM .browse-text .fa{
  font-size: 20px;
    padding-right: 10px;
    color: #085dab;
    font-weight: 400;
}
#bulkUploadNISM .file-drop {
  position: relative;
  overflow: hidden;
  /* left: 163px; */
  /* bottom: 50px; */
  border: 1px solid #dcdcdc;
  padding: 35px;
  width: 100%;
  height: 80px;
  text-align: center;
  background: #dcdcdc;
}
.btn-file1 {
  position: absolute;
  overflow: hidden;
  left: 156px;
  bottom: 46px;
}

.btn-file2 {
  right: 71px;
  bottom: 49px;
  position: absolute;
}

.btn-file3 {
  right: 71px;
  bottom: unset !important;
  position: absolute;
}

.text-wrap-bulk-upload {
  word-break: break-all;
  width: 80%;
}
#uploadedFileName{
  display: initial;
    padding: 5px 8px;
    font-size: 16px;
    color: #086cba;
    width: 100%;
}
#uploadedFileName1{
    font-size: 16px;
    color: #086cba;
    width: 100%;
}
.browse-text {
  border: none;
  background: 0;
}

.btn-file input[type="file"] {
  z-index: 999;
  top: -3px;
}

/* .btn-file input[type="file"] {
  position: absolute;
  top: 0;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
} */

.btn-file input[type="file"] {
  position: absolute;
  bottom: 0;
  left: 0;
  /* text-align: right; */
  filter: alpha(opacity=0);
  /* opacity: 0; */
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: auto;
  font-size: 0;
  position: absolute;
}

.scr_bulk .scr-form .form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 0px 0px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #555;
  background-color: transparent;
  background-image: none;
  border: none !important;
  border-radius: 0px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e0dede !important;
  -webkit-box-shadow: none !important;
}

.bulk-summary .scr-board.scr_bulk {
  position: initial;
  z-index: 99999;
  /* margin-top: 20px; */
  margin-bottom: 15px;
  box-shadow: 1px 2px 21px #ddd;
  padding: 15px;
}

h4.bulk_head {
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}

.bulk-summary .bulk_head .fa {
  color: #085dab;
  margin-left: 10px;
}

.bulk-summary span.icon.btn-file {
  color: #085dab;
  font-size: 25px;
  margin-top: -55px;
}

.bulk-summary .bg-box.bulk-box {
  /* border: 1px solid #e0a8c7; */
  box-shadow: none;
  margin-bottom: 0;
}

.bulk-summary .create-user .table-header {
  background: #085dab;
  position: relative;
  height: auto;
  padding: 7px 5px;
  top: 0px;
  width: 92%;
  margin: 0px 20px 0px 20px;
  box-shadow: 10px 10px 9px #d6d4d4;
}

.bulk-summary .create-user .table-header {
  background: #085dab;
  position: relative;
  height: auto;
  padding: 7px 5px;
  top: 0px;
  width: 92%;
  margin: 0px 20px 0px 20px;
  box-shadow: 10px 10px 9px #d6d4d4;
}

.bulk-summary .create-user .table-header {
  background: #085dab;
  position: relative;
  height: auto;
  padding: 7px 5px;
  top: 0px;
  width: 92%;
  margin: 0px 20px 0px 20px;
  box-shadow: 10px 10px 9px #d6d4d4;
}

.bulk-summary .create-user .table-header {
  background: #085dab;
  position: relative;
  height: auto;
  padding: 4px 5px 8px 5px;
  top: 0px;
  width: 92%;
  margin: 0px 20px 0px 20px;
  box-shadow: 10px 10px 9px #d6d4d4;
}

.bulk-summary .create-user .table-header {
  background: #085dab;
  position: relative;
  height: auto;
  padding: 3px 5px 8px 5px;
  top: 0px;
  width: 92%;
  margin: 0px 20px 0px 20px;
  box-shadow: 10px 10px 9px #d6d4d4;
}

.bulk-summary .user-table.bulkupload-tab {
  width: 65%;
  margin: 0 auto;
  margin-top: 70px;
}

.bulk-summary .footer-list,
h3 {
  margin-top: -36px;
  font-size: 18px;
  color: #085dab;
  font-weight: 500;
}

.bulk-summary .footer-list .fa {
  margin-right: 7px;
}

/************************ Edit User *************************/

.edit-user table {
  border: 1px solid #e8e7e7;
  width: 92%;
  margin-bottom: 20px;
  background: #f4f4f5;
}

.edit-user th,
td {
  border-bottom: 1px solid #dcdcdc;
  padding: 5px;
}

.profile-left-sidebar .table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
  /* padding: 10px; */
  /* margin-top: 45%; */
  /* position: fixed; */
  background: #ffffff;
  box-shadow: 10px 10px 9px #d6d4d4;
}

.profile-left-sidebar .table-responsive table {
  /* padding: 19px; */
  /* background: #000; */
  margin: 10px;
}

/************************ reset password *************************/

.resetpass-modal .reset {
  margin-top: 12%;
}

/* .resetpass-modal .reset h3 {
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 25px;
} */

.resetpass-modal .reset h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  text-transform: none;
  padding-bottom: 25px;
}

.resetpass-modal .reset p {
  letter-spacing: 1px;
}

.resetpass-modal img {
  height: auto !important;
}

/*********************** Feat Payment detail *************************/

.feat-payment .payment-detail {
  padding-top: 50px;
  padding-bottom: 20px;
}

table.paydetail-table {
  width: 90%;
  display: table;
  margin: 0 auto;
}

.feat-payment .payment-detail .paydetail-table th {
  font-weight: 800;
  color: #000;
  border-bottom: 1px solid #eacba5;
  font-size: 16px;
  padding: 5px;
}

.feat-payment .payment-detail .paydetail-table td {
  padding: 6px;
  width: 5%;
  border-bottom: none;
  font-size: 15px;
}

.feat-payment .table-header {
  height: 55px;
  top: 20px;
}

.feat-payment .table-header h3 {
  padding: 17px 24px;
}

.feat-payment .pay-detail {
  padding: 10px;
}

.feat-payment .pay-detail td:nth-child(2) {
  font-size: 20px;
}

.feat-payment h2.sub-header {
  margin-left: 35px;
}

.checkout .form-control {
  width: 70%;
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

.checkout .coupon-code {
  width: 90%;
  margin: 0 auto;
}

.checkout .apply-coupon {
  margin-top: -40px;
  float: right;
}

.checkout .bg-box {
  box-shadow: 2px 9px 27px #e6e6e6;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #fbc583;
  border-radius: 5px;
}

/*********************** Already Feat Registered *************************/

.already-feat-regestered {
  border: 1px solid #fbc583;
  border-radius: 5px;
}

.feat-reg h3 {
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 25px;
  text-align: center;
}

.feat-reg p {
  text-align: center;
  padding-bottom: 20px;
}

/*********************** Footer *************************/

footer {
  padding: 12px 8px 0px;
  /* padding: 7px 8px 1px; */
  background: #085dab;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  z-index: 111;
}

p.text-center a {
  font-size: 14px;
}

footer p>a {
  color: #fff;
  padding-right: 5px;
}

footer a:focus,
a:hover {
  color: #cf5822;
  text-decoration: none;
}

.btn-scrolltop {
  background: antiquewhite;
  border-radius: 50px;
  height: 40px;
  width: 40px;
}

#return-to-top {
  position: fixed;
  right: 5px;
  background: linear-gradient(to right, #0093ff 0%, #206db3 60%);
  width: 42px;
  height: 42px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  bottom: 8%;
  z-index: 999;
}

#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 18px;
  top: 11px;
  font-size: 17px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#return-to-top:hover {
  background: rgba(0, 0, 0, 0.9);
}

#return-to-top:hover i {
  color: #fff;
  top: 5px;
}

/****************** Change Mobile & Email ID  *****************/

.change-mobile-email {
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
  padding: 20px;
  margin: 8%;
}

h2.change-mo-email-title {
  font-size: 20px;
  font-weight: 800;
  padding-bottom: 15px;
  color: #085dab;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.change-mobile-email .form-control {
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}

.change-mobile-email .form-control.enter-otp {
  width: 60%;
}

/************************* Error-Page *****************************/

#notfound .notfound {
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.notfound {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

.notfound .notfound-404 {
  height: 280px;
  position: relative;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  /*font-family: 'Montserrat', sans-serif;*/
  font-size: 230px;
  margin: 0px;
  font-weight: 900;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background: url('../images/bg.jpg') no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  /* color:#3a3a3a */
}

.notfound h2 {
  /*font-family: 'Montserrat', sans-serif;*/
  color: #3a3a3a;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}

.notfound p {
  /*font-family: 'Montserrat', sans-serif;*/
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0px;
}



/************************ Welcome Page **************************/

section.welcome-page {
  /* position: relative; */
  height: 100vh;
  background-image: url('../images/banner-bg1.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

#welcome .welcome {
  position: absolute;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.welcome {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

.welcome .welcome-logo {
  height: 90px;
  position: relative;
  z-index: -1;
}

.welcome .welcome-logo h1 {
  /*font-family: 'Montserrat', sans-serif;*/
  font-size: 230px;
  margin: 0px;
  font-weight: 900;
  /* position: absolute; */
  /* left: 50%; */
  /* -webkit-transform: translateX(-50%); */
  /* -ms-transform: translateX(-50%); */
  /* transform: translateX(-50%); */
  /* background: url('../images/partner-bg.jpg') no-repeat; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  /* color:#3a3a3a */
}

.welcome h2 {
  /*font-family: 'Montserrat', sans-serif;*/
  color: #085dab;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.welcome p {
  /*font-family: 'Montserrat', sans-serif;*/
  color: #000;
  font-size: 30px;
  line-height: 1em;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 25px;
}



/******************************** Tour ************************************************/
.skip-button {
  position: fixed;
  bottom: 15%;
  float: left;
  left: 5px;
  z-index: 99999;

}

/********************************* Add Product List *****************************************/

.product-add .form-control {
  box-shadow: 0px 2px 9px #f4e1c9;
  border: 1px solid #fbc583 !important;
}


.learningTitle a.info-bar {
  background: #555;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  margin-left: 10px;
}

/************************ FaQ Style ***************************************/
/* Style the tab */
#faq .tab {
  /* float: left; */
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  border-radius: 5px;
  /* width: 25%; */
  height: auto;
  /* margin-left: 5%; */
}

/* Style the buttons inside the tab */
#faq .tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
#faq .tab button:hover {
  background-color: #69a63a;
  color: #fff;
}

/* Create an active/current "tab button" class */
#faq .tab button.active {
  background-color: #085dab;
  color: #fff
}

/* Style the tab content */
#faq .tabcontent {
  /* float: left; */
  /* padding: 0px 12px; */
  /* border: 1px solid #ccc; */
  /* width: 65%; */
  border-left: none;
  height: auto;
}

/**************************** RoadMap *******************************/

section.road_map {
  margin-top: 45px;
  margin-bottom: 30px;
}

.road_map_image {
  margin-bottom: 80px;
}

.road_map_img {
  margin-bottom: 20px;
}

.roadmap_cfx {
  margin: 25px 0px;
}

.road_map h1.heading-title {
  font-size: 55px;
  font-weight: 600;
  line-height: 1.2em;
  color: #3a3a3a;
  background: transparent;
  text-align: center;
  margin-bottom: 10px;
}

.road_map .border_bottom {
  border-bottom: 5px solid #075eac;
  width: 25%;
  align-items: center;
  margin-left: 38%;
  margin-bottom: 10px;
}

.road_map p {
  color: #3a3a3a;
  font-size: 14px;
  /* font-weight: 600; */
  line-height: 27px;
  text-align: center;
}

.road_cfx_img img.img-responsive {
  width: 140px;
  height: auto;
}

.road_map .border_bottom-1 {
  /* margin: 15px 70px 30px 0px; */
  margin: 10px auto;
  height: 3px;
  background: linear-gradient(to right, #0093ff 0%, #085dab 100%);
}

p.roadmap-text {
  margin: 10px 0px;
  line-height: 1.5;
}

/**** Left Fixed SideNav *******/

nav {
  white-space: nowrap;
  /* background: #37474F; */
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.table-header nav ul li a.current {
  background: #085dab !important;
  border-radius: 3px !important;
  padding: 5px 10px;
}

/* Only stick if you can fit */

@media (min-height: 300px) {
  nav ul {
    position: relative;
    top: 0;
  }
}

/********************* Notification-Alert Popup ****************************/

/* .nav-pills .nav-link.active,
        .nav-pills .show>.nav-link {
            background-color: #17A2B8;
        } */

#notification-alert .bg-dark {
  background: #6b9ecd;
}

#notification-alert .text-light a {
  color: #fff;
}

#notification-alert .text-info {
  word-break: break-all;
  white-space: pre-wrap;
  padding-right: 5px;
  text-transform: uppercase;
  font-size: 15px;
}

#notification-alert .text-info.unread{
color: #3a3a3a !important
}
#notification-alert .text-light a:hover {
  color: #eee;
  text-decoration: underline;
}

.dropdown-menu {
  top: 70px;
  right: 15px !important;
  left: unset;
  width: 520px;
  /* box-shadow: 3px 5px 8px #bfbfbf; */
  padding-bottom: 0px;
  padding: 0px;
}

.dropdown-menu:before {
  content: "";
  position: absolute;
  top: -20px;
  right: 12px;
  border: 10px solid #6b9ecd;
  border-color: transparent transparent #6b9ecd transparent;
}

.dropdown-menu span {
  color: #fff;
  line-height: 2;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
  background: none;
  border: none;
}

#notification-alert li a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.42857143;
  color: #085dab;
  white-space: nowrap;
}
#notification-alert li a:hover {
  color: #fff;
  text-decoration: none;
  background: none;
  font-size: 15px;
}
#notification-alert li a:focus {
  color: #cf5822;
  text-decoration: none;
  background: none;
  font-size: 15px;
}

/* .dropdown-menu>li>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #fff;
  white-space: nowrap;
} */

#notification-alert .head {
  padding: 5px 15px;
  border-radius: 3px 3px 0px 0px;
}

.notification-content {
  border-bottom: 1px solid #dcdcdc;
  margin-top: 10px;
}
ul#notification-profile .notification-content {
  margin-top: 0px;
}
#notification-alert p.alert-body {
  text-transform: uppercase;
  color: #085caa;
}

#notification-page .panel-title-email {
  /*position: absolute; */
    /* left: 81%; */
    padding-top: 10px;
    border-left-style: groove;
    text-transform: uppercase;
    color: #085caa;
    font-size: 14px;
    /* margin: 0 auto;
}
#notification-alert h4.panel-title {
  text-transform: capitalize;
}

#notification-page h4.panel-title {
  text-transform: capitalize;
  font-size: 16px;
  /* width: 80% */
}
#notification-page .panel-title-date {
  font-size: 14px;
  color: #fa9f34;
}

#notification-alert .footer {
  padding: 5px 15px;
  border-radius: 0px 0px 3px 3px;
}

#notification-alert .notification-box {
  padding: 0px 10px 10px;
}

#notification-alert .notification-body {
  word-break: break-all;
  white-space: pre-wrap;
  color: #3a3a3a;
  padding-right: 5px;
  text-align: left;
}

#notification-alert .bg-gray {
  background-color: #eee;
}



/************************* Notification--Alert Page *************************/

.panel-default .panel-heading {
  color: #000;
  background-color: #fff;
  border-color: #ddd;
  /* padding-bottom: 23px; */
}
.panel-default .panel-heading.unread {
  color: #085caa;
  background-color: #fff;
  border-color: #ddd;
  /* padding-bottom: 23px; */
}
.panel-default .panel-heading:hover {
  color: #fff;
  background: #69a63a;
}

a.accordion-toggle:focus {
  text-decoration: none;
}



/************************ Privacy Policy ***********************/

.privacy-policy h1.text-center {
  color: #085dab;
  font-weight: 800;
  text-transform: uppercase;
}

.privacy-policy h5 {
  font-size: 16px;
  color: #414141;
  text-transform: uppercase;
  font-weight: 600;
}

.privacy-policy p {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 2;
}

.privacy-policy h6.note {
  font-size: 16px;
  font-weight: 800;
  color: #000;
}

/************************ Cookies Policy ***********************/

.cookies-policy h1.text-center {
  color: #085dab;
  font-weight: 800;
  text-transform: uppercase;
}

.cookies-policy h5 {
  font-size: 16px;
  color: #414141;
  text-transform: uppercase;
  font-weight: 600;
}

.cookies-policy p {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 2;
}

/************************ Disclaimer ***********************/

.disclaimer h1.text-center {
  color: #085dab;
  font-weight: 800;
  text-transform: uppercase;
}

.disclaimer strong {
  font-size: 16px;
  color: #414141;
  font-weight: 600;
}

.disclaimer p {
  font-size: 14px;
  color: #3a3a3a;
  line-height: 2;
}

.react-tiny-popover-container {
  position: fixed !important;
  top: 106px;
  left: 1090px;
  background: rgb(255, 255, 255);
  opacity: 1;
  z-index: 99999;
  border: 1px solid rgb(249, 159, 49);
  border-radius: 5px;
  padding: 15px;
  box-shadow: rgb(191, 191, 191) 3px 5px 8px;
  transition: opacity 0.35s ease 0s;
}

.box {
  width: 100px;
  /* height: 75px; */
  /* background-color: fff;
  border: 1px solid rgb(249, 159, 49);
  box-shadow: rgb(191, 191, 191) 3px 5px 8px; */
  color: #424242;
  /* padding: 20px; */
  position: relative;
  /* margin: 40px; */
  float: left;
  z-index: 9999;
}

/* .box.arrow-top {
  margin-top: 10px;
}

.box.arrow-top:after {
  content: " ";
  position: absolute;
  right: 30px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid black;
} */


/* Popup container - can be anything you want */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup .popuptext {
  visibility: hidden;
  /* font-size: 14px; */
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/****************** ******************/
/* Popup container - can be anything you want */
.popup1 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup1 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup1 .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup1 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/************************ *************************/
/* Popup container - can be anything you want */
.popup2 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup2 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup2 .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup2 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}


/************************ *************************/
/* Popup container - can be anything you want */
.popup3 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup3 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup3 .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup3 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}


/************************ *************************/
/* Popup container - can be anything you want */
.popup4 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup4 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup4 .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup4 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}


/************************ *************************/
/* Popup container - can be anything you want */
.popup5 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup5 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #fff;
  color: #555;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  top: 5%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup5 .popuptext::after {
  content: "/25b2";
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup5 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}


/************************ *************************/
/* Popup container - can be anything you want */
.popup6 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup6 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #fff;
  color: #555;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  top: 5%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup6 .popuptext::after {
  /* content: "/25b2"; */
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup6 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/************************ *************************/
/* Popup container - can be anything you want */
.popup7 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup7 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */
  top: 85%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup7 .popuptext::after {
  /* content: ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup7 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}


/************************ *************************/
/* Popup container - can be anything you want */
.popup8 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup8 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */
  top: 85%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup8 .popuptext::after {
  /* content: ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup8 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
/************************ *************************/

/* Popup container - can be anything you want */
.popup9 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup9 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */
  top: 85%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup9 .popuptext::after {
  /* content: ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup9 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/************************ *************************/
/* Popup container - can be anything you want */
.popup10 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup10 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #fff;
  color: #555;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  top: 5%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup10 .popuptext::after {
  /* content: "/25b2"; */
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup10 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/************************ *************************/
/* Popup container - can be anything you want */
.popup11 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup11 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #fff;
  color: #555;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  top: 5%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup11 .popuptext::after {
  /* content: "/25b2"; */
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup11 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/************************ *************************/
/* Popup container - can be anything you want */
.popup13 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup13 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */
  top: 85%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup13 .popuptext::after {
  /* content: ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup13 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
/************************ *************************/

/************************ *************************/
/* Popup container - can be anything you want */
.popup14 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup14 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */
  top: 85%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup14 .popuptext::after {
  /* content: ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup14 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
/************************ *************************/

/************************ *************************/
/* Popup container - can be anything you want */
.popup15 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup15 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */
  top: 85%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup15 .popuptext::after {
  /* content: ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup15 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
/************************ *************************/

/************************ *************************/
/* Popup container - can be anything you want */
.popup16 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup16 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */
  top: 85%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup16 .popuptext::after {
  /* content: ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup16 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
/************************ *************************/


/************************ *************************/
/* Popup container - can be anything you want */
.popup17 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup17 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */
  top: 85%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup17 .popuptext::after {
  /* content: ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup17 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
/************************ *************************/


/************************ *************************/
/* Popup container - can be anything you want */
.popup18 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup18 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */
  top: 85%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup18 .popuptext::after {
  /* content: ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup18 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
/************************ *************************/


/************************ *************************/
/* Popup container - can be anything you want */
.popup19 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup19 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */
  top: 85%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup19 .popuptext::after {
  /* content: ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup19 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
/************************ *************************/


/************************ *************************/
/* Popup container - can be anything you want */
.popup20 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup20 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */
  top: 85%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup20 .popuptext::after {
  /* content: ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup20 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
/************************ *************************/

/************************ *************************/
/* Popup container - can be anything you want */
.popup21 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup21 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #085dab;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 125%; */
  top: 85%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup21 .popuptext::after {
  /* content: ""; */
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #085dab transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup21 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
/************************ *************************/


/************************ *************************/
/* Popup container - can be anything you want */
.popup22 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
.popup22 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #fff;
  color: #555;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  top: 5%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup22 .popuptext::after {
  /* content: "/25b2"; */
  position: absolute;
  bottom: 90%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup22 .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}


/************************ *************************/
/* Popup container - can be anything you want */
.popup23 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* The actual popup */
/* .popup23 .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #fff;
  color: #555;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  top: 5%;
  left: 50%;
  margin-left: -80px;
} */

.popup23.popuptext {
    visibility: hidden;
    width: 160px;
    background-color: #fff;
    color: #555;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    top: 5%;
    left: 50%;
    margin-left: -80px;
}

/* Popup arrow */
.popup23.popuptext::after {
  content: "/25b2";
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup */
.popup23.popuptext.show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}


/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}




/*********************** Footer-1 ********************/

section.footer-1 {
  margin: 30px 0px 0px;
  padding: 30px 0px 50px;
  background: #f4f4f4;
  border-top: 4px solid #000;
}

h1.footer-title {
  font-weight: 700;
  color: #000;
  padding-bottom: 10px;
  border-bottom: 2px solid #000;
  text-align: left;
  margin-bottom: 18px;
}

.footer-1 p {
  font-size: 19px;
  font-weight: 700;
  padding: 10px 0px;
}

.footer-1 ul {
  list-style-type: none;
  text-align: left;
  padding-left: 0px;
}

.footer-1 ul li {
  font-size: 17px;
  font-weight: 700;
  line-height: 27px;
}

.footer-1 ul li a {
  color: #000;
}

.footer-1 ul li a:hover {
  color: #337ab7;
}

.footer-1 .btn-footer {
  margin: auto;
  font-size: 16px;
  font-weight: 700;
  display: block;
  padding: 8px 28px;
  border: none;
  border-radius: 2px;
  color: #fff;
  background: #7bc249;
  transition: .5s;
  box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.5);
}

.footer-1 .btn-footer:hover {
  background: #000;
}


/********************************** Add Course ***********************************/
.well {
  min-height: 299px;
  max-height: 300px;
  overflow-y: auto;
  padding: 19px;
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid #f99f31;
}

.well .input-group-addon {
  top: 0;
  color: #085dab;
  background-color: #fff;
}

.well .glyphicon-unchecked:before {
  color: #085dab;
}

.add-course {
  background: #fff;
  margin: 0 auto;
  padding: 15px;
  text-align: left;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 9px #d6d4d4;
}


/****************************** Progress Bar ************************************************/
span.progressIcon {
  font-size: 16px;
  display: inline-flex;
}

.progressIcon .fa {
  font-size: 30px;
  padding: 0px 10px 0px 0px;
}

.progressIcon strong {
  padding-top: 7px;
}

span.progressGraph {
  display: inline-flex;
  /* margin-right: 10px;
  padding-right: 10px; */
}

span.progressProduct {
  margin: 0px 15px;
}

h5.ProgressStatus {
  color: #085dab;
  background: aliceblue;
  width: max-content;
  padding: 2px;
  border-radius: 4px;
}

#preassessment{
  margin-bottom: 10%;
  padding-top: 50px
}
  
#preassessment .assessment-title{
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

#preassessment .boxbg{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 1px solid #aaa;
  border-radius: 5px;
 
}

#preassessment .boxbg:hover{
  border: 1px solid #fbc583 !important;
  box-shadow: 0px 2px 9px #ffbb65 !important;
}
#preassessment .assess-details{
  padding: 10px;
  margin-top: 20px;
  text-align: center;
}

#preassessment .timer-details{
  padding: 10px;
  text-align: center
}

#preassessment .thank-you-title{
  font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding-top: 15px;
    color: #085dab;   
}
#preassessment .assess-count.title {
  border-bottom: 1px solid #dcdcdc;
}
#preassessment .assess-progress {
  font-size: 13px;
  font-weight: bold;
  color: #3a3a3a;
  padding-top: 5px;
  padding-bottom: 5px;
}
#preassessment .boxbg.gridChange.assess-body {
  padding: 15px;
  margin-bottom: 25px;
}
.preassessmentDetailBox .boxbg.gridChange.assess-body{
  border-bottom: 1px solid #7676;
  padding: 15px 30px;
}
#preassessment .timer-title{
  border-bottom: 1px solid #dcdcdc
}
#preassessment .assessment-test{
  /* float: right; 
    width: 72%;
    overflow: auto;
    position: fixed;
    top: 27%;
    bottom: 80px;
     margin-right: 30px; */ 
}
#preassessment .preright-section{
   float: right; 
}
#preassessment .left-section{
    position: fixed;
    /* margin-top: 9%; */
    /* max-height: 450px; */
    /* overflow-y: auto; */
    max-height: max-content;
    /* overflow-y:scroll;  */
}
#preassessment .title{
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #085dab;
  text-transform: capitalize;
}
#preassessment .timer-subtitle{
  font-size: 14px;
  font-weight: bold;
  color: #3a3a3a;
  padding-top: 10px;
  padding-bottom: 30px;
}
#preassessment .align-right {
  text-align: right;
  margin-top: 15px;
}
#preassessment .assess-subtext {
  padding-bottom: 6px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
#preassessment .assess-subtext span {
  padding-left: 5px;
}
#preassessment .btn-preAssessment{
  font-size: 16px;
  padding: 6px 23px;
  margin: 5px;
  border: 1px solid #085dab;
  border-radius: 50px;
  color: #085dab;
  background: #fff; 
  transition: 0.5s; 
  box-shadow: 3px 5px 8px #bfbfbf;
}
#preassessment .btn-preAssessment:hover{
  color: #fff;
  background: #085dab; 
}
#preassessment .timer-btn{
  margin-top: 8px;
  margin-bottom: 8px;
}
#preassessment .timer-preAssessment{
  font-size: 25px;
  padding: 5px 30px;
  font-weight: 900;
  border: 1px solid #085dab;
  border-radius: 50px;
  color: #085dab;
  background: #fff;
  transition: 0.5s;
  box-shadow: 3px 5px 8px #bfbfbf;
}
#preassessment .bottom-border {
  border-bottom: 1px solid #aaa;
  margin-left: -15px;
  margin-right: -15px;
}
#preassessment .radioflex{
  padding: 6px 6px 6px 25px;
}

#preassessment .thank-you-content{
  padding: 20px;
  font-size: 16px;
  text-align: center;
}

#preassessment .thank-you-page {
  width: 100%;
  padding: 10px;
  text-align: center;
  margin-top: 20px;
}
#preassessment .thank-you-btn {
/* margin: auto;
    font-size: 16px;
    display: initial;
    padding: 6px 30px;
    border: none;
    border-radius: 50px;
    color: #fff;
    background: #085dab;
    transition: 0.5s;
    box-shadow: 3px 5px 8px #bfbfbf; */
    text-align: center;
    margin: 20px 0px 30px;
}
#preassessment .thank-you-btn a{
      color: #fff !important;
  }
#preassessment .intro-title{
  font-weight: bold;
  padding: 10px 0px;
  font-size: 16px;
}
#preassessment .intro-title-heading {
  font-weight: bold;
  padding: 10px 0px;
  font-size: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #085dab;
  letter-spacing: 5px;
}
#preassessment .instruction-title {
  padding-left: 5px;
}
#preassessment .assess-next-prv{
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 0px;
  margin-left: 750px;
}

#preassessment .boxbg.gridChange.assessment-test{
  padding: 15px;
}
#preassessment .boxbg.gridChange.assessment-test.gradient {
  padding: 15px;
  background: #66a8d8;
  background-image: linear-gradient(to right, #f9d6bc, #fff);
}
#preassessment .boxbg.gridChange.assessment-test.gradient1 {
  padding: 15px;
background-image: linear-gradient(to right, #f9bfdb, #fff);
}
#preassessment .question-style {
  color: #999999;
  padding-bottom: 10px;
}
#preassessment .question {
  color: #000;
  font-weight: 600;
}

/* #preassessment #assessmentOption label{
  padding: 4px 0px 3px 10px;
  font-weight: 800;
  color: #000000;
  font-size: 14px;
  letter-spacing: 1px;
  margin-top: 10px;
  text-transform: capitalize;
} */
#preassessment #assessmentOption span{
  padding: 4px 0px 3px 10px;
  font-weight: 800;
  color: #3a3a3a;
  font-size: 14px;
  letter-spacing: 1px;
  margin-top: 10px;
  text-transform: capitalize;
}
#preassessment .grid-container{
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  padding: 10px;
}

#preassessment .grid-container > gridChange {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
}

/********************************** Mozila Firefox ********************************************/
input[type="date"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control, input[type="time"].form-control {
  line-height: -moz-block-height !important;
}

/************************************* Media Query ********************************************/

@media only screen and (max-width: 767px) {
  .navbar-collapse {
    clear: both;
    background: #e1e1e1;
    border-bottom: 1px solid #dcdcdc;
    box-shadow: 2px 2px 5px #bfbfbf;
  }
  .nav {
    display: block;
  }

  .nav .nav-item,
  .nav .nav-item a {
    padding-left: 0px;
    text-align: left
  }
  #user-option .dropdown-menu:before{
    top: 37px;
  }

  #notification-alert .message {
    font-size: 13px;
  }
  .notfound .notfound-404 {
    /* height: 142px; */
  }

  .notfound .notfound-404 h1 {
    font-size: 112px;
  }

  .welcome .welcome-logo {
    /* height: 142px; */
  }

  .welcome .welcome-logo h1 {
    font-size: 112px;
  }
  #notification-alert .dropdown-menu {
    top: 50px;
    left: -16px;
    width: 290px;
  }
  .sub-footer {
    padding-bottom: 165px;
}
.referral .referral-button{
  width: 100%;
}
section#home {
  margin-top: 4%;
}
.banner .carousel.carousel-slider {
  min-height: auto;
}
.banner .caption {
  position: absolute;
  left: 4%;
  top: 18%;
}
.banner h2.banner-title {
  color: #fff;
  font-weight: 900;
  text-align: left;
  padding: 0px 15px;
  font-size: 12px;
}
.banner p.banner-text {
  color: #fff;
  text-align: left;
  padding: 0px 15px;
  font-weight: bold;
  font-size: 8px;
  width: 100%;
  margin: auto;
}
.banner .banner-button {
  padding-top: 0px;
  margin: auto;
  width: 100%;
}
.banner .banner-btn {
  margin: auto;
  font-size: 8px;
  display: block;
  padding: 2px 14px;
  border: none;
  border-radius: 50px;
  color: #010101;
  background: #fff;
  transition: 0.5s;
}
footer {
  padding: 7px 8px;
  background: #085dab;;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  margin-top: 5px;
  z-index: 111;
}
section#learner-dashboard{
  margin-bottom: 50% !important;
}
#preassessment{
  margin-bottom: 50%;
  
}
#preassessment .boxbg{
  margin-bottom: 20px;
}
#learner-dashboard .mystatuspadding{
  padding-left: 15px;
}
#learner-dashboard .mystatus{
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 15px;
  
}
#learner-dashboard .my-status{
  min-height: unset;
  max-height: unset;
  height: auto;
}
#learner-dashboard .profile-details{
  margin-bottom: 30px;
}
#learner-dashboard .calendar-event{
  margin-bottom: 20px;
}
#learner-dashboard .my-learningmargin{
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
#learner-dashboard .my-learning{
  margin-bottom: 30px;
}
#funnelChart {
  min-height: unset;
  max-height: max-content;
  max-height: unset;
}
#funnelChart .F-mt {
  /* margin-top: 60%; */
  margin-top: 10%;
}
#funnelChart .F-mt1 {
  /* margin-top: 62%; */
  margin-top: 0%;
}
#funnelChart .F-mt2 {
  /* margin-top: 16%; */
  margin-top: 0%;
}
#funnelChart .F-mt3 {
  /* margin-top: -2px; */
  margin-top: 0%;
}
#funnelChart .F-mt4 {
  /* margin-top: -5px; */
  margin-top: 0%;
}
#funnelChart .F-mt5 {
  /* margin-top: -6px; */
  margin-top: 0%;
}
.btn-4 {
  font-size: 16px;
  padding: 6px 15px;
}
#learner-dashboard .ml-notofication{
  margin-top: 20px;
  padding-left: 15px
}
#preassessment .assessment-test{
  /* float: unset;
    width: unset;
    overflow: unset;
    position: unset;
    top: unset;
    bottom: unset;
    margin-right: unset; */
}
#preassessment .left-section{
  position: unset;
  margin-top: unset;
  height: unset;
  overflow-y: unset;
  max-height: unset;
}

.profile-footer{
  padding: 12px 8px 5px;
  background: #fff;
  border-top: unset;
  position: relative;
  left: 0;
  padding-right: 8%;
  bottom: 1%;
  width: 100%;
  color: white;
  text-align: center;
  z-index: 111;
}
.reattempt-footer{
  padding: 12px 8px 5px;
  background: #fff;
  border-top: unset;
  position: relative;
  left: 0;
  padding-right: 8%;
  bottom: 1%;
  width: 100%;
  color: white;
  text-align: center;
  z-index: 111;
}

section.dashboard-tab.user-profile {
  padding: 5px 0px 30px;
}
.profile-sidebar {
  margin-bottom: 0px;
}
#getintouch .modal-dialog {
  width: 100%;
  height: auto;
  max-height: 500px;
  overflow-y: auto;
}
#get-in-touch{
  bottom: 17%;
}
div#get-in-touch1 {
  bottom: 27%;
}
.ReactTable .-pagination {
  display: block !important;
}
#preassessment .timer-preAssessment {
  font-size: 20px;
  padding: 5px 30px;
}
select#action {
  width: auto;
}
select#download {
  width: auto;
}
i.fa.fa-ellipsis-h.actionIcon {
  position: relative;
  margin-left: unset;
}
#action-dropdown .dropdown-menu {
  top: 40px;
  right: 0 !important;
  width: 200px;
  left: -54px;
}
#action-dropdown .dropdown-menu:before {
  display: none;
}
#action-dropdown .fa{
  margin-top: 5px !important
}
.preassessmentDetailBox #login-reg-tab li a {
  padding: 5px;
  font-size: 16px;
}
div#dateLocationPopup {
  margin-right: 0px;
}
div#certificateDownloadpopup {
  margin-right: 0px;
}
.navbar-brand img {
  height: 55px;
  margin-left: 0px;
  margin-top: 10px;
}
section#notification-page {
  padding-bottom: 100px !important;
}
input[type="date"]:after {
  display: none
 } 
 #funnelChart ul{
  margin-left: 0px;
  padding-left: 32px;
}
#funnelChart .ml-35{
  margin-left: 0px !important
}
.displayFlex{
  display: block
}
.header-shortcut-links li a{
  padding: 5px;
}
.header-shortcut-links{
  padding:15px
}
.getInTouch-box {
  bottom: 17%;
}
.getInTouch-box1 {
  bottom: 39%;
}
.notification-container-mobile-bottom {
  right: 20px;
  left: 20px;
  bottom: 10%;
  margin-bottom: 15px;
}
.nav .nav-item, .nav .nav-item a{
  text-align: left;
}
.composeBtn {
  position: fixed;
  top: 140px;
  right: 30px;
}
.composeBtn1{
  padding: 17px;
  float: unset;
}

.preassessmentDetailBox .dashboard-tab.user-tab {
  padding-top: 15px;
}
.ReactTable .rt-noData{
  top: 72px !important;
}
#notification-page .panel-title-email{
  padding-top: 0px;
  font-size: 12px;
}
#notification-page .panel-title{
  font-size: 14px
}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  .dap-header button {
    display: block;
    float: right;
    margin: 15px;
    padding: 5px 12px 2px;
}
.dap-header .fa {
  font-size: 20px;
}
.dap-header #navbarSupportedContent1 {
  margin-top: 21%;
}
.dap-header .navbar-nav {
  float: none !important;
}
.dap-header .navbar-nav>li>a {
  width: 100%;
  text-align: center;
}
  .enroll {
    background-image: none;
  }

  .header .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
    padding-bottom: 3px;
  }

  .header .navbar-toggle {
    font-size: 17px;
    position: relative;
    float: right;
    padding: 4px 16px;
    margin-top: 8px;
    margin-right: 17px;
    margin-bottom: 10px;
    background-color: transparent;
    background-image: none;
    border-radius: 4px;
    margin-top: 15px;
    color: #000;
  }

  .dashboard .navbar-toggle {
    font-size: 17px;
    position: relative;
    float: right;
    padding: 7px 10px;
    margin-top: 8px;
    margin-right: 17px;
    margin-bottom: 10px;
    background-color: transparent;
    background-image: none;
    border-radius: 4px;
    margin-top: 15px;
    color: #da643e;
    border: 1px solid #adaaaa;
    cursor: pointer;
  }

  .dashboard .navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: #adaaaa;
    cursor: pointer;
  }

  .navbar-nav {
    margin: 7.5px 6px;
  }

  .enroll h2 {
    font-size: 26px;
    font-weight: 500;
    color: #000;
  }

  .modal-head {
    padding: 28px 8px;
    text-align: left;
  }

  .board {
    background: #fff;
    box-shadow: 8px 6px 10px #e6e6e6;
    padding: 31px 11px;
    margin-bottom: 10px;
  }

  .dashboard-tab.user-tab {
    padding: 100px 0px 20px;
    background-image: none;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .assign-role .cus-checkbox {
    display: block;
  }

  .assign-role .sec-checkbox {
    padding: 8px 0px;
  }

  .bg-box {
    /* box-shadow: 2px 9px 27px #e6e6e6;
        margin-bottom: 50px; */
    padding: 0px 0px;
    background: #fff;
    padding-bottom: 20px;
  }

  .report-bg {
    margin-top: 32px;
  }

  .scr-board {
    margin-bottom: 15px;
  }

  .nav>li>a {
    margin-right: 10px;
  }

  .profile-left-sidebar {
    position: relative;
    margin: 0 auto;
    z-index: 1;
  }

  .checkout .apply-coupon {
    margin-top: 10px;
    float: none;
  }

  .checkout {
    margin: 0 auto;
  }

  main {
    float: none !important;
    width: unset !important;
    overflow: unset !important;
    position: unset !important;
    /* right: 0; */
    height: 0;
    top: 0;
    bottom: 0;
  }

  .dropdown-menu:before {
    content: "";
    position: absolute;
    top: 40px;
    left: 5%;
    right: unset;
    border: 10px solid #6b9ecd;
    border-color: transparent transparent #6b9ecd transparent;
  }

  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: #fff;
    border: 0;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  span.progressGraph {
    display: unset !important;
  }
  .create-address .form-check {
    float: left !important;
}
.btn-file1 {
  left: 50px;
  bottom: 65px;
}
.btn-file2 {
  bottom: 68px;
}
.getin-touch{
  margin-bottom: 10% !important;
}
.btn-5{
  padding: 6px 20px;
}
.tab-group {
  margin: 32px 10px 40px 10px;
}
.getInTouch-box1 {
  bottom: 45%;
}
.getInTouch-box {
  bottom: 18%;
}

}
@media only screen and (min-device-width: 411px) and (max-device-width: 823px) {
.dap-header #navbarSupportedContent1 {
  margin-top: 19%;
}
#get-in-touch {
  bottom: 16%;
}
div#get-in-touch1 {
  bottom: 24%;
}
.getInTouch-box {
  bottom: 16%;
}
.getInTouch-box1 {
  bottom: 39%;
}
}
@media only screen and (max-width: 320px) {
#getintouch .modal-dialog {
  width: 100%;
  height: auto;
  max-height: 475px;
  overflow-y: auto;
}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .dashboard-tab {
    background-image: none;
    /* padding: 180px 0px 20px; */
    position: relative;
    background-repeat: no-repeat;
    margin-bottom: 25% !important;
  }
 
  .course-structure {
    text-align: center;
  }

  .dashboard-tab .board-img {
    background-image: url(../images/icon-bg.png);
    background-size: 100%;
    padding: 30px 30px;
    background-repeat: no-repeat;
  }

  .board {
    background: #fff;
    box-shadow: 8px 6px 10px #e6e6e6;
    padding: 31px 11px;
    margin-bottom: 10px;
  }

  .padding-rl-50 {
    padding: 0px 10px;
  }

  .table-header h3 {
    font-size: 17px;
    color: #fff;
    padding: 18px 24px;
  }

  nav.navbar.dashboard {
    padding: 0px 0px;
  }

  .top-nav {
    padding: 0 0px;
    float: right;
  }

  .navbar.scrolled {
    background: #fff;
  border-bottom: 1px solid #dcdcdc;
  box-shadow: 2px 2px 5px #bfbfbf;
    /* box-shadow: 0px 0px 21px #cecece; */
    padding: 0px 0px !important;
  }

  .create-user {
    width: 100%;
  }

  .learner-dashboard .sec-activity .bg-box {
    padding: 0px 0px;
    padding-bottom: 15px;
  }

  /* .create-user .form-control,h2,label{
      padding: 0px 10px;  
} */
  .sidenav {
    padding-top: 150px;
  }

  .sidenav a {
    font-size: 18px;
  }

  .sidenav .closebtn {
    top: 70px;
    cursor: pointer;
  }

  span.sidenva-icon {
    cursor: pointer;
  }

  .profile-left-sidebar {
    position: relative;
    margin: 0 auto;
    z-index: 1;
  }

  .checkout .apply-coupon {
    margin-top: 10px;
    float: none;
  }

  .checkout {
    margin: 0 auto;
  }

  main {
    float: none !important;
    width: unset !important;
    overflow: unset !important;
    position: unset !important;
    /* right: 0; */
    height: 0;
    top: 0;
    bottom: 0;
  }

    .dropdown-menu:before {
    content: "";
    position: absolute;
    top: 40px;
    left: 5%;
    right: unset;
    border: 10px solid #6b9ecd;
    border-color: transparent transparent #6b9ecd transparent;
  }

  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: #fff;
    border: 0;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  span.progressGraph {
    display: unset !important;
  }
  .create-address .form-check {
    float: left !important;
}
.profile-graph-my-learning-path {
  display: block; 
}
.btn-file1 {
  left: 80px;
  bottom: 49px;
}
.btn-file1 {
  left: 50px;
  bottom: 46px;
}
.btn-file2 {  
  bottom: 49px;
}
#admin-dashboard .social-stream{
  margin-bottom: 25px; 
}
.getin-touch{
  margin-bottom: 30% !important;
}
.inlinepayment {
  margin-top: 10px !important;
  margin-left: 5px !important;
  display: unset !important;
}
}

@media only screen and (min-device-width: 480px) and (max-device-width: 768px) {
  .course-structure {
    text-align: center;
  }

  .table-responsive {
    border: none;
  }

  .table-header h3 {
    font-size: 14px;
  }

  .navbar-right {
    float: right !important;
    margin-right: -29px;
  }

  .profile-left-sidebar {
    position: relative;
    margin: 0 auto;
    z-index: 1;
  }

  .checkout .apply-coupon {
    margin-top: 10px;
    float: none;
  }

  .checkout {
    margin: 0 auto;
  }

  main {
    float: none !important;
    width: unset !important;
    overflow: unset !important;
    position: unset !important;
    /* right: 0; */
    height: 0;
    top: 0;
    bottom: 0;
  }

    .dropdown-menu:before {
    content: "";
    position: absolute;
    top: 40px;
    left: 45%;
    right: unset;
    border: 10px solid #6b9ecd;
    border-color: transparent transparent #6b9ecd transparent;
  }

  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: #fff;
    border: 0;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  span.progressGraph {
    display: unset !important;
  }
  .create-address .form-check {
    float: left !important;
}
.inlinepayment {
  margin-top: 10px !important;
  margin-left: 5px !important;
  display: unset !important;
}
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navbar-header {
    float: none;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-nav {
    float: none !important;
    margin: 7.5px 50px 7.5px -18px;
  }

  .navbar-nav>li {
    float: none;
    padding: 0px 35px;
    
  }

  .navbar-text {
    float: none;
    margin: 15px 0;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
    overflow-x: auto !important;
  }

  .navbar-collapse {
    clear: both;
    background: #e1e1e1;
  }

  .profile-left-sidebar {
    position: relative;
    margin: 0 auto;
    z-index: 1;
  }

  .checkout .apply-coupon {
    margin-top: 10px;
    float: none;
  }

  .checkout {
    margin: 0 auto;
  }
  .benefits-box {
    background-color: white;
    width: 100%;
    height: auto;
    border: 1px solid #dcdcdc;
    box-shadow: 2px 2px 5px #bfbfbf;
    padding: 12px 15px;
    margin: 12px 0px;
    border-radius: 5px;
    text-align: center;
    z-index: 1;
}
h2.benefits-title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.about .about-paragraph {
  height: 200px;
  overflow-y: auto;
}
.banner h2.banner-title {
  color: #fff;
  font-weight: 900;
  text-align: center;
  padding: 0px 0px;
  font-size: 18px;
}
.banner p.banner-text {
  color: #fff;
  text-align: center;
  padding: 0px 0px;
  font-weight: bold;
  font-size: 13px;
  width: 100%;
  margin: auto;
}
.banner .banner-button {
  padding-top: 2px;
  margin: auto;
  width: 50%;
}
.referral .referral-button {
  margin: auto;
  width: 50%;
  padding: 50px 0px;
}
.dap-header #navbarSupportedContent1 {
  margin-top: 7%;
}
  main {
    float: none !important;
    width: unset !important;
    overflow: unset !important;
    position: unset !important;
    /* right: 0; */
    height: 0;
    top: 0;
    bottom: 0;
  }

  footer {
    padding: 22px 8px 7px;
    background: #085dab;;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    margin-top: 5px;
    z-index: 111;
  }

  .dropdown-menu:before {
    content: "";
    position: absolute;
    top: 36px;
    left: 8%;
    right: unset;
    border: 10px solid #6b9ecd;
    border-color: transparent transparent #6b9ecd transparent;
  }

  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: #fff;
    border: 0;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  span.progressGraph {
    display: unset !important;
  }
  .create-address .form-check {
    float: left !important;
}
#preassessment .timer-preAssessment {
  font-size: 20px;
  padding: 5px 30px;
}
#learner-dashboard .mystatuspadding{
  padding-left: 15px;
}
section#learner-dashboard{
  margin-bottom: 15% !important;
}
#learner-dashboard .mystatus{
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 15px;
}
#learner-dashboard .my-status{
  min-height: unset;
  max-height: unset;
  height: auto;
}
#learner-dashboard .profile-details{
  margin-bottom: 30px;
}
#learner-dashboard .calendar-event{
  margin-bottom: 20px;
}
#learner-dashboard .my-learningmargin{
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
#learner-dashboard .my-learning{
  margin-bottom: 30px;
}
#funnelChart {
  min-height: unset;
  max-height: max-content;
  max-height: unset;
}
#funnelChart .F-mt {
  margin-top: 25%;
}
#funnelChart .F-mt1 {
  margin-top: 15%;
}
#funnelChart .F-mt2 {
  margin-top: 10%;
}
#funnelChart .F-mt3 {
  margin-top: 4%;
}
#funnelChart .F-mt4 {
  margin-top: 2%;
}
#learner-dashboard .ml-notofication{
  margin-top: 20px;
  padding-left: 15px
}
#preassessment .assessment-test{
  /* float: unset;
    width: unset;
    overflow: unset;
    position: unset;
    top: unset;
    bottom: unset;
    margin-right: unset; */

    /* float: right;
    width: auto;
    overflow: auto;
    position: fixed;
    top: 21%;
    bottom: 100px;
    margin-right: 30px; */
}
#preassessment .left-section{
  position: unset;
  margin-top: unset;
  height: unset;
  overflow-y: unset;
  max-height: unset
}
#preassessment .preright-section {
  float: right;
  margin-bottom: 25px;
}
#preassessment {
  margin-bottom: 15%;
}
section.dashboard-tab.user-profile {
  padding: 5px 0px 80px;
}
.profile-footer {
  padding: 12px 8px 5px;
  background: #fff;
  border-top: unset;
  position: relative;
}
.profile-sidebar {
  margin-bottom: 0px;
}
#getintouch{
  right: 10%;
}
div#get-in-touch1 {
  bottom: 20%;
}
#get-in-touch {
  bottom: 14%;
}
.socialLinks {
  padding-bottom: 20px;
}
.reattempt-footer{
  padding: 12px 8px 5px;
  background: #fff;
  border-top: unset;
  position: relative;
  left: 0;
  padding-right: 8%;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  z-index: 111;
}
select#action {
  width: auto;
}
select#download {
  width: auto;
}
i.fa.fa-ellipsis-h.actionIcon {
  position: relative;
  margin-left: unset;
}
#action-dropdown .dropdown-menu:before {
  top: -20px;
    left: 89%;
}
section#home {
  margin-top: 2%;
}
section#notification-page {
  padding-bottom: 100px !important;
}
.displayFlex{
  display: block
}
.getInTouch-box {
  bottom: 14%;
}
.getInTouch-box1 {
  bottom: 30%;
}
.nav .nav-item, .nav .nav-item a{
  text-align: left;
}
.composeBtn {
  position: fixed;
  top: 110px;
  right: 50px;
}
.composeBtn1{
  padding: 17px;
  float: right;
}

.preassessmentDetailBox .dashboard-tab.user-tab {
  padding-top: 15px;
}
}
@media (min-width: 1024px) and (max-width: 1270px) {
.dap-header #navbarSupportedContent1 {
  /* margin-top: 7%; */
}
.dap-header #navbarSupportedContent1 {
  margin-top: 5%;
  background: #dcdcdc;
}
.dap-header button {
  display: block !important;
  float: right;
  margin: 15px;
  padding: 5px 12px 2px;
}

.navbar-collapse.collapse.in {
  display: block !important;
  overflow-x: auto !important;
}
.navbar-collapse.collapse {
  display: none !important;
  height: auto!important;
  padding-bottom: 0;
  overflow: visible!important;
 
}
#navbar{
  background: #e1e1e1;
  border-bottom: 1px solid #dcdcdc;
  box-shadow: 2px 2px 5px #bfbfbf;
  margin-top: 7%
}
.dap-header .navbar-nav {
  float: unset !important;
}
.navbar-nav>li {
  float: unset !important;
}
section#home {
  margin-top: 1%;
}
#md-toggle{
display: block;
float: right;
margin-top: 25px
}
}
@media (min-width: 979px) and (max-width: 1024px) {
  ul.feat-report {
    background: #f2f3f5;
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 24px 17px;
    padding-bottom: 16px;
    margin-bottom: -38px;
  }

  .profile-left-sidebar {
    position: relative;
    margin: 0 auto;
    z-index: 1;
  }

  .checkout .apply-coupon {
    margin-top: 10px;
    float: none;
  }

  .checkout {
    margin: 0 auto;
  }

  main {
    float: none !important;
    width: unset !important;
    overflow: unset !important;
    position: unset !important;
    /* right: 0; */
    height: 0;
    top: 0;
    bottom: 0;
  }

  .dropdown-menu:before {
    content: "";
    position: absolute;
    top: 40px;
    left: 45%;
    right: unset;
    border: 10px solid #6b9ecd;
    border-color: transparent transparent #6b9ecd transparent;
  }

  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: #fff;
    border: 0;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  span.progressGraph {
    display: unset !important;
  }
  .create-address .form-check {
    float: left !important;
}

ul#notification-profile {
  width: auto !important;
}
}

.remove-wrapper-space{
padding: 0 !important;
}


