header{background-color: #fff;position: fixed;left: 0;top: 0;padding: 8px 0 6px;box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.10);z-index: 999;}
.ap-navigation li{padding: 0 30px;border-right: 1px solid rgba(117, 190, 243, 0.141);line-height: 2.5;}
.ap-navigation li:last-child{border-right: none;padding-right: 0;}
.ap-navigation li:nth-child(5){border-right: none;}
.ap-navigation li:first-child{padding-left: 0;}
.ap-navigation li a{display: inline-block;position: relative;font-size: 16px;text-transform: uppercase;font-family:'Helvetica-Condensed-Bold';transition: all 0.4s ease;}
.ap-navigation li a::after{content: '';position: absolute;left: 0;bottom: 0;width: 0%;display: block;background-color: rgb(255, 213, 3);height: 3px;transition: all 0.4s ease;}
.ap-navigation li a:hover::after{width: 100%;}

/* Code added 28-09-2022 start */
.ap-navigation li button{display: inline-block;position: relative;font-size: 16px;text-transform: uppercase;font-family:'Helvetica-Condensed-Bold';transition: all 0.4s ease;background-color: transparent;border: none;cursor: pointer;}
.ap-navigation li button::after{content: '';position: absolute;left: 0;bottom: -10px;width: 0%;display: block;background-color: rgb(255, 213, 3);height: 3px;transition: all 0.4s ease;}
.ap-navigation li button:hover::after{width: 100%;}
/* Code added 28-09-2022 end */

.ap-navigation li.ap-loginBtnWrp{margin-left: 40px;}
.ap-navigation li.ap-loginBtnWrp a{padding: 0px 20px;color: #fff;font-family:'Helvetica-Condensed-Black';border-radius: 6px;box-shadow: 0px 5px 7px 0px rgba(41, 81, 96, 0.7);}
.ap-navigation li.ap-loginBtnWrp a:hover::after{display: none;}
.ap-mobLogin-btn{padding: 5px 20px;color: #fff;font-family:'Helvetica-Condensed-Black';border-radius: 6px;box-shadow: 0px 5px 7px 0px rgba(41, 81, 96, 0.7);font-size: 14px;}
.ap-hamBurgerClick{display: inline-block;margin-left: 20px;background-color: transparent;border: none;margin-bottom: -4px;}
.ap-mobBtnWrp{display: flex;align-items: center; display: none;}

@media screen and (max-width:1100px) {
    .ap-navigation li{padding: 0 14px;line-height: 2;}
    .ap-navigation li.ap-loginBtnWrp{margin-left:16px;}
}

@media screen and (max-width:550px) {
    .ap-navigation li a.ap-loginClick{display: none;}
    .ap-navMenu{position: absolute;top: 100%;background-color: #fff;width: 100%;left: 0;padding: 0 15px;padding-bottom: 20px;box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.10);transition: all 0.4s ease; visibility: hidden;opacity: 0;z-index: 1100;}
    .ap-navMenu.open{visibility: visible;opacity: 1;}
    .ap-navMenu li{width: 100%;margin: 0;padding: 10px 0;border: none;border-bottom: 1px solid #ddd; display:flex;}
    .ap-navMenu li:last-child{display: none;}
    .ap-logo{width:97px;}
    .ap-mobBtnWrp{display: flex;}
}

  

