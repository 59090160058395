#progressstatusPopup {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#progressstatusPopup td, #progressstatusPopup th {
  border: 1px solid #ddd;
  padding: 8px;
}

#progressstatusPopup tr:nth-child(even){background-color: #f2f2f2;}

#progressstatusPopup tr:hover {background-color: #ddd;}

