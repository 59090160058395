@font-face{font-family:'HelveticaNeueLTCom-BdCn';src:url('../fonts/HelveticaNeueLTCom-BdCn.eot');src:url('../fonts/HelveticaNeueLTCom-BdCn.eot?#iefix') format('embedded-opentype'),url('../fonts/HelveticaNeueLTCom-BdCn.woff2') format('woff2'),url('../fonts/HelveticaNeueLTCom-BdCn.woff') format('woff'),url('../fonts/HelveticaNeueLTCom-BdCn.ttf') format('truetype'),url('../fonts/HelveticaNeueLTCom-BdCn.svg#HelveticaNeueLTCom-BdCn') format('svg');font-weight:normal;font-style:normal;font-display:swap;}

@font-face{font-family:'Helvetica-Condensed';src:url('../fonts/Helvetica-Condensed.eot');src:url('../fonts/Helvetica-Condensed.eot?#iefix') format('embedded-opentype'),url('../fonts/Helvetica-Condensed.woff2') format('woff2'),url('../fonts/Helvetica-Condensed.woff') format('woff'),url('../fonts/Helvetica-Condensed.ttf') format('truetype'),url('../fonts/Helvetica-Condensed.svg#Helvetica-Condensed') format('svg');font-weight:normal;font-style:normal;font-display:swap;}

@font-face{font-family:'Helvetica-Condensed-Bold';src:url('../fonts/Helvetica-Condensed-Bold.eot');src:url('../fonts/Helvetica-Condensed-Bold.eot?#iefix') format('embedded-opentype'),url('../fonts/Helvetica-Condensed-Bold.woff2') format('woff2'),url('../fonts/Helvetica-Condensed-Bold.woff') format('woff'),url('../fonts/Helvetica-Condensed-Bold.ttf') format('truetype'),url('../fonts/Helvetica-Condensed-Bold.svg#Helvetica-Condensed-Bold') format('svg');font-weight:normal;font-style:normal;font-display:swap;}

@font-face{font-family:'HelveticaNeueLTStd-Cn';src:url('../fonts/HelveticaNeueLTStd-Cn.eot');src:url('../fonts/HelveticaNeueLTStd-Cn.eot?#iefix') format('embedded-opentype'),url('../fonts/HelveticaNeueLTStd-Cn.woff2') format('woff2'),url('../fonts/HelveticaNeueLTStd-Cn.woff') format('woff'),url('../fonts/HelveticaNeueLTStd-Cn.ttf') format('truetype'),url('../fonts/HelveticaNeueLTStd-Cn.svg#HelveticaNeueLTStd-Cn') format('svg');font-weight:normal;font-style:normal;font-display:swap;}

@font-face{font-family:'Helvetica-Condensed-Black';src:url('../fonts/Helvetica-Condensed-Black.eot');src:url('../fonts/Helvetica-Condensed-Black.eot?#iefix') format('embedded-opentype'),url('../fonts/Helvetica-Condensed-Black.woff2') format('woff2'),url('../fonts/Helvetica-Condensed-Black.woff') format('woff'),url('../fonts/Helvetica-Condensed-Black.ttf') format('truetype'),url('../fonts/Helvetica-Condensed-Black.svg#Helvetica-Condensed-Black') format('svg');font-weight:normal;font-style:normal;font-display:swap;}

@font-face{font-family:'FuturaBT-BoldItalic';src:url('../fonts/FuturaBT-BoldItalic.eot');src:url('../fonts/FuturaBT-BoldItalic.eot?#iefix') format('embedded-opentype'),url('../fonts/FuturaBT-BoldItalic.woff2') format('woff2'),url('../fonts/FuturaBT-BoldItalic.woff') format('woff'),url('../fonts/FuturaBT-BoldItalic.ttf') format('truetype'),url('../fonts/FuturaBT-BoldItalic.svg#FuturaBT-BoldItalic') format('svg');font-weight:normal;font-style:normal;font-display:swap;}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

*{outline:none;box-sizing:border-box;margin:0;padding:0;}
/* input,button{-moz-appearance:none;-webkit-appearance:none;} */
/* input,button,div,ul,li{outline:none !important;} */
div{box-sizing:border-box;}
a:link,a:visited{text-decoration:none;}
img{border:none;outline:none;}
ul,ul li{list-style-type:none}
.desktop{display:block;}
.mobile{display:none;}
.helperSpan{display:inline-block;vertical-align:middle;width:1px;height:100%;}
.relative{position:relative;}
.absolute{position:absolute;}
.clr{clear:both}
.floatLft{float:left}
.floatRgt{float:right}
.inlineBlk{display:inline-block;vertical-align:middle}
.table{display:table}
.transition{transition:all .35s ease 0s;-moz-transition:all .35s ease 0s;-webkit-transition:all .35s ease 0s;}
.col-100{width:100%}
.col-90{width:90%}
.col-80{width:80%}
.col-70{width:70%}
.col-60{width:60%}
.col-50{width:50%}
.col-40{width:40%}
.col-30{width:30%}
.col-20{width:20%}
.col-10{width:10%}
.flexDisplay{display:flex;}
.justifySpace{justify-content:space-between;}
.justifyStart{justify-content:flex-start;}
.justifyEnd{justify-content:flex-end;}
.justifyCenter{justify-content:center;}
.alignStart{align-items:flex-start;}
.alignEnd{align-items:flex-end;}
.alignCenter{align-items:center;}
.flexWrap{flex-flow:row wrap;}
.flexColumn{flex-direction:column;}
.textCenter{text-align:center;}
.textLeft{text-align:left;}
.textRight{text-align:right;}
.textUpper{text-transform: uppercase;}
.fwNormal{font-weight: 400;}
.fwLight{font-weight: 300;}
.fwMedium{font-weight: 500;}
.fwSemibold{font-weight: 600;}
.fwBold{font-weight: 700;}
.wrapper{max-width: 1540px;width: 100%;display: inline-block;padding: 0 20px;}
.primary-dark{color: #333 !important;}
.primary-green{color: #00a886;}
.bg-primary{background-color: #00a886 !important;}
.ap-common-head{font-family:'HelveticaNeueLTCom-BdCn';font-size: 58px;}

@media screen and (max-width:1600px) {
 .wrapper{max-width:1475px;}

}
@media screen and (max-width:1540px) {
    .wrapper{max-width: 1400px;}
    .ap-common-head{font-size: 48px;}
}

@media screen and (max-width:1400px) {
    .wrapper{max-width: 1280px;}
    .ap-common-head{font-size: 40px;}
}
@media screen and (max-width:550px) {
    .ap-common-head{font-size: 28.8px;}
    .wrapper{padding: 0 15px;}
   

}
@font-face{font-family:'Gotham-Light';src:url('../fonts/Gotham-Light.eot');src:url('../fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),url('../fonts/Gotham-Light.woff2') format('woff2'),url('../fonts/Gotham-Light.woff') format('woff'),url('../fonts/Gotham-Light.ttf') format('truetype'),url('../fonts/Gotham-Light.svg#Gotham-Light') format('svg');font-weight:normal;font-style:normal;font-display:swap;}

@font-face{font-family:'Gotham-Medium';src:url('../fonts/Gotham-Medium.eot');src:url('../fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),url('../fonts/Gotham-Medium.woff2') format('woff2'),url('../fonts/Gotham-Medium.woff') format('woff'),url('../fonts/Gotham-Medium.ttf') format('truetype'),url('../fonts/Gotham-Medium.svg#Gotham-Medium') format('svg');font-weight:normal;font-style:normal;font-display:swap;}

@font-face{font-family:'Gotham-Bold';src:url('../fonts/Gotham-Bold.eot');src:url('../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),url('../fonts/Gotham-Bold.woff2') format('woff2'),url('../fonts/Gotham-Bold.woff') format('woff'),url('../fonts/Gotham-Bold.ttf') format('truetype'),url('../fonts/Gotham-Bold.svg#Gotham-Bold') format('svg');font-weight:normal;font-style:normal;font-display:swap;}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

:root{
    --gotham-light: 'Gotham-Light';
    --gotham-medium: 'Gotham-Medium';
    --gotham-bold: 'Gotham-Bold';
    --roboto-font: 'Roboto', sans-serif;
    --primary-blue: #1d5ba8;
    --primary-red: #fe1818;
    --primary-black: #333333;
    --primary-white: #ffffff;
    --primary-body-color: #737373;
    --fw-light:300;
    --fw-regular:400;
    --fw-medium:500;
    --fw-semibold:600;
    --fw-bold:700;
    --fw-black: 900;
}

*{outline:none;box-sizing:border-box;margin:0;padding:0;}
/* input,button{-moz-appearance:none;-webkit-appearance:none;} */
/* input,button,div,ul,li{outline:none !important;} */
.ap-gotham-light{font-family: var(--gotham-light);}
.ap-gotham-medium{font-family: var(--gotham-medium);}
.ap-gotham-bold{font-family: var(--gotham-bold);}
.ap-roboto-font{font-family: var(--roboto-font);}
.ap-primary-blue{color: var(--primary-blue);}
.ap-primary-red{color: var(--primary-red);}
.ap-primary-black{color: var(--primary-black);}
.ap-primary-white{color: var(--primary-white);}
.ap-primary-bodyColor{color: var(--primary-body-color);}
.ap-bg-blue{background-color: var(--primary-blue);}

div{box-sizing:border-box;}
a:link,a:visited{text-decoration:none;}
img{border:none;outline:none;}
ul,ul li{list-style-type:none}
.desktop{display:block;}
.mobile{display:none;}
.helperSpan{display:inline-block;vertical-align:middle;width:1px;height:100%;}
.relative{position:relative;}
.absolute{position:absolute;}
.clr{clear:both}
.floatLft{float:left}
.floatRgt{float:right}
.inlineBlk{display:inline-block;vertical-align:middle}
.table{display:table}
.transition{transition:all .35s ease 0s;-moz-transition:all .35s ease 0s;-webkit-transition:all .35s ease 0s;}
.col-100{width:100%}
.col-90{width:90%}
.col-80{width:80%}
.col-70{width:70%}
.col-60{width:60%}
.col-50{width:50%}
.col-40{width:40%}
.col-30{width:30%}
.col-20{width:20%}
.col-10{width:10%}
.flexDisplay{display:flex;}
.justifySpace{justify-content:space-between;}
.justifyStart{justify-content:flex-start;}
.justifyEnd{justify-content:flex-end;}
.justifyCenter{justify-content:center;}
.alignStart{align-items:flex-start;}
.alignEnd{align-items:flex-end;}
.alignCenter{align-items:center;}
.flexWrap{flex-flow:row wrap;}
.flexColumn{flex-direction:column;}
.textCenter{text-align:center;}
.textLeft{text-align:left;}
.textRight{text-align:right;}
.textJustify{text-align: justify;}
.textUpper{text-transform: uppercase;}
.fwNormal{font-weight: 400;}
.fwLight{font-weight: 300;}
.fwMedium{font-weight: 500;}
.fwSemibold{font-weight: 600;}
.fwBlack{font-weight: var(--fw-black);}
.fwBold{font-weight: 700;}
.wrapper{max-width: 1540px;width: 100%;display: inline-block;padding: 0 20px;}
.primary-dark{color: #333 !important;}
.primary-green{color: #00a886;}
.bg-primary{background-color: #00a886 !important;}
.ap-common-head{font-size: 80px;}
.ap-common-head-small{font-size: 66px;}

@media screen and (max-width:1600px) {
    .wrapper{max-width:1450px;}
    .ap-common-head{font-size:54px;}
    .ap-common-head span img{max-width: 76%;}
    .ap-benefits .ap-common-head span{margin-right:-10px;}
    .ap-common-head-small{font-size:45px;}
    .ap-common-head-small span img{max-width: 10%;}

}
@media screen and (max-width:1540px) {
    .wrapper{max-width: 1400px;}
    .ap-common-head{font-size: 48px;}
}

@media screen and (max-width:1400px) {
    .wrapper{max-width: 1280px;}
    .ap-common-head{font-size: 40px;}
    .ap-common-head span img{max-width:56%;}

}
@media screen and (max-width:550px) {
    .ap-common-head{font-size: 28.8px;}
    .wrapper{padding: 0 15px;}
    .ap-common-head span{width: 100%;}
    .ap-common-head span img{max-width:34%;}
    .ap-common-head-small span{width: 100%;float: left;}
    .ap-common-head-small span img{max-width: 34%;}
    .ap-common-head-small{font-size:24px;}
    

}

