.ap-topStrip{padding: 30px 0;margin-top: 90px;}
.ap-stripHead{font-size: 50px;color: #fff;font-family:'HelveticaNeueLTCom-BdCn';margin: 0 !important;line-height: normal !important;}
.ap-benefits{margin-top: 80px;}
.ap-benefitsSmall{font-family:'HelveticaNeueLTCom-BdCn';font-size: 35.2px;}
.ap-benefintsBig{font-family:'HelveticaNeueLTCom-BdCn';font-size: 50px;}
.ap-benefits ul{margin-top: 60px;margin-bottom: 70px;}
.ap-benefits ul li{width: calc(16.6% - 50px);}
.ap-benefitsIco{width:132px;height:132px;border-width:2px;border-color:rgb(251,217,83);border-style:solid;border-radius:16px;}
.ap-benefitsIco img{max-width: 100%;}
.ap-benefitName{font-family:'HelveticaNeueLTCom-BdCn';font-size: 32px;margin-top: 8px;margin-bottom: 0 !important;}
.ap-aboutText{font-family:'HelveticaNeueLTStd-Cn';font-size: 32px;margin-top: 10px;}
.ap-aboutus{margin-bottom: 70px;}
.ap-yellowInner{background-color: #fbd953;padding: 30px 20px 100px;}
.ap-startedWrp{max-width: 1024px;width: 100%;display: inline-block;}
.ap-startedMain ul{margin-top: 50px;}
.ap-startedMain ul li{width: calc(50% - 60px);display: flex;justify-content: center;align-items: center;flex-direction: column;text-align: center;margin-bottom: 15%;position: relative;}
.ap-startedMain ul li .ap-startedIco{width:225px;height:225px;border-radius:50%;display: flex;justify-content: center;align-items: center;}
.ap-startedMain ul li .ap-startedIco img{max-width: 100%;}
.ap-lineImg{left: 74%;top: 30%;display: inline-block;}
.ap-curvelineImg{display: inline-block;left: 74%;top: 30%;width: 62%;}
.ap-curvelineImg img{max-width: 100%;}
.ap-noMar{margin-bottom: 0 !important;}
.ap-count{width:52px;height:52px;display:flex;justify-content:center;align-items:center;color:#fff;border-width:5px;border-color:rgb(255,213,3);border-style:solid;border-radius:50%;background-color:rgb(255,161,12);font-size:28px;right:15px;top:15px;right:6px;top:0px;font-family:'FuturaBT-BoldItalic';}
.ap-payFeesBtn-wrp{margin-top: 60px;}
.ap-payFeesBtn-wrp a{text-align: center;color: #fff;font-family:'HelveticaNeueLTCom-BdCn';font-size: 45px;padding: 10px 50px;border-radius: 5px; box-shadow: 0px 5px 7px 0px rgba(27, 27, 27, 0.23),0px 11px 7px 0px rgba(89, 128, 143, 0.42);}
.ap-lineSeparator{height: 4px;background-color: rgba(125, 104, 2, 0.3);margin: 90px 0 60px;}
.ap-supportCard{padding:80px 15px 40px;background:rgb(254,254,254);background:linear-gradient(133deg,rgba(254,254,254,1) 42%,rgba(238,239,239,1) 78%,rgba(244,244,244,1) 100%);border-radius:40px;box-shadow:4px 3px 10px rgba(0,0,0,0.10);}
.ap-supportList{padding: 0 70px;margin-top: 60px;}
.ap-supportList li.ap-supportInner{width: calc(33.33% - 25px);}
.ap-supportIco img{max-width: 100%;}
.ap-supportTitle{font-family:'HelveticaNeueLTCom-BdCn';font-size: 38px;margin-top: 35px;position: relative;}
.ap-supportTitle::after{content: '';position: absolute;width: 64%;height: 4px;background-color: rgb(0, 168, 134);display: block;left: 50%;transform: translateX(-50%);bottom: -20px;}
.ap-supportPoints{margin-top: 50px;padding: 0 0px 0 30px;height: 250px;}
.ap-supportPoints li{font-size: 20px;font-family:'Helvetica-Condensed';margin-bottom: 10px;line-height: 1.5;}
.ap-supportPoints li::before{content:'';display:inline-block;background:url(../../assets/images/tick-ico.png) no-repeat center center;width:30px;height:18px;margin-left:-1.5em;padding-right:8px;}
.ap-yellowBlock{margin-bottom: 100px;}

.ap-testimonials{background-color: #00a886;padding: 10px 0 50px;margin-bottom: 70px;}
.ap-banner-main{background-color: #efefef;padding: 40px 0 70px;}
.ap-mt-none{margin-top: 0 !important;}
.ap-bannerFixed-txt{width: calc(38% - 0px);margin-top: 5%;margin-left: 2.5%;}
.ap-banner-sliderWrp{width: calc(62% - 0px);margin-left: -5%;}
.ap-bannerFixed-txt .ap-bannerPara{font-family:'HelveticaNeueLTCom-BdCn';font-size: 34px;}
.ap-banner-main .wrapper{max-width: 1620px;}
.ap-whatsApp-click{position: fixed;bottom: 35%;right: 10%;z-index: 888;width: 6%;}
.ap-whatsApp-click img{max-width: 100%;}

.ap-bannerFixed-txt .ap-payFeesBtn-wrp a{font-size: 28px;padding: 10px 30px;} /* 12-10-2022*/
.ap-bannerFixed-txt .ap-payFeesBtn-wrp{margin-top: 40px;}/* 12-10-2022*/

.ap-commonFloatbtn{position:fixed;right:0;background-color:#fbd953;border:none;color:#333333;font-size: 35px;font-family:'HelveticaNeueLTCom-BdCn'; cursor: pointer; ;position:fixed;right:0;text-align:center;text-decoration:none;text-transform:uppercase;-moz-transform:rotate(-90deg);-ms-transform:rotate(-90deg);-o-transform:rotate(-90deg);-webkit-transform:rotate(-90deg);transform-origin:bottom right;padding: 5px 8px;line-height:40px; box-shadow: 0px 10px 12.6px 1.4px rgba(21, 21, 21, 0.36);z-index: 888;}
.ap-firstFloat{top: 10%;height:50px;width:400px;}
.ap-secondFloat{top: 56%;height:50px;width:220px;}
.ap-knowMore-click{margin-bottom: 70px;}
.ap-knowMore-click a{display:inline-block;font-size:40px;font-family:'HelveticaNeueLTCom-BdCn';padding:4px 20px;border-radius:10px;background-color:rgb(239,239,239);box-shadow:0px 5px 7px 0px rgba(27,47,54,0.23),0px 5px 7px 0px rgba(89,128,143,0.23);}

/* 28-09-2022 */
.ap-cookieDiv{position: fixed;bottom: 0;left: 0;padding: 25px;background-color: #bfbfbf;text-align: center;width: 100%;float: left;}
.ap-height{height: 50px;}

@media screen and (max-width:1600px) {
    .ap-secondFloat{top: 64%;}
    .ap-commonFloatbtn{font-size: 24px;height: 40px;line-height: 30px;}
    .ap-firstFloat{width:280px;}
    .ap-secondFloat{width:160px;top: 48%;}
}

@media screen and (max-width:1540px) {
    .ap-stripHead{font-size: 48px;}
    .ap-benefitsSmall{font-size: 32px;}
    .ap-benefintsBig{font-size: 48px;}
    .ap-benefitName{font-size: 25.6px;}
    .ap-aboutText{font-size: 26px;}
    .ap-startedMain ul li .ap-startedIco{width: 170px;height: 170px;}
    .ap-count{width: 45px;height: 45px;font-size: 22px;}
    .ap-startedMain ul li .ap-startedIco img{max-width:58%;}
    .ap-startedWrp{max-width:910px;}
    .ap-lineImg{left:71%;}
    .ap-curvelineImg{left:70%;width:58%;}
    .ap-supportIco img{max-width:40%;}
    .ap-supportTitle{font-size: 28.8px;}
    .ap-supportTitle::after{width: 54%;height: 3px;bottom: -16px;}
    .ap-supportPoints li{font-size:18px;}
    .ap-supportPoints{height: 220px;}
    .ap-payFeesBtn-wrp a{font-size: 34px;padding: 8px 38px;}
    .ap-supportTitle{margin-top: 22px;}
    .ap-footMenu li a{font-size:18px;}
    .ap-footDesc h3{font-size: 18px;}
    .ap-footDesc p{font-size: 18px;}
    .ap-footSocial p{font-size: 18px;}
    .ap-socialWrp a img{max-width: 75%;}
    .ap-socialWrp a{margin-left: 15px;}
    .ap-whatsApp-click{bottom: 40%;right: 6%;}
    .ap-bannerQuote .ap-common-head{font-size: 38.4px;}
}

@media screen and (max-width:1500px) {
    .ap-secondFloat{top: 50%;}
}

@media screen and (max-width:1400px) {
    .ap-stripHead{font-size:35.2px;}
    .ap-benefitsSmall{font-size:25.6px;}
    .ap-benefintsBig{font-size:40px;}
    .ap-benefitsIco{width:100px;height:100px;border-width:1px;}
    .ap-benefitsIco img{max-width:68%;}
    .ap-benefitName{font-size:22.4px;}
    .ap-aboutText{font-size:20px;}
    .ap-logo{width:120px;}
    .ap-logo img{max-width:100%;}
    .ap-navigation li a{font-size: 15px;}
    .ap-supportPoints li{font-size:15px;}
    .ap-supportPoints{height:195px;}
    .ap-supportTitle{font-size:24px;}
    .ap-supportPoints li::before{padding-right: 0;}
    .ap-supportCard{padding:65px 15px 40px;}
    .ap-topStrip{padding:22px 0;margin-top:76px;}
    .ap-whatsApp-click{bottom: 55%;right: 5%;}
    .ap-secondFloat{top: 58%;}
}

@media screen and (max-width:1300px) {
    .ap-secondFloat{top: 52%;}
}

@media screen and (max-width:1100px) {
    .ap-startedMain ul li .ap-startedIco{width:100px;height:100px;}
    .ap-startedMain ul li .ap-startedIco img{max-width:52%;}
    .ap-count{width:30px;height:30px;font-size:14px;}
    .ap-benefitName{font-size:19.2px;}
    .ap-curvelineImg{left:68%;width:36%;}
    .ap-startedMain ul li{margin-bottom: 10%;width: calc(50% - 32px);}
    .ap-lineImg{left:66%;}
    .ap-payFeesBtn-wrp a{font-size:25px;}
    .ap-supportList{padding:0 0px;}
    .ap-supportList li.ap-supportInner{width:calc(33.33% - 12px);}
    .ap-secondFloat{top:32%;}
}

@media screen and (max-width:992px) {

    .ap-benefits ul li{width:calc(16.6% - 12px);}
    .ap-benefitsIco img{max-width:46%;}
    .ap-benefitsIco{width:78px;height:78px;}
    .ap-benefits ul{margin-top:40px;margin-bottom:48px;}
    .ap-benefits{margin-top:48px;}
    .ap-aboutText{font-size:18px;line-height:1.4;}
    .ap-supportCard{padding:45px 15px 40px;}
    .ap-supportTitle{font-size:24px;margin-top:12px;}
    .ap-supportPoints{margin-top:36px;padding:0 0px 0 14px;}
    .ap-supportPoints li{font-size:13px;}

    .ap-yellowBlock{margin-bottom:60px;}
    .ap-yellowInner{padding:30px 15px 46px;}
    .ap-startedWrp{max-width:690px;}
    .ap-lineImg img{max-width: 100%;}
    .ap-lineImg{width: 88%;}
    .ap-curvelineImg{width: 42%;}
    .ap-lineSeparator{height:2px;margin:58px 0 36px;}
    .ap-secondFloat{top:40%;}

}

@media screen and (max-width:800px) {
    .ap-startedWrp{max-width:640px;}
}

@media screen and (max-width: 550px){
    .ap-stripHead{font-size:25.6px;}
    .ap-benefitsSmall{font-size:19.2px;}
    .ap-benefintsBig{font-size:25.6px;}
    .ap-aboutText{font-size:15px;}
    .ap-aboutus{margin-bottom:38px;}
    .ap-benefits ul{flex-flow: row wrap;}
    .ap-benefits ul li{width:calc(50% - 12px);margin-bottom:20px;}
    .ap-benefitName{font-size:16px;}
    .ap-benefitsIco{width:60px;height:60px;}
    .ap-startedMain ul li{width:calc(50% - 10px);}
    .ap-lineImg{display:none;}
    .ap-curvelineImg{display: none;}
    .ap-startedMain ul li .ap-startedIco{width:80px;height:80px;}
    .ap-count{border-width:2px;width:22px;height:22px;font-size:11px;}
    .ap-benefitName br{display: none;}
    .ap-payFeesBtn-wrp a{font-size:18px;padding:8px 24px;}
    .ap-lineSeparator{margin:34px 0 22px;}
    .ap-startedMain ul{margin-top:38px;}
    .ap-payFeesBtn-wrp{margin-top:40px;}
    .ap-supportList{margin-top: 40px;}
    .ap-supportList li.ap-supportInner{width:100%;margin-bottom:25px;}
    .ap-supportPoints li{font-size:15px;}
    .ap-supportPoints li br{display: none;}
    .ap-footMenu{flex-flow: row wrap;}
    .ap-whatsApp-click{width: 16%;bottom: 75%;right: 10%;}
    .ap-commonFloatbtn{font-size:16px;height:35px;line-height:28px;}
    .ap-firstFloat{width:190px;}
    .ap-secondFloat{width:110px;}
    .ap-bannerFixed-txt .ap-payFeesBtn-wrp a{font-size:18px;padding:8px 16px;}/*12-10-2022*/
    .ap-bannerFixed-txt .ap-payFeesBtn-wrp{margin-top: 20px;}/*12-10-2022*/

}

@media screen and (max-width:360px) {
    .ap-secondFloat{top:44%;}
}