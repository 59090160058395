footer{padding: 0px 0 40px;background-color: #efefef;color:inherit; width: 100%;font-size: 12px;}
.ap-footMenu{padding: 0 40px;border-bottom: 1px solid;padding-bottom: 0px;}
.ap-footWrp{margin-top: 0px;}
.ap-footMenu li a{display: inline-block;font-family: 'Roboto', sans-serif;font-size: 16px;font-weight: 400;color: #807e7e;}
.ap-footDesc h3{font-family: 'Roboto', sans-serif;font-size: 13px;font-weight: 700;color: #807e7e;}
.ap-footDesc h3 a{color: inherit;}
.ap-footDesc p{font-family: 'Roboto', sans-serif;font-size: 12px;font-weight: 400;color: #807e7e;margin-bottom: 0px;}
.ap-footDesc{width: 54%;}
.ap-socialWrp a{display: inline-block;margin-left: 15px; }
.ap-footSocial p{font-family: 'Roboto', sans-serif;font-size: 12px;font-weight: 400;color:#807e7e;margin-top: 0px;}
.ap-footSocial p a{color: inherit;}
.ap-footMenu li button{display: inline-block;font-family: 'Roboto', sans-serif;font-size: 20px;font-weight: 400;color: #807e7e;border: none;background-color: transparent;cursor: pointer;}

@media screen and (max-width:992px) {
    .ap-footDesc{width:42%;}
    .ap-footDesc h3{font-size:16px;}
    .ap-footDesc p{font-size: 16px;margin-bottom: 14px;}
    .ap-footSocial p{font-size: 16px;}
    .ap-footMenu{padding: 0 0px;padding-bottom: 0px;}
    .ap-footMenu li a{font-size: 16px;}
    footer{padding:-1px 0 32px;}
    
}
@media screen and (max-width:550px) {
    .ap-footDesc{width:100%;margin-bottom:25px;}

    .ap-footSocial{width: 100%;text-align: center;}
    .ap-socialWrp a{margin: 0 15px;}
    .ap-footMenu li{width: calc(50% - 5px);margin-bottom: 10px;}
    footer{padding:-1px 0 32px;}
    .ap-socialWrp{text-align: center;}
    .ap-footSocial p{font-size:14px;text-align:center;}
    .ap-footDesc p{font-size:14px;}

}