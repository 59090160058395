/* .ap-bannerImg{width: calc(45% - 40px);}
.ap-bannerImg img{max-width: 100%;}
.ap-bannerQuote{width: calc(55% - 0px);margin-top: 12%;}
.ap-bannerQuote .ap-common-head{font-size: 2.6rem;margin-bottom: 8px;}
.ap-banner-slider{margin-left: 0%;}
.ap-bannerSliderPara{font-family:'HelveticaNeueLTCom-BdCn';font-size: 1.4rem;line-height: 1.4;}
.ap-parasmall{font-size: 1.2rem;margin-top: 8px;line-height: 1.2;}
.ap-banner-slider .slick-dots{left: -30%;bottom: -45px;}
.ap-banner-slider .slick-dots li button{width: 16px;height: 16px;background-color: rgb(255, 255, 255);border-radius: 50%;}
.ap-banner-slider .slick-dots li.slick-active button{background-color: #00a886;}
.ap-banner-slider .slick-dots li button:before{display: none;}
.ap-fixedLogoWrp{display: flex; align-items: center;right: 20%;top: 30px;}
.ap-fixedLogoWrp span{display: inline-block;padding: 0 30px;border-right: 2px solid rgba(164, 164, 164, 0.14);}
.ap-fixedLogoWrp span:last-child{border-right: none;}
.ap-fixedLogoWrp span img{max-width: 100%;}
  
@media screen and (max-width:1540px) {
    .ap-bannerFixed-txt{margin-left: 5.5%;}
}
@media screen and (max-width:1500px) {
    .ap-bannerFixed-txt .ap-bannerPara{font-size: 1.8rem;}
    .ap-bannerQuote .ap-common-head{font-size:2.2rem;}
}
@media screen and (max-width:1400px) {
    
    .ap-banner-slider .slick-dots li button{width: 12px;height: 12px;}
    .ap-banner-slider .slick-dots{left: -29%;bottom: -34px;}
    .ap-banner-main{padding:26px 0 60px;}
    .ap-fixedLogoWrp{right: 15%;}
    .ap-fixedLogoWrp span{padding: 0 20px;}
    .ap-fixedLogoWrp span img{max-width: 80%;}
    .ap-bannerSliderPara{font-size:1.2rem;}
    .ap-bannerFixed-txt .ap-bannerPara{font-size:1.5rem;}
}

@media screen and (max-width:1300px) {
    .ap-bannerQuote .ap-common-head{font-size:2rem;}
    .ap-bannerSliderPara{font-size:1.1rem;}

}
  
@media screen and (max-width:1100px) {
    .ap-bannerFixed-txt{margin-left:0;}
    .ap-bannerFixed-txt{width:calc(40% - 0px);}
    .ap-fixedLogoWrp{right:0%;top:0;}

}
  
@media screen and (max-width:992px) {
    .ap-bannerCnt-wrp{flex-flow: row wrap;}
    .ap-bannerFixed-txt{width:100%;margin-bottom:28px;}
    .ap-banner-sliderWrp{width:100%;margin-left:0;}
    .ap-banner-slider .slick-dots{left:0;}
}
@media screen and (max-width:550px) {
    .ap-banner-main{padding:0px 0 60px;}
    .ap-bannerInner{flex-flow: row wrap;}
    .ap-bannerImg{width:100%;margin-top: 60px;}
    .ap-bannerQuote{width:100%;margin-top:2%;}
    .ap-bannerSliderPara{font-size:1rem;}
    .ap-bannerQuote .ap-common-head{font-size:1.5rem;}
    .ap-fixedLogoWrp span:last-child{padding-right: 0;}
}

   */


   /* 10-10-22 */


   .ap-bannerImg{width: calc(45% - 40px);}
.ap-bannerImg img{max-width: 100%;}
.ap-bannerQuote{width: calc(55% - 0px);margin-top: 12%;}
.ap-bannerQuote .ap-common-head{font-size: 41.6px;margin-bottom: 8px;}
.ap-banner-slider{margin-left: 0%;}
.ap-bannerSliderPara{font-family:'HelveticaNeueLTCom-BdCn';font-size: 22.4px;line-height: 1.4;}
.ap-parasmall{font-size: 19.2px;margin-top: 8px;line-height: 1.2;}
.ap-banner-slider .slick-dots{left: -30%;bottom: -45px;}
.ap-banner-slider .slick-dots li button{width: 16px;height: 16px;background-color: rgb(255, 255, 255);border-radius: 50%;}
.ap-banner-slider .slick-dots li.slick-active button{background-color: #00a886;}
.ap-banner-slider .slick-dots li button:before{display: none;}
.ap-fixedLogoWrp{display: flex; align-items: center;right: 20%;top: 30px;}
.ap-fixedLogoWrp span{display: inline-block;padding: 0 30px;border-right: 2px solid rgba(164, 164, 164, 0.14);}
.ap-fixedLogoWrp span:last-child{border-right: none;}
.ap-fixedLogoWrp span img{max-width: 100%;}
  
@media screen and (max-width:1540px) {
    .ap-bannerFixed-txt{margin-left: 5.5%;}
}
@media screen and (max-width:1500px) {
    .ap-bannerFixed-txt .ap-bannerPara{font-size: 28.8px;}
    .ap-bannerQuote .ap-common-head{font-size: 35.2px;}
}
@media screen and (max-width:1400px) {
    
    .ap-banner-slider .slick-dots li button{width: 12px;height: 12px;}
    .ap-banner-slider .slick-dots{left: -29%;bottom: -34px;}
    .ap-banner-main{padding:26px 0 60px;}
    .ap-fixedLogoWrp{right: 15%;}
    .ap-fixedLogoWrp span{padding: 0 20px;}
    .ap-fixedLogoWrp span img{max-width: 80%;}
    .ap-bannerSliderPara{font-size: 19.2px;}
    .ap-bannerFixed-txt .ap-bannerPara{font-size: 24px;}
}

@media screen and (max-width:1300px) {
    .ap-bannerQuote .ap-common-head{font-size: 32px;}
    .ap-bannerSliderPara{font-size: 17.6px;}

}
  
@media screen and (max-width:1100px) {
    .ap-bannerFixed-txt{margin-left:0;}
    .ap-bannerFixed-txt{width:calc(40% - 0px);}
    .ap-fixedLogoWrp{right:0%;top:0;}

}
  
@media screen and (max-width:992px) {
    .ap-bannerCnt-wrp{flex-flow: row wrap;}
    .ap-bannerFixed-txt{width:100%;margin-bottom:28px;}
    .ap-banner-sliderWrp{width:100%;margin-left:0;}
    .ap-banner-slider .slick-dots{left:0;}
}
@media screen and (max-width:550px) {
    .ap-banner-main{padding:0px 0 60px;}
    .ap-bannerInner{flex-flow: row wrap;}
    .ap-bannerImg{width:100%;margin-top: 60px;}
    .ap-bannerQuote{width:100%;margin-top:2%;}
    .ap-bannerSliderPara{font-size: 16px;}
    .ap-bannerQuote .ap-common-head{font-size: 24px;}
    .ap-fixedLogoWrp span:last-child{padding-right: 0;}
}

  