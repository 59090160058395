.loading {
    /* color: #7ef9ff, #b4c8d1, #4682b4, #0f52ba, #000080; */
    display: flex;
    justify-content: center;
    margin: auto;
}
    .dot {
      position: relative;
      width: 3em;
      height: 3em;
      margin: 0.8em;
      border-radius: 50%;
      background: #7ef9ff
    }
      .dot::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: #7ef9ff;
        border-radius: inherit;
        animation: wave 2s ease-out infinite;
      }

      .dot1 {
        position: relative;
        width: 3em;
        height: 3em;
        margin: 0.8em;
        border-radius: 50%;
        background: #b4c8d1
      }
        .dot1::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background: #b4c8d1;
          border-radius: inherit;
          animation: wave 2s ease-out infinite;
        }
        .dot2 {
          position: relative;
          width: 3em;
          height: 3em;
          margin: 0.8em;
          border-radius: 50%;
          background: #4682b4
        }
          .dot2::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: #4682b4;
            border-radius: inherit;
            animation: wave 2s ease-out infinite;
          }
          .dot3 {
            position: relative;
            width: 3em;
            height: 3em;
            margin: 0.8em;
            border-radius: 50%;
            background: #0f52ba
          }
            .dot3::before {
              position: absolute;
              content: "";
              width: 100%;
              height: 100%;
              background: #0f52ba;
              border-radius: inherit;
              animation: wave 2s ease-out infinite;
            }
            .dot4 {
              position: relative;
              width: 3em;
              height: 3em;
              margin: 0.8em;
              border-radius: 50%;
              background: #000080
            }
              .dot4::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                background: #000080;
                border-radius: inherit;
                animation: wave 2s ease-out infinite;
              }
  
      @for $i from 1 through 5 {
       .dot {
          background: (#89cff0);
       }
          .dot::before {
            animation-delay: $i * 0.2s;
          }
        }
      
  @keyframes wave {
    50%,
    75% {
      transform: scale(2.5);
    }
  
    80%,
    100% {
      opacity: 0;
    }
  }
  