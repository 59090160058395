/* .ap-testmonTitle{font-family:'HelveticaNeueLTCom-BdCn';font-size: 3.875rem;color: #fbd953;}
.ap-testmonTitle span{display: inline-block;vertical-align: middle;}
.ap-testmonTitle span img{max-width: 100%;margin-top: -15px;}
.ap-testimon-text{width: calc(65% - 30px);margin-top: -60px;}
.ap-testimon-image{width: calc(35% - 30px);}
.ap-testimon-image img{width: 100%;}
.ap-testimonial-slider .slick-list{padding-top: 20px;}
.ap-testimon-para{margin-top: 50px;}
.ap-testimon-para .ap-testimon-name{font-family:'HelveticaNeueLTCom-BdCn';font-size: 3.125rem;color: #fff;margin-bottom: 10px;}
.ap-testimon-para .ap-testimonText{font-family:'HelveticaNeueLTStd-Cn';font-size: 2rem;color: #fff;line-height: 1.4;}
.ap-testimonItem{margin: 0 8px;}
.ap-testimonial-slider .slick-dots li button{width: 16px;height: 16px;background-color: rgb(241, 241, 241);border-radius: 50%;}
.ap-testimonial-slider .slick-dots li.slick-active button{background-color: #fbd953;}
.ap-testimonial-slider .slick-dots li button:before{display: none;}
.ap-testimonial-slider .slick-next:before, .slick-prev:before{display: none;}
.ap-testimonial-slider .slick-next{background: url(../../../assets/images/arro-02.png) no-repeat center center; width: 48px;height: 80px;right: -80px;}
.ap-testimonial-slider .slick-next.slick-disabled{display: none !important;}
.ap-testimonial-slider .slick-prev.slick-disabled{display: none !important;}
.ap-testimonial-slider .slick-prev{background: url(../../../assets/images/arro-01.png) no-repeat center center; width: 48px;height: 80px;left: -80px;}

@media screen and (max-width:1536px) {
    .ap-testmonTitle{font-size: 3.125rem;}
    .ap-testmonTitle span{width: 12%;}
    .ap-testimon-para .ap-testimon-name{font-size: 2.5rem;}
    .ap-testimon-para .ap-testimonText{font-size: 1.8rem;}
}
@media screen and (max-width:1500px) {
    .ap-testimonial-slider{padding: 0 40px;}
}
@media screen and (max-width:1400px) {
    .ap-testmonTitle{font-size: 3rem;}
    .ap-testmonTitle span{width: 10%;}
    .ap-testimon-para .ap-testimon-name{font-size: 2.2rem;}
    .ap-testimon-para .ap-testimonText{font-size: 1.6rem;}
    .ap-testimon-para{margin-top:40px;}
    .ap-testimonial-slider .slick-dots li button{width: 12px;height: 12px;}
    .ap-testimon-image img{max-width: 88%;}
    .ap-testimonial-slider{padding:0 25px;}

}
@media screen and (max-width:1300px) {
    .ap-testimonial-slider .slick-prev { left: -50px;}
    .ap-testimonial-slider{padding:0 40px;}
    .ap-testimonial-slider .slick-next {right: -52px;}

}
@media screen and (max-width:1100px) {
    .ap-testimon-para .ap-testimonText{font-size:1.2rem;}
    .ap-testimonial-slider .slick-prev { display: none !important;}
    .ap-testimonial-slider .slick-next { display: none !important;}
    .ap-testimonial-slider{padding:0;}
}
@media screen and (max-width:800px) {
    .ap-testmonTitle{font-size:2rem;}
    .ap-testimon-para .ap-testimon-name{font-size:1.6rem;}
    .ap-testimon-para .ap-testimonText{font-size:1rem;}
    .ap-testimon-para{margin-top:18px;}
    .ap-testimon-text{margin-top: -25px;}
}

@media screen and (max-width:550px) {
    .ap-testiMon-inner{flex-flow: wrap;}
    .ap-testimon-text{width: 100%;margin-bottom: 15px;margin-top: 0;}
    .ap-testimon-image{width:100%;padding: 0 18px 0 0;}
    .ap-testimon-image img{max-width:100%;}
    .ap-testimonItem{margin:0 12px;}
    .ap-testimon-para .ap-testimonText{font-size:0.875rem;}
}

@media screen and (max-width:360px) {
    .ap-testimonItem{margin:0 0px;}
} */



/* 10-10-2022 */


.ap-testmonTitle{font-family:'HelveticaNeueLTCom-BdCn';font-size: 62px;color: #fbd953;}
.ap-testmonTitle span{display: inline-block;vertical-align: middle;}
.ap-testmonTitle span img{max-width: 100%;margin-top: -15px;}
.ap-testimon-text{width: calc(65% - 30px);margin-top: -60px;}
.ap-testimon-image{width: calc(35% - 30px);}
.ap-testimon-image img{width: 100%;}
.ap-testimonial-slider .slick-list{padding-top: 20px;}
.ap-testimon-para{margin-top: 50px;}
.ap-testimon-para .ap-testimon-name{font-family:'HelveticaNeueLTCom-BdCn';font-size: 50px;color: #fff;margin-bottom: 10px;}
.ap-testimon-para .ap-testimonText{font-family:'HelveticaNeueLTStd-Cn';font-size: 32px;color: #fff;line-height: 1.4;}
.ap-testimonItem{margin: 0 8px;}
.ap-testimonial-slider .slick-dots li button{width: 16px;height: 16px;background-color: rgb(241, 241, 241);border-radius: 50%;}
.ap-testimonial-slider .slick-dots li.slick-active button{background-color: #fbd953;}
.ap-testimonial-slider .slick-dots li button:before{display: none;}
.ap-testimonial-slider .slick-next:before, .slick-prev:before{display: none;}
.ap-testimonial-slider .slick-next{background: url(../../../assets/images/arro-02.png) no-repeat center center; width: 48px;height: 80px;right: -80px;}
.ap-testimonial-slider .slick-next.slick-disabled{display: none !important;}
.ap-testimonial-slider .slick-prev.slick-disabled{display: none !important;}
.ap-testimonial-slider .slick-prev{background: url(../../../assets/images/arro-01.png) no-repeat center center; width: 48px;height: 80px;left: -80px;}

@media screen and (max-width:1536px) {
    .ap-testmonTitle{font-size: 50px;}
    .ap-testmonTitle span{width: 12%;}
    .ap-testimon-para .ap-testimon-name{font-size: 40px;}
    .ap-testimon-para .ap-testimonText{font-size: 28.8px;}
}
@media screen and (max-width:1500px) {
    .ap-testimonial-slider{padding: 0 40px;}
}
@media screen and (max-width:1400px) {
    .ap-testmonTitle{font-size: 48px;}
    .ap-testmonTitle span{width: 10%;}
    .ap-testimon-para .ap-testimon-name{font-size: 35.2px;}
    .ap-testimon-para .ap-testimonText{font-size: 25.6px;}
    .ap-testimon-para{margin-top:40px;}
    .ap-testimonial-slider .slick-dots li button{width: 12px;height: 12px;}
    .ap-testimon-image img{max-width: 88%;}
    .ap-testimonial-slider{padding:0 25px;}

}
@media screen and (max-width:1300px) {
    .ap-testimonial-slider .slick-prev { left: -50px;}
    .ap-testimonial-slider{padding:0 40px;}
    .ap-testimonial-slider .slick-next {right: -52px;}

}
@media screen and (max-width:1100px) {
    .ap-testimon-para .ap-testimonText{font-size: 19.2px;}
    .ap-testimonial-slider .slick-prev { display: none !important;}
    .ap-testimonial-slider .slick-next { display: none !important;}
    .ap-testimonial-slider{padding:0;}
}
@media screen and (max-width:800px) {
    .ap-testmonTitle{font-size: 32px;}
    .ap-testimon-para .ap-testimon-name{font-size: 25.6px;}
    .ap-testimon-para .ap-testimonText{font-size: 16px;}
    .ap-testimon-para{margin-top:18px;}
    .ap-testimon-text{margin-top: -25px;}
}

@media screen and (max-width:550px) {
    .ap-testiMon-inner{flex-flow: wrap;}
    .ap-testimon-text{width: 100%;margin-bottom: 15px;margin-top: 0;}
    .ap-testimon-image{width:100%;padding: 0 18px 0 0;}
    .ap-testimon-image img{max-width:100%;}
    .ap-testimonItem{margin:0 12px;}
    .ap-testimon-para .ap-testimonText{font-size: 14px;}
}

@media screen and (max-width:360px) {
    .ap-testimonItem{margin:0 0px;}
}