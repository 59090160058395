.ap-accordionInner p{display: none;}
.ap-accordionInner p.open{display: block;}
.ap-accordionMain{margin: 50px 0;}
.accordion__item{border-width:1px;border-color:rgb(255,255,255);border-style:solid;border-radius:7px;background-color:rgb(243,247,250);box-shadow:0px 5px 7px 0px rgba(27,47,54,0.23),0px 5px 7px 0px rgba(89,128,143,0.23);margin-bottom: 25px;    width: 100%;
    float: left;}
.accordion{border: none !important;}
.accordion__button{background-color: transparent !important;font-family:'HelveticaNeueLTStd-Cn';font-size: 35px;color: #333333;padding: 16px 100px 16px 25px!important;position: relative;}
.accordion__button:before{display: none !important;}
.accordion__button::after{content: '';position: absolute;background: url(../../../assets/images/plus-ico.png) no-repeat center center/cover;width: 35px;height: 35px;display: inline-block;right: 15px;top: 50%;transform: translateY(-50%);}
.ap-faqData{padding-left: 30px;padding-bottom: 25px;}
.ap-faqData li{color: #666;font-family:'HelveticaNeueLTStd-Cn';font-size: 26px;line-height: 1.4;list-style-type: disc;}
.accordion__panel{width: 100%;float: left;padding-top: 10px !important;}
.accordion__button[aria-expanded='true']::after, .accordion__button[aria-selected='true']::after{background: url(../../../assets/images/minus-ico.png) no-repeat center center/cover;}
.accordion__item + .accordion__item{border-top: none !important;}

@media screen and (max-width:1400px) {
    .accordion__button{font-size:28px;}
    .ap-faqData li{font-size:22px;}
    .ap-knowMore-click a{padding: 4px 14px;}
}
@media screen and (max-width:550px) {
    .accordion__button{padding-right: 54px !important;}
    .accordion__button{font-size:20px;}
    .ap-faqData li{font-size:16px;}
    .accordion__button::after{width:24px;height:24px;}
    .ap-accordionMain{margin:25px 0;}
    .ap-knowMore-click a{font-size:22px;}
    .ap-knowMore-click{margin-bottom:42px;}
}