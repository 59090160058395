.container-unauthorized {
  background-color: #fff;
  color: #000;
  text-align: center;
  font-family: "Open Sans";
  font-size: 1.3rem;
  h1 {
    font-size: 2.5rem;
  }
}
.message-unauthorized {
  max-width: 700px;
  margin: 5rem auto 0 auto;
}

